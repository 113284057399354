import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
  MenuItem,
  FormLabel,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Fade,
  FormGroup,
} from "@material-ui/core";

import { Context } from "../../context";
import Api from "../../service";
import { useEffect } from "react";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt-br";

const useStyles = makeStyles((theme) => ({
  inputMaior: {
    marginBottom: 20,
    width: "100%",
  },
  inputStyle: {
    minWidth: "71vw",
    maxWidth: "71vw",
    [theme.breakpoints.up("xxs")]: {
      minWidth: "64vw",
      maxWidth: "64vw",
    },
    [theme.breakpoints.up("xs")]: {
      minWidth: "64vw",
      maxWidth: "64vw",
    },
    [theme.breakpoints.up("sm")]: {
      minWidth: "50vw",
      maxWidth: "50vw",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "58vw",
      maxWidth: "58vw",
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: "65vw",
      maxWidth: "65vw",
    },
    [theme.breakpoints.up("xl")]: {
      minWidth: "71vw",
      maxWidth: "71vw",
    },
  },
  hover: {
    "&:hover": {
      color: "#44533f",
    },
  },
}));

export default function CreateTransaction() {
  const {
    reviewFleet,
    setReviewFleet,
    reviewLastKm,
    setReviewLastKm,
    reviewCurrentKm,
    setReviewCurrentKm,
    reviewOilChange,
    setReviewOilChange,
    reviewTireChange,
    setReviewTireChange,
    reviewTiresChangedFrontLeft,
    setReviewTiresChangedFrontLeft,
    reviewTiresChangedFrontRight,
    setReviewTiresChangedFrontRight,
    reviewTiresChangedRearLeft,
    setReviewTiresChangedRearLeft,
    reviewTiresChangedRearRight,
    setReviewTiresChangedRearRight,
    reviewCoast,
    setReviewCoast,
    reviewDescription,
    setReviewDescription,
    reviewDate,
    setReviewDate,
    reviewStatus,
    setReviewStatus,
    errorReviewLastKm,
    setErrorReviewLastKm,
    errorReviewCurrentKm,
    setErrorReviewCurrentKm,
    isFromEdit,
    reviewFleetRegistered,
    setReviewFleetRegistered,
    loadingReviewFleetRegistered,
    setloadingReviewFleetRegistered,
    setImpossibleCreateTransaction,
  } = useContext(Context);

  useEffect(() => {
    setloadingReviewFleetRegistered(true);
    setImpossibleCreateTransaction(false);

    if (!isFromEdit) {
      setReviewFleet("");
      setReviewFleetRegistered("");
      setloadingReviewFleetRegistered(true);
      setReviewLastKm("");
      setReviewCurrentKm("");
      setReviewOilChange(false);
      setReviewTireChange(false);
      setReviewTiresChangedFrontLeft(false);
      setReviewTiresChangedFrontRight(false);
      setReviewTiresChangedRearLeft(false);
      setReviewTiresChangedRearRight(false);
      setReviewCoast("");
      setReviewDescription("");
      setErrorReviewLastKm(false);
      setErrorReviewCurrentKm(false);
    }

    Api.get("/api/fleet/list").then((res) => {
      if (res.data && res.data.length > 0 && !isFromEdit) {
        setReviewFleet(res.data[0]._id);
      }

      setReviewFleetRegistered(res.data);
      setloadingReviewFleetRegistered(false);
    });
  }, []); // eslint-disable-line

  const classes = useStyles();

  return loadingReviewFleetRegistered ? (
    <Grid
      container
      className={classes.inputStyle}
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Grid>
  ) : (
    <>
      <Grid container className={classes.inputStyle}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <FormControl variant="outlined" className={classes.inputMaior}>
            <InputLabel>Frota</InputLabel>
            <Select
              id="reviewFleet"
              value={reviewFleet}
              onChange={(e) => setReviewFleet(e.target.value)}
              label="Frota"
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                PaperProps: {
                  style: {
                    maxHeight: 200,
                  },
                },
              }}
            >
              {reviewFleetRegistered &&
                reviewFleetRegistered.map((fleet, index) => {
                  return (
                    <MenuItem key={index} value={fleet._id}>
                      000{fleet.serialNumber}
                      {fleet.licensePlate && ` - ${fleet.licensePlate}`}{" "}
                      {fleet.licensePlate ? `/` : "-"} {fleet.model} -{" "}
                      {fleet.brand} (
                      {fleet.status === "active" ? "Ativa" : "Inativa"})
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
            <TextField
              id="reviewLastKm"
              value={reviewLastKm || ""}
              required
              type="number"
              error={!!errorReviewLastKm}
              helperText={
                errorReviewLastKm
                  ? "Insira uma quilometragem válida"
                  : "Para valores decimais, utilize vírgula."
              }
              onInvalid={(e) => {
                e.target.setCustomValidity("Insira uma quilometragem válida");
                setErrorReviewLastKm(true);
              }}
              onInput={(e) => {
                e.target.setCustomValidity("");
                setReviewLastKm(e.target.value);
                setErrorReviewLastKm(false);
              }}
              label="Km anterior"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">km</InputAdornment>
                ),
              }}
              variant="outlined"
              className={classes.inputMaior}
            />
          </Grid>
          <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
            <TextField
              id="reviewCurrentKm"
              value={reviewCurrentKm || ""}
              required
              type="number"
              error={!!errorReviewCurrentKm}
              helperText={
                errorReviewCurrentKm
                  ? "Insira uma quilometragem válida"
                  : "Para valores decimais, utilize vírgula."
              }
              onInvalid={(e) => {
                e.target.setCustomValidity("Insira uma quilometragem válida");
                setErrorReviewCurrentKm(true);
              }}
              onInput={(e) => {
                e.target.setCustomValidity("");
                setReviewCurrentKm(e.target.value);
                setErrorReviewCurrentKm(false);
              }}
              label="Km final"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">km</InputAdornment>
                ),
              }}
              variant="outlined"
              className={classes.inputMaior}
            />
          </Grid>
          <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
            <TextField
              id="reviewCoast"
              value={reviewCoast || ""}
              type="number"
              helperText={"Para valores decimais, utilize vírgula."}
              label="Custo total"
              onInput={(e) => {
                setReviewCoast(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">Reais</InputAdornment>
                ),
              }}
              variant="outlined"
              className={classes.inputMaior}
            />
          </Grid>
        </Grid>
        <Grid container style={{ marginLeft: 5 }}>
          <Grid item xl={2} lg={2} md={12} sm={12} xs={12}>
            <FormControl variant="outlined" className={classes.inputMaior}>
              <FormLabel id="oilChange">Troca de óleo</FormLabel>
              <RadioGroup
                row
                value={reviewOilChange || "nao"}
                onChange={(e) => setReviewOilChange(e.target.value)}
              >
                <FormControlLabel value="sim" control={<Radio />} label="Sim" />
                <FormControlLabel value="nao" control={<Radio />} label="Não" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xl={2} lg={2} md={12} sm={12} xs={12}>
            <FormControl variant="outlined" className={classes.inputMaior}>
              <FormLabel id="oilChange">Troca de pneus</FormLabel>
              <RadioGroup
                row
                value={reviewTireChange || "nao"}
                onChange={(e) => setReviewTireChange(e.target.value)}
              >
                <FormControlLabel value="sim" control={<Radio />} label="Sim" />
                <FormControlLabel value="nao" control={<Radio />} label="Não" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Fade
            in={reviewTireChange === "sim"}
            style={{ display: reviewTireChange === "sim" ? "" : "none" }}
          >
            <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
              <FormControl variant="outlined" className={classes.inputMaior}>
                <FormLabel id="oilChange">Pneus trocados</FormLabel>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={reviewTiresChangedFrontRight}
                        onChange={(e) =>
                          setReviewTiresChangedFrontRight(e.target.checked)
                        }
                      />
                    }
                    label="Dianteiro direito"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={reviewTiresChangedFrontLeft}
                        onChange={(e) =>
                          setReviewTiresChangedFrontLeft(e.target.checked)
                        }
                      />
                    }
                    label="Dianteiro esquerdo"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={reviewTiresChangedRearRight}
                        onChange={(e) =>
                          setReviewTiresChangedRearRight(e.target.checked)
                        }
                      />
                    }
                    label="Traseiro direito"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={reviewTiresChangedRearLeft}
                        onChange={(e) =>
                          setReviewTiresChangedRearLeft(e.target.checked)
                        }
                      />
                    }
                    label="Traseiro esquerdo"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          </Fade>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            id="description"
            value={reviewDescription || ""}
            type="number"
            onInput={(e) => {
              setReviewDescription(e.target.value);
            }}
            multiline
            minRows={3}
            label="Descrição da manutenção"
            variant="outlined"
            className={classes.inputMaior}
          />
        </Grid>
        <Grid container spacing={1}>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <FormControl variant="outlined" className={classes.inputMaior}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="pt-br"
              >
                <MobileDatePicker
                  label={"Data da manutenção"}
                  value={reviewDate}
                  onChange={(e) => {
                    setReviewDate(e.$d);
                  }}
                  clearable
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <FormControl variant="outlined" className={classes.inputMaior}>
              <InputLabel>Status</InputLabel>
              <Select
                id="reviewStatus"
                value={reviewStatus}
                onChange={(e) => setReviewStatus(e.target.value)}
                label="Status"
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
              >
                <MenuItem value={"active"}>Habilitado</MenuItem>
                <MenuItem value={"inactive"}>Desabilitado</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
