import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  CssBaseline,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Paper,
} from "@material-ui/core";

import "./style.css";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { Context } from "../../context";

import { ReactComponent as Logo } from "../../assets/logo.svg";

const useStyles = makeStyles((theme) => ({
  paperStyle: {
    width: "500px",
    height: "500px",
    [theme.breakpoints.up("xxs")]: {
      width: "300px",
    },
    [theme.breakpoints.up("xs")]: {
      width: "300px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "500px",
    },
    [theme.breakpoints.up("md")]: {
      width: "500px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "500px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "500px",
    },
    textAlign: "center",
  },
  inputStyle: {
    width: "100%",
    marginBottom: 30,
    [`& fieldset`]: {
      borderRadius: 30,
    },
  },
  inputColor: {
    color: "#243e92",
  },
  notchedOutline: {
    borderColor: "#243e92",
    textColor: "#243e92",
  },
  buttonStyle: {
    width: "100%",
    height: "36px",
    marginBottom: "30px",
    borderRadius: 30,
  },
  loginSpan: {
    width: "100%",
    color: "red",
  },
  logoStyle: {
    width: "350px",
    height: "200px",
    [theme.breakpoints.up("xxs")]: {
      width: "250px",
    },
    [theme.breakpoints.up("xs")]: {
      width: "250px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "350px",
    },
    [theme.breakpoints.up("md")]: {
      width: "350px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "350px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "350px",
    },
  },
}));

const Login = () => {
  const classes = useStyles();

  const {
    handleLogin,
    setErrorMsg,
    errorMsg,
    setPage,
    setSelectedIndex,
    setOpenUsers,
  } = useContext(Context);
  const [showPass, setShowPass] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleShowPassword = () => {
    setShowPass(!showPass);
  };

  const handleHiddePassword = (e) => {
    e.preventDefault();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMsg("");
    setPage("Página Inicial");
    setSelectedIndex([1, "Página Inicial"]);
    setOpenUsers(false);

    if (email !== "" && password !== "") {
      handleLogin(email, password);
    } else {
      var msg = "E-mail e/ou senha incorretos!";
      setErrorMsg(msg);
    }
  };

  return (
    <>
      <CssBaseline />
      <div className={"loginWrap"}>
        <img className={"loginBg"} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20vh",
          }}
        >
          <Paper elevation={4} className={`${classes.paperStyle} loginContent`}>
            <Grid
              container
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Grid item xs={10}>
                <Logo className={classes.logoStyle}></Logo>

                <form noValidate onSubmit={handleSubmit}>
                  <TextField
                    variant="outlined"
                    id="user"
                    required
                    color="primary"
                    label="E-mail"
                    className={classes.inputStyle}
                    value={email}
                    InputProps={{
                      classes: {
                        input: classes.inputColor,
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: "#243e92",
                      },
                    }}
                    onInput={(e) => setEmail(e.target.value)}
                  />

                  <TextField
                    variant="outlined"
                    id="pass"
                    required
                    label="Senha"
                    type={showPass ? "text" : "password"}
                    className={classes.inputStyle}
                    autoComplete="current-password"
                    onChange={onChangePassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleShowPassword}
                            onMouseDown={handleHiddePassword}
                          >
                            {showPass ? (
                              <Visibility style={{ color: "#243e92" }} />
                            ) : (
                              <VisibilityOff style={{ color: "#243e92" }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                      classes: {
                        input: classes.inputColor,
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: "#243e92",
                      },
                    }}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.buttonStyle}
                  >
                    entrar
                  </Button>
                  <div className={classes.loginSpan}>{errorMsg}</div>
                </form>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </div>
    </>
  );
};

export default Login;
