import React from "react";
import { Router } from "react-router-dom";

import Routes from "./routes";
import history from "./routes/history";

import { ContextHooks } from "./context";

import Theme from "./theme";

import { ThemeProvider } from "@material-ui/core/styles";

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <ContextHooks>
        <Router history={history}>
          <Routes />
        </Router>
      </ContextHooks>
    </ThemeProvider>
  );
}

export default App;
