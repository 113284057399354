import { useState } from "react";

export default function FleetHook() {
  const [fleetList, setFleetList] = useState("");
  const [loadingFleet, setLoadingFleet] = useState(true);
  const [fleetModel, setFleetModel] = useState("");
  const [fleetBrand, setFleetBrand] = useState("");
  const [fleetAllocation, setFleetAllocation] = useState("");
  const [fleetType, setFleetType] = useState("car");
  const [fleetSerialNumber, setFleetSerialNumber] = useState("");
  const [fleetLicensePlate, setFleetLicensePlate] = useState("");
  const [fleetStatus, setFleetStatus] = useState("active");
  const [errorFleetModel, setErrorFleetModel] = useState("");
  const [errorFleetBrand, setErrorFleetBrand] = useState("");
  const [cityRegistered, setCityRegistered] = useState("");
  const [loadingCityRegistered, setloadingCityRegistered] = useState(true);

  return {
    fleetList,
    setFleetList,
    loadingFleet,
    setLoadingFleet,
    fleetModel,
    setFleetModel,
    fleetBrand,
    setFleetBrand,
    fleetAllocation,
    setFleetAllocation,
    fleetType,
    setFleetType,
    fleetSerialNumber,
    setFleetSerialNumber,
    fleetLicensePlate,
    setFleetLicensePlate,
    fleetStatus,
    setFleetStatus,
    errorFleetModel,
    setErrorFleetModel,
    errorFleetBrand,
    setErrorFleetBrand,
    cityRegistered,
    setCityRegistered,
    loadingCityRegistered,
    setloadingCityRegistered,
  };
}
