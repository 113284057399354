import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  Box,
  Tabs,
  Tab,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";

import { Context } from "../../context";
import history from "../../routes/history";
import Api from "../../service";
import dayjs from "dayjs";

import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";

import CreateUser from "../../components/user/createUser";
import CreateProduct from "../../components/products/createProducts";
import CreateCity from "../../components/city/createCity";
import CreateFleet from "../../components/fleet/createFleet";
import CreateTransaction from "../../components/transaction/createTransaction";
import CreateReview from "../../components/review/createReview";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    marginLeft: "30px",
    marginRight: "30px",
    marginTop: "100px",
    height: "85vh",
    [theme.breakpoints.up("xxs")]: {
      height: "100%",
      marginBottom: "30px",
    },
    [theme.breakpoints.up("xs")]: {
      height: "100%",
      marginBottom: "30px",
    },
    [theme.breakpoints.up("sm")]: {
      height: "85vh",
    },
    [theme.breakpoints.up("md")]: {
      height: "85vh",
    },
    [theme.breakpoints.up("lg")]: {
      height: "85vh",
    },
    [theme.breakpoints.up("xl")]: {
      height: "85vh",
    },
    scrollbarWidth: "thin",
  },
  buttonInput: {
    margin: "19px",
    height: "35px",
    width: "12vw",
    [theme.breakpoints.up("xxs")]: {
      width: "35vw",
    },
    [theme.breakpoints.up("xs")]: {
      width: "35vw",
    },
    [theme.breakpoints.up("sm")]: {
      width: "12vw",
    },
    [theme.breakpoints.up("md")]: {
      width: "12vw",
    },
    [theme.breakpoints.up("lg")]: {
      width: "12vw",
    },
    [theme.breakpoints.up("xl")]: {
      width: "12vw",
    },
    color: "white",
  },
  tabs: {
    borderRight: `1px solid rgba(0, 0, 0, .1)`,
    height: "85vh",
    [theme.breakpoints.up("xxs")]: {
      height: "100%",
      borderRight: "none",
    },
    [theme.breakpoints.up("xs")]: {
      height: "100%",
      borderRight: "none",
    },
    [theme.breakpoints.up("sm")]: {
      height: "85vh",
      borderRight: `1px solid rgba(0, 0, 0, .1)`,
    },
    [theme.breakpoints.up("md")]: {
      height: "85vh",
      borderRight: `1px solid rgba(0, 0, 0, .1)`,
    },
    [theme.breakpoints.up("lg")]: {
      height: "85vh",
      borderRight: `1px solid rgba(0, 0, 0, .1)`,
    },
    [theme.breakpoints.up("xl")]: {
      height: "85vh",
      borderRight: `1px solid rgba(0, 0, 0, .1)`,
    },
  },
  formGrid: {
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
    [theme.breakpoints.up("xxs")]: {
      width: "83vw",
      height: "100%",
      flexDirection: "column",
    },
    [theme.breakpoints.up("xs")]: {
      width: "83vw",
      height: "100%",
      flexDirection: "column",
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      height: "100%",
      flexDirection: "row",
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
      height: "100%",
      flexDirection: "row",
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%",
      height: "100%",
      flexDirection: "row",
    },
    [theme.breakpoints.up("xl")]: {
      width: "100%",
      height: "100%",
      flexDirection: "row",
    },
  },
  backAndConfirmButton: {
    borderLeft: `1px solid rgba(0, 0, 0, .1)`,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    [theme.breakpoints.up("xxs")]: {
      borderLeft: "none",
      flexDirection: "row",
      alignItems: "space-between",
    },
    [theme.breakpoints.up("xs")]: {
      borderLeft: "none",
      flexDirection: "row",
      alignItems: "space-between",
    },
    [theme.breakpoints.up("sm")]: {
      borderLeft: `1px solid rgba(0, 0, 0, .1)`,
      flexDirection: "column",
      alignItems: "flex-start",
    },
    [theme.breakpoints.up("md")]: {
      borderLeft: `1px solid rgba(0, 0, 0, .1)`,
      flexDirection: "column",
      alignItems: "flex-start",
    },
    [theme.breakpoints.up("lg")]: {
      borderLeft: `1px solid rgba(0, 0, 0, .1)`,
      flexDirection: "column",
      alignItems: "flex-start",
    },
    [theme.breakpoints.up("xl")]: {
      borderLeft: `1px solid rgba(0, 0, 0, .1)`,
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

export default function User() {
  const {
    cookie,
    setPage,
    setOpenUsers,
    tabIndex,
    setTabIndex,
    name,
    email,
    phone,
    password,
    passwordConfirm,
    setPassword,
    setPasswordConfirm,
    setIsFromEdit,
    productName,
    productBrand,
    productQuantity,
    productStatus,
    cityName,
    cityState,
    cityStatus,
    fleetModel,
    fleetBrand,
    fleetAllocation,
    fleetType,
    fleetLicensePlate,
    fleetStatus,
    transactionProduct,
    transactionOrigin,
    transactionDestination,
    transactionQuantity,
    transactionStatus,
    transactionDate,
    setTransactionDate,
    impossibleCreateTransaction,
    reviewFleet,
    reviewLastKm,
    reviewCurrentKm,
    reviewOilChange,
    reviewTireChange,
    reviewTiresChangedFrontLeft,
    reviewTiresChangedFrontRight,
    reviewTiresChangedRearLeft,
    reviewTiresChangedRearRight,
    reviewCoast,
    reviewDescription,
    reviewDate,
    reviewStatus,
  } = useContext(Context);

  const classes = useStyles();

  const [errorMessage, setErrorMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    setPage("Cadastrar");
    setOpenUsers(true);
    setIsFromEdit(false);
    setPassword("");
    setPasswordConfirm("");

    const token = cookie.token.accessToken;
    Api.defaults.headers.common["x-access-token"] = token;

    setLoading(false);
  }, [tabIndex]); // eslint-disable-line

  const handleCloseModal = (props) => {
    if (props === "fechar") {
      if (
        errorMessage === "Falhou! E-mail já cadastrado!" ||
        errorMessage === "As senhas não coincidem"
      ) {
        setOpenModal(false);
        setButtonDisable(false);
      } else {
        setOpenModal(false);
        tabIndex === 0
          ? history.push("/user")
          : tabIndex === 1
          ? history.push("/product")
          : tabIndex === 2
          ? history.push("/city")
          : tabIndex === 3
          ? history.push("/fleet")
          : tabIndex === 4
          ? history.push("/transaction")
          : history.push("/review");
        setButtonDisable(false);
      }
    } else {
      setOpenModal(false);
      window.location.reload(true);
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    switch (tabIndex) {
      case 0: {
        setPage("Usuários");
        history.push("/user");
        break;
      }

      case 1: {
        setPage("Produtos");
        history.push("/product");
        break;
      }

      case 2: {
        setPage("Cidades");
        history.push("/city");
        break;
      }

      case 3: {
        setPage("Frotas");
        history.push("/fleet");
        break;
      }

      case 4: {
        setPage("Transações");
        history.push("/transaction");
        break;
      }

      case 5: {
        setPage("Manutenções");
        history.push("/review");
        break;
      }

      default: {
        break;
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    switch (tabIndex) {
      case 0: {
        if (password === passwordConfirm && password !== "") {
          Api.post("/api/auth/signup", {
            fullName: name,
            password: password,
            phone: phone.includes("_")
              ? phone.slice(0, phone.length - 1)
              : phone,
            email: email,
          })
            .then((res) => {
              setErrorMessage(res.data.message);
              setOpenModal(true);
            })
            .catch((err) => {
              setErrorMessage(err.response.data.message);
              setOpenModal(true);
            });
        } else {
          setErrorMessage("As senhas não coincidem");
          setOpenModal(true);
          setButtonDisable(true);
        }
        break;
      }

      case 1: {
        Api.post("/api/product", {
          name: productName,
          brand: productBrand,
          quantity: productQuantity,
          status: productStatus,
        })
          .then((res) => {
            setErrorMessage(res.data.message);
            setOpenModal(true);
          })
          .catch((err) => {
            setErrorMessage(err.response.data.message);
            setOpenModal(true);
          });
        break;
      }

      case 2: {
        Api.post("/api/city", {
          name: cityName,
          state: cityState,
          status: cityStatus,
        })
          .then((res) => {
            setErrorMessage(res.data.message);
            setOpenModal(true);
          })
          .catch((err) => {
            setErrorMessage(err.response.data.message);
            setOpenModal(true);
          });
        break;
      }

      case 3: {
        Api.post("/api/fleet", {
          model: fleetModel,
          brand: fleetBrand,
          oldAllocation: fleetAllocation,
          type: fleetType,
          licensePlate: fleetLicensePlate,
          status: fleetStatus,
        })
          .then((res) => {
            setErrorMessage(res.data.message);
            setOpenModal(true);
          })
          .catch((err) => {
            setErrorMessage(err.response.data.message);
            setOpenModal(true);
          });
        console.log(fleetAllocation);
        break;
      }

      case 4: {
        Api.post("/api/transaction", {
          product: transactionProduct,
          origin: transactionOrigin,
          destination: transactionDestination,
          quantity: transactionQuantity,
          author: cookie.userInfo[0]._id,
          timestamp: transactionDate,
          status: transactionStatus,
        })
          .then((res) => {
            setTransactionDate(dayjs(new Date()));
            setErrorMessage(res.data.message);
            setOpenModal(true);
          })
          .catch((err) => {
            setErrorMessage(err.response.data.message);
            setOpenModal(true);
          });
        break;
      }

      case 5: {
        const tireChanged =
          reviewTireChange === "nao"
            ? {
                frontLeft: false,
                frontRight: false,
                rearLeft: false,
                rearRight: false,
              }
            : {
                frontLeft: reviewTiresChangedFrontLeft,
                frontRight: reviewTiresChangedFrontRight,
                rearLeft: reviewTiresChangedRearLeft,
                rearRight: reviewTiresChangedRearRight,
              };

        const newDate = new Date(reviewDate).getTime();

        Api.post("/api/review", {
          fleet: reviewFleet,
          lastKm: reviewLastKm,
          currentKm: reviewCurrentKm,
          oilChange: reviewOilChange === "sim" ? true : false,
          tireChange: reviewTireChange === "sim" ? true : false,
          tiresChanged: tireChanged,
          coast: reviewCoast,
          description: reviewDescription,
          date: newDate || dayjs(new Date()),
          status: reviewStatus,
        })
          .then((res) => {
            setErrorMessage(res.data.message);
            setOpenModal(true);
          })
          .catch((err) => {
            setErrorMessage(err.response.data.message);
            setOpenModal(true);
          });
        break;
      }

      default: {
        break;
      }
    }
  };

  return loading ? (
    <Grid
      container
      style={{
        width: "78vw",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <CircularProgress />
    </Grid>
  ) : (
    <Paper elevation={2} className={classes.root}>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className={classes.formGrid}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={tabIndex}
            onChange={handleChange}
            className={classes.tabs}
            indicatorColor="secondary"
            textColor="secondary"
          >
            <Tab
              id="person"
              key="person"
              value={0}
              label={
                <Grid
                  container
                  direction="row"
                  justifycontent="center"
                  alignItems="center"
                >
                  <span>Usuário</span>
                </Grid>
              }
            />
            <Tab
              id="product"
              key="product"
              value={1}
              label={
                <Grid
                  container
                  direction="row"
                  justifycontent="center"
                  alignItems="center"
                >
                  <span>Produto</span>
                </Grid>
              }
            />
            <Tab
              id="city"
              key="city"
              value={2}
              label={
                <Grid
                  container
                  direction="row"
                  justifycontent="center"
                  alignItems="center"
                >
                  <span>Cidade</span>
                </Grid>
              }
            />
            <Tab
              id="fleet"
              key="fleet"
              value={3}
              label={
                <Grid
                  container
                  direction="row"
                  justifycontent="center"
                  alignItems="center"
                >
                  <span>Frota</span>
                </Grid>
              }
            />
            <Tab
              id="transaction"
              key="transaction"
              value={4}
              label={
                <Grid
                  container
                  direction="row"
                  justifycontent="center"
                  alignItems="center"
                >
                  <span>Transação</span>
                </Grid>
              }
            />
            <Tab
              id="review"
              key="review"
              value={5}
              label={
                <Grid
                  container
                  direction="row"
                  justifycontent="center"
                  alignItems="center"
                >
                  <span>Manutenção</span>
                </Grid>
              }
            />
          </Tabs>

          <TabPanel value={tabIndex} index={0}>
            <CreateUser />
          </TabPanel>

          <TabPanel value={tabIndex} index={1}>
            <CreateProduct />
          </TabPanel>

          <TabPanel value={tabIndex} index={2}>
            <CreateCity />
          </TabPanel>

          <TabPanel value={tabIndex} index={3}>
            <CreateFleet />
          </TabPanel>

          <TabPanel value={tabIndex} index={4}>
            <CreateTransaction />
          </TabPanel>

          <TabPanel value={tabIndex} index={5}>
            <CreateReview />
          </TabPanel>

          <div className={classes.backAndConfirmButton}>
            <Button
              variant="contained"
              style={{ backgroundColor: "#4B565C", color: "white" }}
              className={`${classes.buttonInput}`}
              onClick={(e) => handleBack(e)}
            >
              <ArrowBackIosNewOutlinedIcon style={{ marginRight: 10 }} />
              Voltar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={impossibleCreateTransaction}
              className={`${classes.buttonInput}`}
            >
              <CheckOutlinedIcon style={{ marginRight: 10 }} />
              salvar
            </Button>
          </div>
        </div>
      </form>
      <Dialog
        open={openModal}
        onClose={() => handleCloseModal("value")}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          <strong>
            <em>Atenção:</em>
          </strong>
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <h3 style={{ color: "gray" }}>{errorMessage}</h3>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCloseModal("fechar")}
            style={{ color: "#808080" }}
            autoFocus
          >
            <strong>fechar</strong>
          </Button>
          <Button
            onClick={() => handleCloseModal("outro")}
            color="secondary"
            autoFocus
            disabled={buttonDisable}
          >
            <strong>
              {tabIndex === 0
                ? "Criar outro usuário"
                : tabIndex === 1
                ? "Criar outro produto"
                : tabIndex === 2
                ? "Criar outra cidade"
                : tabIndex === 3
                ? "Criar outra frota"
                : tabIndex === 3
                ? "Criar outra transação"
                : "Criar outra manutenção"}
            </strong>
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
