import React, { useContext, useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  IconButton,
  TableCell,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Grid,
  Tooltip,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import MenuBookOutlinedIcon from "@material-ui/icons/MenuBookOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import BlockIcon from "@mui/icons-material/Block";
import CheckIcon from "@mui/icons-material/Check";

import { Context } from "../../context";
import Api from "../../service";
import history from "../../routes/history";
import { Typography } from "@mui/material";

import Pdf from "./pdfGenerator";

const useStyles = makeStyles((theme) => ({
  general: {
    display: "flex",
    width: "90vw",
    [theme.breakpoints.up("xxs")]: {
      width: "80vw",
    },
    [theme.breakpoints.up("xs")]: {
      width: "80vw",
    },
    [theme.breakpoints.up("sm")]: {
      width: "90vw",
    },
    [theme.breakpoints.up("md")]: {
      width: "90vw",
    },
    [theme.breakpoints.up("lg")]: {
      width: "90vw",
    },
    [theme.breakpoints.up("xl")]: {
      width: "90vw",
    },
  },
  delete: {
    marginRight: 30,
  },
  modalDialog: {
    textAlign: "left",
  },
  modalText: {
    color: "gray",
    fontSize: "18px",
    fontWeight: "bold",
  },
  fontIcon: {
    fontSize: 28,
  },
  hover: {
    "&:hover": {
      color: "#211f58",
    },
  },
  modalDialogPdf: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  input: {
    width: "90%",
    marginBottom: "30px",
  },
  paperDiv: {
    width: "1000vw",
  },
  tableRowContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  subTableStyle: {
    width: "100%",
    [theme.breakpoints.up("xxs")]: {
      width: "100%",
    },
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "50%",
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "50%",
    },
  },
}));

const ManageCity = () => {
  const {
    cookie,
    citiesList,
    setCitiesList,
    setPage,
    setOpenCities,
    setSelectedIndex,
    setTabIndex,
    setValueEdit,
    loadingCities,
    setLoadingCities,
  } = useContext(Context);

  const classes = useStyles();

  const [deleteMessage, setDeleteMessage] = useState("");
  const [openResponse, setOpenResponse] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [valueDelete, setValueDelete] = useState("");
  const [cityDelete, setCityDelete] = useState([]);
  const [openPdf, setOpenPdf] = useState(false);
  const [status, setStatus] = useState("all");
  const [nameFilter, setNameFilter] = useState("all");
  const [stateFilter, setStateFilter] = useState("all");

  var newCitiesList;

  var uniqCityState = [];

  const handleSeeCity = (id) => {
    setTabIndex(2);
    setValueEdit(id);
    history.push("/edit");
  };

  if (citiesList) {
    uniqCityState = [
      ...new Set(citiesList.map((city) => city.state !== "" && city.state)),
    ];

    uniqCityState = uniqCityState.filter((item) => item !== false);
  }

  // Code snippet that avoid warning in MUIDataTable under 4.1.12v
  const oldRender = TableCell.render;

  TableCell.render = function (...args) {
    const [props, ...otherArgs] = args;
    if (typeof props === "object" && props && "isEmpty" in props) {
      const { isEmpty, ...propsWithoutEmpty } = props;
      return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs]);
    } else {
      return oldRender.apply(this, args);
    }
  };
  // Code snippet that avoid warning in MUIDataTable under 4.1.12v

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontSize: "1.2rem",
    },
  }))(TableCell);

  useEffect(() => {
    const token = cookie.token.accessToken;

    Api.defaults.headers.common["x-access-token"] = token;

    setPage("Cidades");
    setSelectedIndex([4, "Cidades"]);
    setOpenCities(true);

    Api.get("/api/city/list")
      .then((res) => {
        setCitiesList(res.data);
        setLoadingCities(false);
      })
      .catch((err) => {
        console.error(err);
        setLoadingCities(false);
      });

    setValueDelete("");
    setCityDelete("");
  }, []); // eslint-disable-line

  const handleGeneratorPdf = () => {
    setOpenPdf(true);
  };

  function handleClosePdfModal(props) {
    if (props === "confirmar") {
      var filteredCities;
      if (nameFilter === "all" && status === "all" && stateFilter === "all") {
        filteredCities = citiesList;
      } else {
        filteredCities = citiesList.filter((item) => {
          var isFilterCity =
            nameFilter !== "all" ? item._id === nameFilter : true;
          if (isFilterCity) {
            return status !== "all" ? item.status === status : item.status;
          } else {
            return "";
          }
        });

        filteredCities = filteredCities.filter((item) => {
          if (stateFilter === "all") {
            return item;
          } else {
            return item.state === stateFilter;
          }
        });
      }

      const cities = filteredCities.map((item) => {
        return [
          { text: item.name, fontSize: 9, margin: [0, 4, 0, 4] },
          {
            text: item.state,
            fontSize: 9,
            margin: [0, 4, 0, 4],
          },
          {
            text: `${
              item.totalFleet === 0
                ? "Nenhuma"
                : item.totalFleet === 1
                ? `${item.totalFleet} unidade`
                : `${item.totalFleet} unidades`
            }`,
            fontSize: 9,
            margin: [0, 4, 0, 4],
          },
          {
            text: item.status === "active" ? "Ativa" : "Inativa",
            fontSize: 9,
            margin: [0, 4, 0, 4],
          },
        ];
      });
      Pdf(cities);
    } else {
      setOpenPdf(false);
      setStatus("all");
      setNameFilter("all");
      setStateFilter("all");
    }
  }

  newCitiesList = citiesList && citiesList;

  var countStatus = 0;
  var totalRows = 0;

  newCitiesList &&
    newCitiesList.forEach((data) => {
      totalRows += 1;
      data.status === "inactive" && (countStatus += 1);
    });

  const columns = [
    {
      name: "_id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "status",
      label: "status",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "name",
      label: "Cidade",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value}
            </Grid>
          );
        },
      },
    },
    {
      name: "state",
      label: "Estado",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value}
            </Grid>
          );
        },
      },
    },
    {
      name: "totalFleet",
      label: "Frotas alocadas (qtde)",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value === 0
                ? "Nenhuma"
                : value === 1
                ? `${value} unidade`
                : `${value} unidades`}
            </Grid>
          );
        },
      },
    },
    {
      name: "products",
      label: "Produtos alocados (qtde)",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value === 0
                ? "Nenhum"
                : value === 1
                ? `${value} unidade`
                : `${value} unidades`}
            </Grid>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value === "active" ? (
                <CheckIcon
                  style={{
                    color: "green",
                    display: "flex",
                    justifyContent: "center",
                  }}
                />
              ) : (
                <BlockIcon
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                />
              )}
            </Grid>
          );
        },
      },
    },
    {
      name: "open",
      label: "Editar",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return <StyledTableCell key={columnMeta.index}></StyledTableCell>;
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Grid container>
                <Grid item xs={10}>
                  <IconButton
                    onClick={() => handleSeeCity(tableMeta.rowData[0])}
                  >
                    <Tooltip title="Ver Detalhes">
                      <MenuBookOutlinedIcon
                        className={classes.fontIcon}
                        style={{
                          color:
                            tableMeta.rowData[1] === "active"
                              ? "#243e92"
                              : "gray",
                        }}
                      />
                    </Tooltip>
                  </IconButton>
                </Grid>
              </Grid>
            </>
          );
        },
      },
    },
  ];

  const handleCloseModalResponse = () => {
    setOpenResponse(false);
    window.location.reload();
  };

  function handleCloseModalConfirm(props) {
    if (props === "confirmar") {
      setOpenConfirm(false);
      setValueDelete("");

      Api.put("/api/city/status", { _id: valueDelete })
        .then((res) => {
          setDeleteMessage(res.data.message);
          setOpenResponse(true);
        })
        .catch((err) => {
          setDeleteMessage(err.response.data.message);
          setOpenResponse(true);
        });
    } else {
      setOpenConfirm(false);
    }
  }

  const handleDelete = (selectedRows, displayData) => {
    setCityDelete("");

    var cityName = selectedRows.data.map((city) => {
      return displayData.find((item) => item.dataIndex === city.dataIndex)
        .data[0];
    });

    setValueDelete(cityName);
    setOpenConfirm(true);
  };

  const handleCreateCity = () => {
    setPage("Cadastrar");
    setTabIndex(2);
    history.push("/create");
  };

  useEffect(() => {
    Api.get("/api/city", { params: { _id: valueDelete } })
      .then((res) => {
        setCityDelete(res.data);
      })
      .catch((err) => {
        setCityDelete(err.response.data.message);
      });
  }, [openConfirm === true]); // eslint-disable-line

  return loadingCities ? (
    <Grid
      container
      style={{
        width: "78vw",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <CircularProgress />
    </Grid>
  ) : (
    <div>
      <Paper elevation={0} className={classes.general}>
        <div className={classes.paperDiv}>
          <MUIDataTable
            id={"table"}
            title={"Cidades"}
            data={newCitiesList}
            pagination="true"
            columns={columns}
            options={{
              sort: true,
              expandableRows: true,
              isRowExpandable: (dataIndex, expandedRows) => {
                return newCitiesList[dataIndex].fleets.length > 0;
              },
              renderExpandableRow: (rowData, rowMeta) => {
                return (
                  <>
                    <tr>
                      <td colSpan={8}>
                        <TableContainer
                          className={classes.tableRowContainer}
                          component={Paper}
                        >
                          <Table
                            className={classes.subTableStyle}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow key={"header"}>
                                <TableCell align="center">Frota</TableCell>
                                <TableCell align="center">
                                  Nº de série
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {newCitiesList[rowMeta.dataIndex].fleets.length >
                                0 &&
                                newCitiesList[rowMeta.dataIndex].fleets.map(
                                  (item, index) => {
                                    return (
                                      <TableRow key={index}>
                                        <TableCell
                                          align="center"
                                          key={Math.random()}
                                        >
                                          {item.type === "car"
                                            ? "Carro"
                                            : item.type === "motorcycle"
                                            ? "Moto"
                                            : item.type === "truck"
                                            ? "Caminhão"
                                            : item.type === "brush_cutter"
                                            ? "Roçadeira"
                                            : "Trator"}{" "}
                                          {item.model} ({item.brand})
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          key={Math.random()}
                                        >
                                          000{item.serialNumber}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  }
                                )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </td>
                    </tr>
                  </>
                );
              },
              rowsPerPage: 5,
              rowsPerPageOptions: [5, 10, 15, 20],
              filter: false,
              download: false,
              responsive: "simple",
              selectableRowsOnClick: true,
              print: true,
              viewColumns: true,
              elevation: 0,
              textLabels: {
                body: {
                  noMatch: "Nenhuma cidade encontrada!",
                  toolTip: "Ordenar",
                  columnHeaderTooltip: (column) =>
                    `Ordenar por ${column.label}`,
                },
                pagination: {
                  next: "Próxima página",
                  previous: "Página anterior",
                  rowsPerPage: "Linhas por página:",
                  displayRows: "de",
                },
                toolbar: {
                  search: "Procurar",
                  viewColumns: "Filtrar colunas",
                  print: "Imprimir tabela",
                },
                viewColumns: {
                  title: "Filtrar colunas",
                },
                selectedRows: {
                  text: "linha(s) selecionada(s)",
                },
              },
              customToolbarSelect: (
                selectedRows,
                displayData,
                setSelectedRows
              ) => {
                return (
                  <Tooltip title="Desativar Cidade">
                    <div className={classes.delete}>
                      <IconButton
                        onClick={() => handleDelete(selectedRows, displayData)}
                        className={classes.hover}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                );
              },
              customToolbar: (data) => {
                return (
                  <>
                    <Tooltip title="Gerar PDF">
                      <IconButton
                        onClick={handleGeneratorPdf}
                        className={classes.hover}
                      >
                        <PictureAsPdfOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Criar Cidade">
                      <IconButton
                        onClick={handleCreateCity}
                        className={classes.hover}
                      >
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                );
              },
              isRowSelectable: (dataIndex, selectedRows) => {
                return dataIndex < totalRows - countStatus ? true : false;
              },
            }}
          />
        </div>
      </Paper>
      <Typography
        align="right"
        style={{
          marginTop: 20,
          color: "#8c8c8c",
          marginBottom: 20,
          marginRight: 10,
        }}
      >
        Atenção: o valor de frotas alocadas<br></br> corresponde ao número de
        frotas<br></br> totais na cidade.
      </Typography>
      <Dialog
        open={openResponse}
        onClose={handleCloseModalResponse}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          <strong>
            <i>{"Atenção:"}</i>
          </strong>
        </DialogTitle>
        <DialogContent className={classes.modalDialog}>
          <h3 className={classes.modalText} style={{ textAlign: "center" }}>
            {deleteMessage}
          </h3>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseModalResponse}
            style={{ color: "#808080" }}
            autoFocus
          >
            <strong>fechar</strong>
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openConfirm}
        onClose={() => handleCloseModalConfirm("value")}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          <strong>
            <i>{"Atenção:"}</i>
          </strong>
        </DialogTitle>
        <DialogContent className={classes.modalDialog}>
          <h3 className={classes.modalText}>
            Você está prestes a DESATIVAR a(s) cidade(s):
            <ul style={{ color: "black" }}>
              {cityDelete !== "" &&
                cityDelete.map((city, index) => {
                  return (
                    <li key={index}>
                      {city.name} - {city.state}
                    </li>
                  );
                })}
            </ul>
            Deseja continuar?
          </h3>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCloseModalConfirm("negar")}
            style={{ color: "#808080" }}
            autoFocus
          >
            <strong>fechar</strong>
          </Button>
          <Button
            onClick={() => handleCloseModalConfirm("confirmar")}
            color="secondary"
            autoFocus
          >
            <strong>confirmar</strong>
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openPdf}
        onClose={handleClosePdfModal}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          <strong>
            <i>{"Atenção:"}</i>
          </strong>
        </DialogTitle>
        <DialogContent>
          <h3 style={{ marginBottom: "30px" }}>Selecione os filtros:</h3>
          <div className={classes.modalDialogPdf}>
            <FormControl variant="outlined" className={classes.input}>
              <InputLabel>Cidade</InputLabel>
              <Select
                id="city"
                label="Cidade"
                value={nameFilter}
                onChange={(e) => setNameFilter(e.target.value)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
              >
                <MenuItem value={"all"}>Todas as cidades</MenuItem>

                {citiesList &&
                  citiesList.map((city, index) => {
                    return (
                      <MenuItem key={index} value={city._id}>
                        {city.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>

            <FormControl variant="outlined" className={classes.input}>
              <InputLabel>Estado</InputLabel>
              <Select
                id="state"
                label="Estado"
                value={stateFilter}
                onChange={(e) => setStateFilter(e.target.value)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
              >
                <MenuItem value={"all"}>Todos os estados</MenuItem>

                {uniqCityState &&
                  uniqCityState.map((state, index) => {
                    return (
                      <MenuItem key={index} value={state}>
                        {state}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>

            <FormControl variant="outlined" className={classes.input}>
              <InputLabel>Status</InputLabel>
              <Select
                id="status"
                label="Status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
              >
                <MenuItem value={"all"}>Todas as cidades</MenuItem>
                <MenuItem value={"active"}>Cidade ativa</MenuItem>
                <MenuItem value={"inactive"}>Cidade inativa</MenuItem>
              </Select>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleClosePdfModal("negar")}
            style={{ color: "#808080" }}
            autoFocus
          >
            <strong>fechar</strong>
          </Button>
          <Button
            onClick={() => handleClosePdfModal("confirmar")}
            color="secondary"
            autoFocus
          >
            <strong>GERAR PDF</strong>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ManageCity;
