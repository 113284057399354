import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";

import ManageTransaction from "./manageTransaction";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    marginLeft: "30px",
    marginRight: "30px",
    marginTop: "100px",
    height: "85vh",
    [theme.breakpoints.up("xxs")]: {
      height: "100%",
      marginBottom: "30px",
    },
    [theme.breakpoints.up("xs")]: {
      height: "100%",
      marginBottom: "30px",
    },
    [theme.breakpoints.up("sm")]: {
      minHeight: "85vh",
      height: "100%",
    },
    [theme.breakpoints.up("md")]: {
      minHeight: "85vh",
      height: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      minHeight: "85vh",
      height: "100%",
    },
    [theme.breakpoints.up("xl")]: {
      minHeight: "85vh",
      height: "100%",
    },
    scrollbarWidth: "thin",
  },
}));

export default function Transaction() {
  const classes = useStyles();
  
  return (
    <Paper elevation={2} className={classes.root}>
      <ManageTransaction />
    </Paper>
  );
}
