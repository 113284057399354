import { useState } from "react";

export default function CityHook() {
  const [citiesList, setCitiesList] = useState("");
  const [loadingCities, setLoadingCities] = useState(true);
  const [cityName, setCityName] = useState("");
  const [cityState, setCityState] = useState("");
  const [cityStatus, setCityStatus] = useState("active");
  const [errorCityName, setErrorCityName] = useState("");
  const [errorCityState, setErrorCityState] = useState("");

  return {
    citiesList,
    setCitiesList,
    loadingCities,
    setLoadingCities,
    cityName,
    setCityName,
    cityState,
    setCityState,
    cityStatus,
    setCityStatus,
    errorCityName,
    setErrorCityName,
    errorCityState,
    setErrorCityState,
  };
}
