import React, { useEffect, useState, useContext } from "react";
import { Chart } from "react-google-charts";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, CircularProgress } from "@material-ui/core";

import Api from "../../service";
import { Context } from "../../context";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
  },
  chartGeneral: {
    width: "100%",
    height: 400,
    [theme.breakpoints.up("xxs")]: {
      height: "300px",
    },
    [theme.breakpoints.up("xs")]: {
      height: "300px",
    },
    [theme.breakpoints.up("sm")]: {
      height: "400px",
    },
    [theme.breakpoints.up("md")]: {
      height: "400px",
    },
    [theme.breakpoints.up("lg")]: {
      height: "400px",
    },
    [theme.breakpoints.up("xl")]: {
      height: "400px",
    },
  },
}));

const data = [
  ["Age", "Weight"],
  [8, 12],
  [4, 5.5],
  [11, 14],
  [4, 5],
  [3, 3.5],
  [6.5, 7],
];

export default function Charts() {
  const classes = useStyles();

  const { cookie, page } = useContext(Context);

  const [loadingReviewYear, setLoadingReviewYear] = useState(true);
  const [loadingCities, setLoadingCities] = useState(true);
  const [loadingReviewLastDays, setLoadingReviewLastDays] = useState(true);
  const [loadingFleetTypes, setLoadingFleetTypes] = useState(true);
  const [topFiveReviews, setTopFiveReviews] = useState("");
  const [topFiveCities, setTopFiveCities] = useState("");
  const [topFiveReviewsYear, setTopFiveReviewsYear] = useState("");
  const [fleetTypes, setFleetTypes] = useState("");

  useEffect(() => {
    const token = cookie.token.accessToken;

    Api.defaults.headers.common["x-access-token"] = token;

    if (page === "Página Inicial") {
      Api.get("/api/review/topFiveLastDays")
        .then((res) => {
          const newData = res.data.map((item) => {
            return [
              `${item.fleetDetails.model} (000${item.fleetDetails.serialNumber})`,
              item.count,
              "orange",
            ];
          });
          newData.unshift(["Frota", "Manutenções", { role: "style" }]);

          setTopFiveReviews(newData);
          setLoadingReviewLastDays(false);
        })
        .catch((err) => {
          console.error(err);
          setLoadingReviewLastDays(false);
        });

      Api.get("/api/review/topFiveYear")
        .then((res) => {
          const newData = res.data.map((item) => {
            return [
              `${item.fleetDetails.model} (000${item.fleetDetails.serialNumber})`,
              item.count,
              "orange",
            ];
          });
          newData.unshift(["Frota", "Manutenções", { role: "style" }]);

          setTopFiveReviewsYear(newData);
          setLoadingReviewYear(false);
        })
        .catch((err) => {
          console.error(err);
          setLoadingReviewYear(false);
        });

      Api.get("/api/fleet/topFive")
        .then((res) => {
          const newData = res.data.map((item) => {
            return [
              `${item.cityDetails.name} / ${item.cityDetails.state}`,
              item.count,
              "orange",
            ];
          });
          newData.unshift(["Cidade", "Frotas", { role: "style" }]);

          setTopFiveCities(newData);
          setLoadingCities(false);
        })
        .catch((err) => {
          console.error(err);
          setLoadingCities(false);
        });

      Api.get("/api/fleet/types")
        .then((res) => {
          const newData = res.data.map((item) => {
            return [
              `${
                item._id === "car"
                  ? "Carro"
                  : item._id === "truck"
                  ? "Caminhão"
                  : item._id === "motorcycle"
                  ? "Moto"
                  : item._id === "brush_cutter"
                  ? "Roçadeira"
                  : "Trator"
              }`,
              item.count,
              "orange",
            ];
          });
          newData.unshift(["Tipo de frota", "Unidades", { role: "style" }]);
          setFleetTypes(newData);
          setLoadingFleetTypes(false);
        })
        .catch((err) => {
          console.error(err);
          setLoadingFleetTypes(false);
        });
    }
  }, []);

  return loadingReviewLastDays &&
    loadingReviewYear &&
    loadingCities &&
    loadingFleetTypes ? (
    <Grid
      container
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <CircularProgress />
    </Grid>
  ) : (
    <Grid container className={classes.container} spacing={2}>
      <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
        <Chart
          chartType="ColumnChart"
          data={topFiveReviewsYear}
          options={{
            title: `Frotas com mais manutenções (${new Date().getFullYear()})`,
            hAxis: { title: "Frota" },
            vAxis: { title: "Manutenções" },
            legend: "none",
          }}
          className={classes.chartGeneral}
        />
      </Grid>
      <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
        <Chart
          chartType="ColumnChart"
          data={topFiveReviews}
          options={{
            title: "Frotas com mais manutenções (30 dias)",
            hAxis: { title: "Frota" },
            vAxis: { title: "Manutenções" },
            legend: "none",
          }}
          className={classes.chartGeneral}
        />
      </Grid>
      <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
        <Chart
          chartType="ColumnChart"
          data={topFiveCities}
          options={{
            title: "Cidades com mais frotas",
            hAxis: { title: "Cidade" },
            vAxis: { title: "Frotas" },
            legend: "none",
          }}
          className={classes.chartGeneral}
        />
      </Grid>
      <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
        <Chart
          chartType="ColumnChart"
          data={fleetTypes}
          options={{
            title: "Quantidade de frotas por tipo",
            hAxis: { title: "Tipo de frota" },
            vAxis: { title: "Unidades" },
            legend: "none",
          }}
          className={classes.chartGeneral}
        />
      </Grid>
    </Grid>
  );
}
