import { useState, useEffect } from "react";

import Api from "../../service";
import history from "../../routes/history";

import { useCookies } from "react-cookie";

export default function UserAuth() {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userInfos, setUserInfos] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [cookie, setCookie, removeCookie] = useCookies();

  useEffect(() => {
    const token = cookie.token;

    const userInfoLocal = cookie.userInfo;

    if (userInfoLocal) {
      setUserInfos(userInfoLocal);
    }

    if (token) {
      Api.defaults.headers.common["x-access-token"] = token;
      setAuthenticated(true);
    }

    setLoading(false);
  }, []); // eslint-disable-line

  async function handleLogin(email, pass) {
    try {
      setLoading(true);
      let { data } = await Api.post("/api/auth/signin", {
        email: email,
        password: pass,
      });

      setCookie("token", data, { expire: 1, path: "/" });

      Api.defaults.headers.common["x-access-token"] = data.accessToken;

      setAuthenticated(true);
      setLoading(false);
      ({ data } = await Api.get("/api/user"));

      setCookie("userInfo", data, { expire: 1, path: "/" });
      setUserInfos(data);

      history.push("/main");
    } catch (error) {
      setErrorMsg(error.response.data.message);
    }
  }

  async function handleLogout() {
    try {
      removeCookie("token");
      removeCookie("userInfo");

      setAuthenticated(false);

      Api.defaults.headers.common["x-access-token"] = "undefined";
      history.push("/login");
    } catch (error) {
      setErrorMsg(error.response.data.message);
    }
  }

  return {
    authenticated,
    userInfos,
    loading,
    handleLogin,
    handleLogout,
    setErrorMsg,
    errorMsg,
    cookie,
    setCookie,
    setUserInfos,
  };
}
