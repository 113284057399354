import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
  MenuItem,
  Typography,
  InputAdornment,
} from "@material-ui/core";

import { Context } from "../../context";
import Api from "../../service";
import { useEffect } from "react";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt-br";

const useStyles = makeStyles((theme) => ({
  inputMaior: {
    marginBottom: 20,
    width: "100%",
  },
  inputStyle: {
    minWidth: "71vw",
    [theme.breakpoints.up("xxs")]: {
      minWidth: "64vw",
    },
    [theme.breakpoints.up("xs")]: {
      minWidth: "64vw",
    },
    [theme.breakpoints.up("sm")]: {
      minWidth: "50vw",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "58vw",
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: "65vw",
    },
    [theme.breakpoints.up("xl")]: {
      minWidth: "71vw",
    },
  },
  hover: {
    "&:hover": {
      color: "#44533f",
    },
  },
}));

export default function CreateTransaction() {
  const {
    transactionProduct,
    setTransactionProduct,
    transactionOrigin,
    setTransactionOrigin,
    transactionDestination,
    setTransactionDestination,
    transactionQuantity,
    setTransactionQuantity,
    setTransactionAuthor,
    transactionStatus,
    setTransactionStatus,
    transactionDate,
    setTransactionDate,
    errorTransactionQuantity,
    setErrorTransactionQuantity,
    isFromEdit,
    cityRegisteredInTransaction,
    setCityRegisteredInTransaction,
    productRegisteredInTransaction,
    setProductRegisteredInTransaction,
    loadingCityRegisteredInTransaction,
    setLoadingCityRegisteredInTransaction,
    loadingProductRegisteredInTransaction,
    setLoadingProductRegisteredInTransaction,
    impossibleCreateTransaction,
    setImpossibleCreateTransaction,
  } = useContext(Context);

  useEffect(() => {
    setLoadingCityRegisteredInTransaction(true);
    setLoadingProductRegisteredInTransaction(true);

    Api.get("/api/city/list").then((res) => {
      if (res.data && res.data.length > 0 && !isFromEdit) {
        setTransactionOrigin(res.data[0]._id);
        setTransactionDestination(res.data[0]._id);
      }

      setCityRegisteredInTransaction(res.data);
      setLoadingCityRegisteredInTransaction(false);
    });

    Api.get("/api/product/list").then((res) => {
      if (res.data && res.data.length > 0 && !isFromEdit) {
        res.data.sort((a, b) => {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        setTransactionProduct(res.data[0]._id);
      }
      setProductRegisteredInTransaction(res.data);
      setLoadingProductRegisteredInTransaction(false);
    });

    if (!isFromEdit) {
      setTransactionProduct("");
      setTransactionOrigin("");
      setTransactionQuantity("");
      setTransactionDestination("");
      setTransactionAuthor("");
    }
    setErrorTransactionQuantity(false);
  }, []); // eslint-disable-line

  useEffect(() => {
    if (productRegisteredInTransaction && cityRegisteredInTransaction) {
      setImpossibleCreateTransaction(false);
    } else {
      setImpossibleCreateTransaction(true);
    }
  }, [productRegisteredInTransaction, cityRegisteredInTransaction]);

  const classes = useStyles();

  return loadingCityRegisteredInTransaction ||
    loadingProductRegisteredInTransaction ? (
    <Grid
      container
      className={classes.inputStyle}
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Grid>
  ) : (
    <>
      <Grid container className={classes.inputStyle}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography
            align="left"
            style={{
              color: "#8c8c8c",
              marginBottom: 20,
              marginRight: 10,
              marginLeft: 10,
              display: `${!impossibleCreateTransaction && "none"}`,
            }}
          >
            Atenção: para cadastrar uma transação, é necessário haver ao menos
            uma cidade e um produto cadastrados.
          </Typography>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <FormControl variant="outlined" className={classes.inputMaior}>
            <InputLabel>Produto</InputLabel>
            <Select
              id="transactionProduct"
              value={transactionProduct}
              disabled={isFromEdit}
              onChange={(e) => setTransactionProduct(e.target.value)}
              label="Produto"
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                PaperProps: {
                  style: {
                    maxHeight: 200,
                  },
                },
              }}
            >
              {productRegisteredInTransaction &&
                productRegisteredInTransaction.map((product, index) => {
                  return (
                    <MenuItem key={index} value={product._id}>
                      {product.name} -{" "}
                      {product.brand === "" ? "Sem marca" : product.brand}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <FormControl variant="outlined" className={classes.inputMaior}>
            <InputLabel>Cidade de origem</InputLabel>
            <Select
              id="transactionOrigin"
              value={transactionOrigin}
              disabled={isFromEdit}
              onChange={(e) => setTransactionOrigin(e.target.value)}
              label="Cidade de origem"
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                PaperProps: {
                  style: {
                    maxHeight: 200,
                  },
                },
              }}
            >
              {cityRegisteredInTransaction &&
                cityRegisteredInTransaction.map((city, index) => {
                  return (
                    <MenuItem key={index} value={city._id}>
                      {city.status === "inactive"
                        ? `${city.name} (desativada)`
                        : city.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <FormControl variant="outlined" className={classes.inputMaior}>
            <InputLabel>Cidade de destino</InputLabel>
            <Select
              id="transactionDestination"
              disabled={isFromEdit}
              value={transactionDestination}
              onChange={(e) => setTransactionDestination(e.target.value)}
              label="Cidade de destino"
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                PaperProps: {
                  style: {
                    maxHeight: 200,
                  },
                },
              }}
            >
              {cityRegisteredInTransaction &&
                cityRegisteredInTransaction.map((city, index) => {
                  return (
                    <MenuItem key={city._id} value={city._id}>
                      {city.status === "inactive"
                        ? `${city.name} (desativada)`
                        : city.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            id="transactionQuantity"
            value={transactionQuantity || ""}
            required
            disabled={isFromEdit}
            type="number"
            error={!!errorTransactionQuantity}
            helperText={
              errorTransactionQuantity && "Insira uma quantidade válida"
            }
            onInvalid={(e) => {
              e.target.setCustomValidity("Insira uma quantidade válida");
              setErrorTransactionQuantity(true);
            }}
            onInput={(e) => {
              e.target.setCustomValidity("");
              setTransactionQuantity(e.target.value);
              setErrorTransactionQuantity(false);
            }}
            label="Quantidade"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">Unidades</InputAdornment>
              ),
            }}
            variant="outlined"
            className={classes.inputMaior}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <FormControl variant="outlined" className={classes.inputMaior}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="pt-br"
            >
              <MobileDatePicker
                label={"Data da transação"}
                value={transactionDate}
                onChange={(e) => {
                  setTransactionDate(e.$d);
                }}
                clearable
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <FormControl variant="outlined" className={classes.inputMaior}>
            <InputLabel>Status</InputLabel>
            <Select
              id="transactionStatus"
              value={transactionStatus}
              onChange={(e) => setTransactionStatus(e.target.value)}
              label="Status"
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                PaperProps: {
                  style: {
                    maxHeight: 200,
                  },
                },
              }}
            >
              <MenuItem value={"active"}>Habilitado</MenuItem>
              <MenuItem value={"inactive"}>Desabilitado</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}
