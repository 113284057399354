import { useState } from "react";
import dayjs from "dayjs";

export default function ReviewHook() {
  const [reviewList, setReviewList] = useState("");
  const [loadingReview, setLoadingReview] = useState(true);
  const [reviewFleet, setReviewFleet] = useState("");
  const [reviewLastKm, setReviewLastKm] = useState("");
  const [reviewCurrentKm, setReviewCurrentKm] = useState("");
  const [reviewOilChange, setReviewOilChange] = useState(false);
  const [reviewTireChange, setReviewTireChange] = useState(false);
  const [reviewTiresChangedFrontLeft, setReviewTiresChangedFrontLeft] =
    useState(false);
  const [reviewTiresChangedFrontRight, setReviewTiresChangedFrontRight] =
    useState(false);
  const [reviewTiresChangedRearLeft, setReviewTiresChangedRearLeft] =
    useState(false);
  const [reviewTiresChangedRearRight, setReviewTiresChangedRearRight] =
    useState(false);
  const [reviewCoast, setReviewCoast] = useState("");
  const [reviewDescription, setReviewDescription] = useState("");
  const [reviewDate, setReviewDate] = useState(dayjs(new Date()));
  const [reviewStatus, setReviewStatus] = useState("active");
  const [errorReviewLastKm, setErrorReviewLastKm] = useState("");
  const [errorReviewCurrentKm, setErrorReviewCurrentKm] = useState("");
  const [reviewFleetRegistered, setReviewFleetRegistered] = useState("");
  const [loadingReviewFleetRegistered, setloadingReviewFleetRegistered] =
    useState(true);

  return {
    reviewList,
    setReviewList,
    loadingReview,
    setLoadingReview,
    reviewFleet,
    setReviewFleet,
    reviewLastKm,
    setReviewLastKm,
    reviewCurrentKm,
    setReviewCurrentKm,
    reviewOilChange,
    setReviewOilChange,
    reviewTireChange,
    setReviewTireChange,
    reviewTiresChangedFrontLeft,
    setReviewTiresChangedFrontLeft,
    reviewTiresChangedFrontRight,
    setReviewTiresChangedFrontRight,
    reviewTiresChangedRearLeft,
    setReviewTiresChangedRearLeft,
    reviewTiresChangedRearRight,
    setReviewTiresChangedRearRight,
    reviewCoast,
    setReviewCoast,
    reviewDescription,
    setReviewDescription,
    reviewDate,
    setReviewDate,
    reviewStatus,
    setReviewStatus,
    errorReviewLastKm,
    setErrorReviewLastKm,
    errorReviewCurrentKm,
    setErrorReviewCurrentKm,
    reviewFleetRegistered,
    setReviewFleetRegistered,
    loadingReviewFleetRegistered,
    setloadingReviewFleetRegistered,
  };
}
