import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
} from "@material-ui/core";

import { Context } from "../../context";

import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  inputMaior: {
    marginBottom: 20,
    width: "100%",
  },
  inputStyle: {
    minWidth: "71vw",
    [theme.breakpoints.up("xxs")]: {
      minWidth: "64vw",
    },
    [theme.breakpoints.up("xs")]: {
      minWidth: "64vw",
    },
    [theme.breakpoints.up("sm")]: {
      minWidth: "50vw",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "58vw",
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: "65vw",
    },
    [theme.breakpoints.up("xl")]: {
      minWidth: "71vw",
    },
  },
  hover: {
    "&:hover": {
      color: "#44533f",
    },
  },
}));

export default function CreateProduct() {
  const {
    productName,
    setProductName,
    productBrand,
    setProductBrand,
    productQuantity,
    setProductQuantity,
    productStatus,
    setProductStatus,
    errorProductName,
    setErrorProductName,
    errorProductQuantity,
    setErrorProductQuantity,
    isFromEdit,
    setImpossibleCreateTransaction,
  } = useContext(Context);

  useEffect(() => {
    setImpossibleCreateTransaction(false);
    if (!isFromEdit) {
      setProductName("");
      setProductBrand("");
      setProductQuantity("");
    }
    setErrorProductName(false);
    setErrorProductQuantity(false);
  }, []); // eslint-disable-line

  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.inputStyle}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            id="name"
            required
            value={productName || ""}
            error={!!errorProductName}
            style={{ marginRight: 10 }}
            helperText={
              errorProductName && "O campo Nome do produto é obrigatório"
            }
            onInvalid={(e) => {
              e.target.setCustomValidity(
                "O campo Nome do produto é obrigatório"
              );
              setErrorProductName(true);
            }}
            onInput={(e) => {
              e.target.setCustomValidity("");
              setProductName(e.target.value);
              setErrorProductName(false);
            }}
            label="Nome do produto"
            variant="outlined"
            className={classes.inputMaior}
          />
        </Grid>
        {/* END NAME AND CPF FIELD CONTAINER */}
        {/* START brand AND quantity FIELD CONTAINER */}
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            id="productBrand"
            value={productBrand || ""}
            type="productBrand"
            onInput={(e) => {
              setProductBrand(e.target.value);
            }}
            label="Marca do produto"
            variant="outlined"
            className={classes.inputMaior}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            id="productQuantity"
            value={productQuantity || ""}
            required
            type="number"
            error={!!errorProductQuantity}
            helperText={errorProductQuantity && "Insira uma quantidade válida"}
            onInvalid={(e) => {
              e.target.setCustomValidity("Insira uma quantidade válida");
              setErrorProductQuantity(true);
            }}
            onInput={(e) => {
              e.target.setCustomValidity("");
              setProductQuantity(e.target.value);
              setErrorProductQuantity(false);
            }}
            label="Quantidade"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">Unidades</InputAdornment>
              ),
            }}
            variant="outlined"
            className={classes.inputMaior}
          />
        </Grid>
        {/* END brand AND quantity FIELD CONTAINER */}
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <FormControl variant="outlined" className={classes.inputMaior}>
            <InputLabel>Status</InputLabel>
            <Select
              id="productStatus"
              value={productStatus}
              onChange={(e) => setProductStatus(e.target.value)}
              label="Status"
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                PaperProps: {
                  style: {
                    maxHeight: 200,
                  },
                },
              }}
            >
              <MenuItem value={"active"}>Habilitado</MenuItem>
              <MenuItem value={"inactive"}>Desabilitado</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}
