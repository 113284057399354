import { useState } from "react";

export default function ProductHook() {
  const [productsList, setProductsList] = useState("");
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [productName, setProductName] = useState("");
  const [productBrand, setProductBrand] = useState("");
  const [productQuantity, setProductQuantity] = useState("");
  const [productStatus, setProductStatus] = useState("active");
  const [errorProductName, setErrorProductName] = useState("");
  const [errorProductQuantity, setErrorProductQuantity] = useState("");

  return {
    productsList,
    setProductsList,
    loadingProducts,
    setLoadingProducts,
    productName,
    setProductName,
    productBrand,
    setProductBrand,
    productQuantity,
    setProductQuantity,
    productStatus,
    setProductStatus,
    errorProductName,
    setErrorProductName,
    errorProductQuantity,
    setErrorProductQuantity,
  };
}
