import React, { useContext, useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  IconButton,
  TableCell,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Grid,
  Tooltip,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@material-ui/core";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt-br";

import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import MenuBookOutlinedIcon from "@material-ui/icons/MenuBookOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import BlockIcon from "@mui/icons-material/Block";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import { Context } from "../../context";
import Api from "../../service";
import history from "../../routes/history";
import { Typography } from "@mui/material";

import Pdf from "./pdfGenerator";

const useStyles = makeStyles((theme) => ({
  general: {
    display: "flex",
    width: "90vw",
    [theme.breakpoints.up("xxs")]: {
      width: "80vw",
    },
    [theme.breakpoints.up("xs")]: {
      width: "80vw",
    },
    [theme.breakpoints.up("sm")]: {
      width: "90vw",
    },
    [theme.breakpoints.up("md")]: {
      width: "90vw",
    },
    [theme.breakpoints.up("lg")]: {
      width: "90vw",
    },
    [theme.breakpoints.up("xl")]: {
      width: "90vw",
    },
  },
  delete: {
    marginRight: 30,
  },
  modalDialog: {
    textAlign: "left",
  },
  modalText: {
    color: "gray",
    fontSize: "18px",
    fontWeight: "bold",
  },
  fontIcon: {
    fontSize: 28,
  },
  hover: {
    "&:hover": {
      color: "#211f58",
    },
  },
  modalDialogPdf: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  input: {
    width: "90%",
    marginBottom: "30px",
  },
  iconAlignment: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("xxs")]: {
      justifyContent: "flex-start",
    },
    [theme.breakpoints.up("xs")]: {
      justifyContent: "flex-start",
    },
    [theme.breakpoints.up("sm")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.up("md")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.up("lg")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.up("xl")]: {
      justifyContent: "center",
    },
  },
  tableRowContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  subTableStyle: {
    width: "100%",
    [theme.breakpoints.up("xxs")]: {
      width: "100%",
    },
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "50%",
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "50%",
    },
  },
  iconAlignmentInTable: {
    display: "flex",
    justifyContent: "center",
  },
}));

const ManageReview = () => {
  const {
    cookie,
    reviewList,
    setReviewList,
    setPage,
    setOpenReview,
    setSelectedIndex,
    setTabIndex,
    setValueEdit,
    loadingReview,
    setLoadingReview,
  } = useContext(Context);

  const classes = useStyles();

  const [deleteMessage, setDeleteMessage] = useState("");
  const [openResponse, setOpenResponse] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [valueDelete, setValueDelete] = useState("");
  const [reviewDelete, setReviewDelete] = useState([]);
  const [openPdf, setOpenPdf] = useState(false);
  const [status, setStatus] = useState("all");
  const [fleetFilter, setFleetFilter] = useState("all");
  const [monthFilter, setMonthFilter] = useState(0);
  const [yearFilter, setYearFilter] = useState(0);

  var newReviewList;

  var uniqFleetFilter = [];

  if (reviewList) {
    uniqFleetFilter = [
      ...new Set(
        reviewList.map((review) => {
          return `${review.fleet.model} - ${review.fleet.brand}${
            review.fleet.licensePlate ? ` - ${review.fleet.licensePlate} ` : " "
          }(${review.fleet.allocation.name} / ${
            review.fleet.allocation.state
          })`;
        })
      ),
    ];

    uniqFleetFilter = uniqFleetFilter.filter((item) => item !== false);
  }

  const handleSeeReview = (id) => {
    setTabIndex(5);
    setValueEdit(id);
    history.push("/edit");
  };

  function formatReviewDate(reviewDate) {
    let formatDate = new Date(Date.parse(reviewDate));
    let date =
      (formatDate.getDate() > 9
        ? formatDate.getDate()
        : "0" + formatDate.getDate()) +
      "/" +
      (formatDate.getMonth() + 1 > 9
        ? formatDate.getMonth() + 1
        : "0" + (formatDate.getMonth() + 1)) +
      "/" +
      formatDate.getFullYear();
    return date;
  }

  // Code snippet that avoid warning in MUIDataTable under 4.1.12v
  const oldRender = TableCell.render;

  TableCell.render = function (...args) {
    const [props, ...otherArgs] = args;
    if (typeof props === "object" && props && "isEmpty" in props) {
      const { isEmpty, ...propsWithoutEmpty } = props;
      return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs]);
    } else {
      return oldRender.apply(this, args);
    }
  };
  // Code snippet that avoid warning in MUIDataTable under 4.1.12v

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontSize: "1.2rem",
    },
  }))(TableCell);

  useEffect(() => {
    const token = cookie.token.accessToken;

    Api.defaults.headers.common["x-access-token"] = token;

    setPage("Manutenções");
    setSelectedIndex([7, "Manutenções"]);
    setOpenReview(true);

    Api.get("/api/review/list")
      .then((res) => {
        setReviewList(res.data);
        setLoadingReview(false);
      })
      .catch((err) => {
        console.error(err);
        setLoadingReview(false);
      });

    setValueDelete("");
    setReviewDelete("");
  }, []); // eslint-disable-line

  const handleGeneratorPdf = () => {
    setOpenPdf(true);
  };

  function handleClosePdfModal(props) {
    if (props === "confirmar") {
      var filteredReview;
      if (
        fleetFilter === "all" &&
        status === "all" &&
        monthFilter === 0 &&
        yearFilter === 0
      ) {
        filteredReview = reviewList;
      } else {
        filteredReview = reviewList.filter((item) => {
          var isFilterProduct =
            status !== "all" ? item.status === status : item.status;
          if (isFilterProduct) {
            return item;
          } else {
            return "";
          }
        });

        filteredReview = filteredReview.filter((item) => {
          if (fleetFilter === "all") {
            return item;
          } else {
            var concatVar = `${item.fleet.model} - ${item.fleet.brand}${
              item.fleet.licensePlate ? ` - ${item.fleet.licensePlate} ` : " "
            }(${item.fleet.allocation.name} / ${item.fleet.allocation.state})`;

            return concatVar === fleetFilter;
          }
        });

        filteredReview = filteredReview.filter((item) => {
          if (monthFilter === 0 && yearFilter === 0) {
            return item;
          } else {
            const currentDate = formatReviewDate(item.date);
            const splitedDate = currentDate.split("/");
            const currentMonth = splitedDate[1];
            const currentYear = splitedDate[2];

            return currentMonth === monthFilter && currentYear === yearFilter;
          }
        });
      }

      filteredReview.length > 0 &&
        filteredReview.sort((a, b) => {
          if (a.fleet.model.toLowerCase() < b.fleet.model.toLowerCase()) {
            return -1;
          }
          if (a.fleet.model.toLowerCase() > b.fleet.model.toLowerCase()) {
            return 1;
          }
          // Se o modelo for igual, ordena pelo campo city
          if (
            a.fleet.allocation.name.toLowerCase() < b.fleet.allocation.name.toLowerCase()
          ) {
            return -1;
          }
          if (
            a.fleet.allocation.name.toLowerCase() > b.fleet.allocation.name.toLowerCase()
          ) {
            return 1;
          }
          return 0;
        });

      var reviews = filteredReview.map((item) => {
        return [
          {
            text:
              item.fleet &&
              `${item.fleet.model} - ${item.fleet.brand} (${item.fleet.allocation.name} / ${item.fleet.allocation.state})`,
            fontSize: 9,
            margin: [0, 4, 0, 4],
          },
          {
            text: `${item.fleet.licensePlate || "Placa não cadastrada"}`,
            fontSize: 9,
            margin: [0, 4, 0, 4],
          },
          {
            text: `${item.description || "Descrição não cadastrada"}`,
            fontSize: 9,
            margin: [0, 4, 0, 4],
          },
          // {
          //   text: item.oilChange ? "Sim" : "Não",
          //   fontSize: 9,
          //   margin: [0, 4, 0, 4],
          // },
          // {
          //   text: item.tireChange
          //     ? `Sim (dianteiro: ${
          //         item.tiresChanged.frontLeft && item.tiresChanged.frontRight
          //           ? "direito e esquerdo"
          //           : item.tiresChanged.frontLeft &&
          //             !item.tiresChanged.frontRight
          //           ? "esquerdo"
          //           : !item.tiresChanged.frontLeft &&
          //             item.tiresChanged.frontRight
          //           ? "direito"
          //           : "nenhum"
          //       }, traseiro: ${
          //         item.tiresChanged.rearLeft && item.tiresChanged.rearRight
          //           ? "direito e esquerdo"
          //           : item.tiresChanged.rearLeft && !item.tiresChanged.rearRight
          //           ? "esquerdo"
          //           : !item.tiresChanged.rearLeft && item.tiresChanged.rearRight
          //           ? "direito"
          //           : "nenhum"
          //       })`
          //     : "Não",
          //   fontSize: 9,
          //   margin: [0, 4, 0, 4],
          // },
          {
            text: item.coast
              ? `R$ ${item.coast.toLocaleString("pt-BR", {
                  style: "decimal",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`
              : "Custo não cadastrado",
            fontSize: 9,
            margin: [0, 4, 0, 4],
          },
          {
            text: formatReviewDate(item.date),
            fontSize: 9,
            margin: [0, 4, 0, 4],
          },
          // {
          //   text: item.status === "active" ? "Ativo" : "Inativo",
          //   fontSize: 9,
          //   margin: [0, 4, 0, 4],
          // },
        ];
      });

      let totalCoast = filteredReview.reduce(
        (acumulator, it) => acumulator + it["coast"],
        0
      );

      reviews.push(
        `Custo total das manutenções: R$ ${totalCoast.toLocaleString("pt-BR", {
          style: "decimal",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`
      );

      Pdf(reviews);
    } else {
      setOpenPdf(false);
      setStatus("all");
      setFleetFilter("all");
      setMonthFilter(0);
      setYearFilter(0);
    }
  }

  newReviewList = reviewList && reviewList;

  var countStatus = 0;
  var totalRows = 0;

  newReviewList &&
    newReviewList.forEach((data) => {
      totalRows += 1;
      data.status === "inactive" && (countStatus += 1);
    });

  const columns = [
    {
      name: "_id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "status",
      label: "status",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "fleet",
      label: "Frota",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value.model} - {value.brand} ({value.allocation.name} /{" "}
              {value.allocation.state})
            </Grid>
          );
        },
      },
    },
    {
      name: "fleet",
      label: "Placa",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value.licensePlate || "Placa não cadastrada"}
            </Grid>
          );
        },
      },
    },
    {
      name: "description",
      label: "Descrição",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value ? value : "Nenhuma descrição adicionada"}
            </Grid>
          );
        },
      },
    },
    {
      name: "lastKm",
      label: "Km anterior",
      options: {
        display: false,
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value} km
            </Grid>
          );
        },
      },
    },
    {
      name: "currentKm",
      label: "Km final",
      options: {
        display: false,
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value} km
            </Grid>
          );
        },
      },
    },
    {
      name: "kmTraveled",
      label: "Média",
      options: {
        display: false,
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value} km
            </Grid>
          );
        },
      },
    },
    {
      name: "oilChange",
      label: "Troca de óleo",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
              className={classes.iconAlignment}
            >
              {value === true ? (
                <CheckIcon
                  style={{
                    color: "green",
                    display: "flex",
                    justifyContent: "center",
                  }}
                />
              ) : (
                <CloseIcon
                  style={{
                    color: "red",
                    display: "flex",
                    justifyContent: "center",
                  }}
                />
              )}
            </Grid>
          );
        },
      },
    },
    {
      name: "tireChange",
      label: "Troca de pneus",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
              className={classes.iconAlignment}
            >
              {value === true ? (
                <CheckIcon
                  style={{
                    color: "green",
                    display: "flex",
                    justifyContent: "center",
                  }}
                />
              ) : (
                <CloseIcon
                  style={{
                    color: "red",
                    display: "flex",
                    justifyContent: "center",
                  }}
                />
              )}
            </Grid>
          );
        },
      },
    },
    {
      name: "coast",
      label: "Custo",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value
                ? `R$ ${value.toLocaleString("pt-BR", {
                    style: "decimal",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}`
                : "Custo não cadastrado"}
            </Grid>
          );
        },
      },
    },
    {
      name: "date",
      label: "Data",
      options: {
        display: true,
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {formatReviewDate(value)}
            </Grid>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value === "active" ? (
                <CheckIcon
                  style={{
                    color: "green",
                    display: "flex",
                    justifyContent: "center",
                  }}
                />
              ) : (
                <BlockIcon
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                />
              )}
            </Grid>
          );
        },
      },
    },
    {
      name: "open",
      label: "Editar",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return <StyledTableCell key={columnMeta.index}></StyledTableCell>;
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Grid container>
                <Grid item xs={10}>
                  <IconButton
                    onClick={() => handleSeeReview(tableMeta.rowData[0])}
                  >
                    <Tooltip title="Ver Detalhes">
                      <MenuBookOutlinedIcon
                        className={classes.fontIcon}
                        style={{
                          color:
                            tableMeta.rowData[1] === "active"
                              ? "#243e92"
                              : "gray",
                        }}
                      />
                    </Tooltip>
                  </IconButton>
                </Grid>
              </Grid>
            </>
          );
        },
      },
    },
  ];

  const handleCloseModalResponse = () => {
    setOpenResponse(false);
    window.location.reload();
  };

  function handleCloseModalConfirm(props) {
    if (props === "confirmar") {
      setOpenConfirm(false);
      setValueDelete("");

      Api.put("/api/review/status", { _id: valueDelete })
        .then((res) => {
          setDeleteMessage(res.data.message);
          setOpenResponse(true);
        })
        .catch((err) => {
          setDeleteMessage(err.response.data.message);
          setOpenResponse(true);
        });
    } else {
      setOpenConfirm(false);
    }
  }

  const handleDelete = (selectedRows, displayData) => {
    setReviewDelete("");

    var reviewName = selectedRows.data.map((review) => {
      return displayData.find((item) => item.dataIndex === review.dataIndex)
        .data[0];
    });

    setValueDelete(reviewName);
    setOpenConfirm(true);
  };

  const handleCreateReview = () => {
    setPage("Cadastrar");
    setTabIndex(5);
    history.push("/create");
  };

  useEffect(() => {
    Api.get("/api/review", { params: { _id: valueDelete } })
      .then((res) => {
        setReviewDelete(res.data);
      })
      .catch((err) => {
        setReviewDelete(err.response.data.message);
      });
  }, [openConfirm === true]); // eslint-disable-line

  const handleMobileDatePickerDate = (date) => {
    if (date) {
      setMonthFilter(date.split("/")[1]);
      setYearFilter(date.split("/")[2]);
    }
  };

  return loadingReview ? (
    <Grid
      container
      style={{
        width: "78vw",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <CircularProgress />
    </Grid>
  ) : (
    <div>
      <Paper elevation={0} className={classes.general}>
        <div style={{ width: "1000vw" }}>
          <MUIDataTable
            id={"table"}
            title={"Manutenções"}
            data={newReviewList}
            pagination="true"
            columns={columns}
            options={{
              sort: true,
              expandableRows: true,
              isRowExpandable: (dataIndex, expandedRows) => {
                return newReviewList[dataIndex].tireChange;
              },
              renderExpandableRow: (rowData, rowMeta) => {
                return (
                  <>
                    <tr>
                      <td colSpan={12}>
                        <TableContainer
                          className={classes.tableRowContainer}
                          component={Paper}
                        >
                          <Table
                            className={classes.subTableStyle}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow key={"header"}>
                                <TableCell align="center">Pneu</TableCell>
                                <TableCell align="center">
                                  Troca realizada
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {
                                <>
                                  <TableRow key={Math.random()}>
                                    <TableCell
                                      align="center"
                                      key={Math.random()}
                                    >
                                      Dianteiro direito
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      key={Math.random()}
                                      className={classes.iconAlignmentInTable}
                                    >
                                      {newReviewList[rowMeta.dataIndex]
                                        .tiresChanged.frontRight === true ? (
                                        <CheckIcon
                                          style={{
                                            color: "green",
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        />
                                      ) : (
                                        <CloseIcon
                                          style={{
                                            color: "red",
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        />
                                      )}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={Math.random()}>
                                    <TableCell
                                      align="center"
                                      key={Math.random()}
                                    >
                                      Dianteiro esquerdo
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      key={Math.random()}
                                      className={classes.iconAlignmentInTable}
                                    >
                                      {newReviewList[rowMeta.dataIndex]
                                        .tiresChanged.frontLeft === true ? (
                                        <CheckIcon
                                          style={{
                                            color: "green",
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        />
                                      ) : (
                                        <CloseIcon
                                          style={{
                                            color: "red",
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        />
                                      )}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={Math.random()}>
                                    <TableCell
                                      align="center"
                                      key={Math.random()}
                                    >
                                      Traseiro direito
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      key={Math.random()}
                                      className={classes.iconAlignmentInTable}
                                    >
                                      {newReviewList[rowMeta.dataIndex]
                                        .tiresChanged.rearRight === true ? (
                                        <CheckIcon
                                          style={{
                                            color: "green",
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        />
                                      ) : (
                                        <CloseIcon
                                          style={{
                                            color: "red",
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        />
                                      )}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={Math.random()}>
                                    <TableCell
                                      align="center"
                                      key={Math.random()}
                                    >
                                      Traseiro esquerdo
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      key={Math.random()}
                                      className={classes.iconAlignmentInTable}
                                    >
                                      {newReviewList[rowMeta.dataIndex]
                                        .tiresChanged.rearLeft === true ? (
                                        <CheckIcon
                                          style={{
                                            color: "green",
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        />
                                      ) : (
                                        <CloseIcon
                                          style={{
                                            color: "red",
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        />
                                      )}
                                    </TableCell>
                                  </TableRow>
                                </>
                              }
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </td>
                    </tr>
                  </>
                );
              },
              rowsPerPage: 20,
              rowsPerPageOptions: [5, 10, 15, 20],
              filter: false,
              download: false,
              responsive: "simple",
              selectableRowsOnClick: true,
              print: true,
              viewColumns: true,
              elevation: 0,
              textLabels: {
                body: {
                  noMatch: "Nenhuma manutenção encontrada!",
                  toolTip: "Ordenar",
                  columnHeaderTooltip: (column) =>
                    `Ordenar por ${column.label}`,
                },
                pagination: {
                  next: "Próxima página",
                  previous: "Página anterior",
                  rowsPerPage: "Linhas por página:",
                  displayRows: "de",
                },
                toolbar: {
                  search: "Procurar",
                  viewColumns: "Filtrar colunas",
                  print: "Imprimir tabela",
                },
                viewColumns: {
                  title: "Filtrar colunas",
                },
                selectedRows: {
                  text: "linha(s) selecionada(s)",
                },
              },
              customToolbarSelect: (
                selectedRows,
                displayData,
                setSelectedRows
              ) => {
                return (
                  <Tooltip title="Desativar Manutenção">
                    <div className={classes.delete}>
                      <IconButton
                        onClick={() => handleDelete(selectedRows, displayData)}
                        className={classes.hover}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                );
              },
              customToolbar: (data) => {
                return (
                  <>
                    <Tooltip title="Gerar PDF">
                      <IconButton
                        onClick={handleGeneratorPdf}
                        className={classes.hover}
                      >
                        <PictureAsPdfOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Criar Manutenção">
                      <IconButton
                        onClick={handleCreateReview}
                        className={classes.hover}
                      >
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                );
              },
              isRowSelectable: (dataIndex, selectedRows) => {
                return dataIndex < totalRows - countStatus ? true : false;
              },
            }}
          />
        </div>
      </Paper>
      <Typography
        align="right"
        style={{
          marginTop: 20,
          color: "#8c8c8c",
          marginBottom: 20,
          marginRight: 10,
        }}
      >
        Atenção: A média corresponde ao<br></br> valor percorrido desde a última
        <br></br>
        manutenção e o custo<br></br> representa seu custo.
      </Typography>
      <Dialog
        open={openResponse}
        onClose={handleCloseModalResponse}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          <strong>
            <i>{"Atenção:"}</i>
          </strong>
        </DialogTitle>
        <DialogContent className={classes.modalDialog}>
          <h3 className={classes.modalText} style={{ textAlign: "center" }}>
            {deleteMessage}
          </h3>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseModalResponse}
            style={{ color: "#808080" }}
            autoFocus
          >
            <strong>fechar</strong>
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openConfirm}
        onClose={() => handleCloseModalConfirm("value")}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          <strong>
            <i>{"Atenção:"}</i>
          </strong>
        </DialogTitle>
        <DialogContent className={classes.modalDialog}>
          <h3 className={classes.modalText}>
            Você está prestes a DESATIVAR a(s) manutenção(ões):
            <ul style={{ color: "black" }}>
              {reviewDelete !== "" &&
                reviewDelete.map((review, index) => {
                  return (
                    <li key={index}>
                      {" "}
                      {review.fleet.model} - {review.fleet.brand} (
                      {formatReviewDate(review.date)}){" "}
                    </li>
                  );
                })}
            </ul>
            Deseja continuar?
          </h3>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCloseModalConfirm("negar")}
            style={{ color: "#808080" }}
            autoFocus
          >
            <strong>fechar</strong>
          </Button>
          <Button
            onClick={() => handleCloseModalConfirm("confirmar")}
            color="secondary"
            autoFocus
          >
            <strong>confirmar</strong>
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openPdf}
        onClose={handleClosePdfModal}
        fullWidth={true}
        maxWidth="sm"
        disableEnforceFocus
      >
        <DialogTitle>
          <strong>
            <i>{"Atenção:"}</i>
          </strong>
        </DialogTitle>
        <DialogContent>
          <h3 style={{ marginBottom: "30px" }}>Selecione os filtros:</h3>
          <div className={classes.modalDialogPdf}>
            <FormControl variant="outlined" className={classes.input}>
              <InputLabel>Frota</InputLabel>
              <Select
                id="fleet"
                label="Frota"
                value={fleetFilter}
                onChange={(e) => setFleetFilter(e.target.value)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
              >
                <MenuItem value={"all"}>Todas as frotas</MenuItem>

                {uniqFleetFilter &&
                  uniqFleetFilter.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>

            <FormControl variant="outlined" className={classes.input}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="pt-br"
              >
                <MobileDatePicker
                  label={"Mês da manutenção"}
                  views={["year", "month"]}
                  slotProps={{
                    textField: {
                      helperText:
                        "Não selecione nenhum mês para consultar todos.",
                    },
                  }}
                  onChange={(e) => {
                    handleMobileDatePickerDate(formatReviewDate(e.$d));
                  }}
                  clearable
                />
              </LocalizationProvider>
            </FormControl>

            <FormControl variant="outlined" className={classes.input}>
              <InputLabel>Status</InputLabel>
              <Select
                id="status"
                label="Status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
              >
                <MenuItem value={"all"}>Todos as manutenções</MenuItem>
                <MenuItem value={"active"}>Manutenção ativa</MenuItem>
                <MenuItem value={"inactive"}>Manutenção inativa</MenuItem>
              </Select>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleClosePdfModal("negar")}
            style={{ color: "#808080" }}
            autoFocus
          >
            <strong>fechar</strong>
          </Button>
          <Button
            onClick={() => handleClosePdfModal("confirmar")}
            color="secondary"
            autoFocus
          >
            <strong>GERAR PDF</strong>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ManageReview;
