import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  Box,
  Tabs,
  Tab,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";

import { Context } from "../../context";
import history from "../../routes/history";
import Api from "../../service";
import dayjs from "dayjs";

import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";

import CreateUser from "../../components/user/createUser";
import CreateProduct from "../../components/products/createProducts";
import CreateCity from "../../components/city/createCity";
import CreateFleet from "../../components/fleet/createFleet";
import CreateTransaction from "../../components/transaction/createTransaction";
import CreateReview from "../../components/review/createReview";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    marginLeft: "30px",
    marginRight: "30px",
    marginTop: "100px",
    height: "85vh",
    [theme.breakpoints.up("xxs")]: {
      height: "100%",
      marginBottom: "30px",
    },
    [theme.breakpoints.up("xs")]: {
      height: "100%",
      marginBottom: "30px",
    },
    [theme.breakpoints.up("sm")]: {
      height: "85vh",
    },
    [theme.breakpoints.up("md")]: {
      height: "85vh",
    },
    [theme.breakpoints.up("lg")]: {
      height: "85vh",
    },
    [theme.breakpoints.up("xl")]: {
      height: "85vh",
    },
    scrollbarWidth: "thin",
  },
  buttonInput: {
    margin: "19px",
    height: "35px",
    width: "12vw",
    [theme.breakpoints.up("xxs")]: {
      width: "35vw",
    },
    [theme.breakpoints.up("xs")]: {
      width: "35vw",
    },
    [theme.breakpoints.up("sm")]: {
      width: "12vw",
    },
    [theme.breakpoints.up("md")]: {
      width: "12vw",
    },
    [theme.breakpoints.up("lg")]: {
      width: "12vw",
    },
    [theme.breakpoints.up("xl")]: {
      width: "12vw",
    },
    color: "white",
  },
  tabs: {
    borderRight: `1px solid rgba(0, 0, 0, .1)`,
    height: "85vh",
    [theme.breakpoints.up("xxs")]: {
      height: "100%",
      borderRight: "none",
    },
    [theme.breakpoints.up("xs")]: {
      height: "100%",
      borderRight: "none",
    },
    [theme.breakpoints.up("sm")]: {
      height: "85vh",
      borderRight: `1px solid rgba(0, 0, 0, .1)`,
    },
    [theme.breakpoints.up("md")]: {
      height: "85vh",
      borderRight: `1px solid rgba(0, 0, 0, .1)`,
    },
    [theme.breakpoints.up("lg")]: {
      height: "85vh",
      borderRight: `1px solid rgba(0, 0, 0, .1)`,
    },
    [theme.breakpoints.up("xl")]: {
      height: "85vh",
      borderRight: `1px solid rgba(0, 0, 0, .1)`,
    },
  },
  input: {
    display: "flex",
    justifyContent: "left",
    marginBottom: 20,
    [theme.breakpoints.up("xxs")]: {
      width: "100%",
    },
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "99%",
    },
    [theme.breakpoints.up("md")]: {
      width: "99%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "99%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "35vw",
    },
  },
  formGrid: {
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
    [theme.breakpoints.up("xxs")]: {
      width: "85vw",
      height: "100%",
      flexDirection: "column",
    },
    [theme.breakpoints.up("xs")]: {
      width: "85vw",
      height: "100%",
      flexDirection: "column",
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      height: "100%",
      flexDirection: "row",
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
      height: "100%",
      flexDirection: "row",
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%",
      height: "100%",
      flexDirection: "row",
    },
    [theme.breakpoints.up("xl")]: {
      width: "100%",
      height: "100%",
      flexDirection: "row",
    },
  },
  backAndConfirmButton: {
    borderLeft: `1px solid rgba(0, 0, 0, .1)`,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    [theme.breakpoints.up("xxs")]: {
      borderLeft: "none",
      flexDirection: "row",
      alignItems: "space-between",
    },
    [theme.breakpoints.up("xs")]: {
      borderLeft: "none",
      flexDirection: "row",
      alignItems: "space-between",
    },
    [theme.breakpoints.up("sm")]: {
      borderLeft: `1px solid rgba(0, 0, 0, .1)`,
      flexDirection: "column",
      alignItems: "flex-start",
    },
    [theme.breakpoints.up("md")]: {
      borderLeft: `1px solid rgba(0, 0, 0, .1)`,
      flexDirection: "column",
      alignItems: "flex-start",
    },
    [theme.breakpoints.up("lg")]: {
      borderLeft: `1px solid rgba(0, 0, 0, .1)`,
      flexDirection: "column",
      alignItems: "flex-start",
    },
    [theme.breakpoints.up("xl")]: {
      borderLeft: `1px solid rgba(0, 0, 0, .1)`,
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

export default function User() {
  const {
    cookie,
    setPage,
    setOpenUsers,
    tabIndex,
    setTabIndex,
    name,
    setName,
    email,
    setEmail,
    phone,
    setPhone,
    password,
    passwordConfirm,
    setIsFromEdit,
    valueEdit,
    userStatus,
    setUserStatus,
    isFromEdit,
    productName,
    setProductName,
    productBrand,
    setProductBrand,
    productQuantity,
    setProductQuantity,
    productStatus,
    setProductStatus,
    cityName,
    cityState,
    cityStatus,
    setCityName,
    setCityState,
    setCityStatus,
    fleetModel,
    setFleetModel,
    fleetBrand,
    setFleetBrand,
    fleetAllocation,
    setFleetAllocation,
    fleetType,
    setFleetType,
    setFleetSerialNumber,
    fleetLicensePlate,
    setFleetLicensePlate,
    fleetStatus,
    setFleetStatus,
    transactionProduct,
    setTransactionProduct,
    transactionOrigin,
    setTransactionOrigin,
    transactionDestination,
    setTransactionDestination,
    transactionQuantity,
    setTransactionQuantity,
    transactionStatus,
    setTransactionStatus,
    reviewFleet,
    setReviewFleet,
    reviewLastKm,
    setReviewLastKm,
    reviewCurrentKm,
    setReviewCurrentKm,
    reviewOilChange,
    setReviewOilChange,
    reviewTireChange,
    setReviewTireChange,
    reviewTiresChangedFrontLeft,
    setReviewTiresChangedFrontLeft,
    reviewTiresChangedFrontRight,
    setReviewTiresChangedFrontRight,
    reviewTiresChangedRearLeft,
    setReviewTiresChangedRearLeft,
    reviewTiresChangedRearRight,
    setReviewTiresChangedRearRight,
    reviewCoast,
    setReviewCoast,
    reviewDescription,
    setReviewDescription,
    reviewDate,
    setReviewDate,
    reviewStatus,
    setReviewStatus,
  } = useContext(Context);

  const classes = useStyles();

  const [errorMessage, setErrorMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    setPage("Editar");
    setOpenUsers(true);
    setIsFromEdit(true);

    const token = cookie.token.accessToken;
    Api.defaults.headers.common["x-access-token"] = token;

    if (tabIndex === 0) {
      Api.get("/api/user", { params: { _id: valueEdit } })
        .then((res) => {
          setName(res.data[0].fullName);
          setEmail(res.data[0].email);
          setPhone(res.data[0].phone);
          setUserStatus(res.data[0].status);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
        });
    }

    if (tabIndex === 1) {
      Api.get("/api/product", { params: { _id: valueEdit } })
        .then((res) => {
          setProductName(res.data[0].name);
          setProductBrand(res.data[0].brand);
          setProductQuantity(res.data[0].quantity);
          setProductStatus(res.data[0].status);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
        });
    }

    if (tabIndex === 2) {
      Api.get("/api/city", { params: { _id: valueEdit } })
        .then((res) => {
          setCityName(res.data[0].name);
          setCityState(res.data[0].state);
          setCityStatus(res.data[0].status);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
        });
    }

    if (tabIndex === 3) {
      Api.get("/api/fleet", { params: { _id: valueEdit } })
        .then((res) => {
          setFleetModel(res.data[0].model);
          setFleetBrand(res.data[0].brand);
          setFleetAllocation(
            res.data[0].allocation ? res.data[0].allocation._id : ""
          );
          setFleetType(res.data[0].type);
          setFleetLicensePlate(res.data[0].licensePlate);
          setFleetStatus(res.data[0].status);
          setFleetSerialNumber(res.data[0].serialNumber);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
        });
    }

    if (tabIndex === 4) {
      Api.get("/api/transaction", { params: { _id: valueEdit } })
        .then((res) => {
          setTransactionProduct(
            res.data[0].product ? res.data[0].product._id : ""
          );
          setTransactionOrigin(
            res.data[0].origin ? res.data[0].origin._id : ""
          );
          setTransactionDestination(
            res.data[0].destination ? res.data[0].destination._id : ""
          );
          setTransactionQuantity(res.data[0].quantity);
          setTransactionStatus(res.data[0].status);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
        });
    }

    if (tabIndex === 5) {
      Api.get("/api/review", { params: { _id: valueEdit } })
        .then((res) => {
          setReviewFleet(res.data[0].fleet._id);
          setReviewLastKm(res.data[0].lastKm);
          setReviewCurrentKm(res.data[0].currentKm);
          setReviewOilChange(res.data[0].oilChange === true ? "sim" : "nao");
          setReviewTireChange(res.data[0].tireChange === true ? "sim" : "nao");
          setReviewTiresChangedFrontLeft(res.data[0].tiresChanged.frontLeft);
          setReviewTiresChangedFrontRight(res.data[0].tiresChanged.frontRight);
          setReviewTiresChangedRearLeft(res.data[0].tiresChanged.rearLeft);
          setReviewTiresChangedRearRight(res.data[0].tiresChanged.rearRight);
          setReviewCoast(res.data[0].coast);
          setReviewDescription(res.data[0].description);
          setReviewDate(dayjs(new Date(res.data[0].date)));
          setReviewStatus(res.data[0].status);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
        });
    }

    setLoading(false);
  }, [tabIndex]); // eslint-disable-line

  const handleCloseModal = (props) => {
    setReviewDate(dayjs(new Date()));
    if (props === "fechar") {
      if (
        errorMessage === "Falhou! E-mail já cadastrado!" ||
        errorMessage === "As senhas não coincidem"
      ) {
        setOpenModal(false);
        setButtonDisable(false);
      } else {
        setOpenModal(false);
        tabIndex === 0
          ? history.push("/user")
          : tabIndex === 1
          ? history.push("/product")
          : tabIndex === 2
          ? history.push("/city")
          : tabIndex === 3
          ? history.push("/fleet")
          : tabIndex === 4
          ? history.push("/transaction")
          : history.push("/review");
        setButtonDisable(false);
      }
    } else {
      setOpenModal(false);
      window.location.reload(true);
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    switch (tabIndex) {
      case 0: {
        setPage("Usuários");
        history.push("/user");
        break;
      }

      case 1: {
        setPage("Produtos");
        history.push("/product");
        break;
      }

      case 2: {
        setPage("Cidades");
        history.push("/city");
        break;
      }

      case 3: {
        setPage("Frotas");
        history.push("/fleet");
        break;
      }

      case 4: {
        setPage("Transações");
        history.push("/transaction");
        break;
      }

      case 5: {
        setPage("Manutenções");
        history.push("/review");
        break;
      }

      default: {
        break;
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    switch (tabIndex) {
      case 0: {
        if (password === passwordConfirm && password !== "") {
          Api.put("/api/user", {
            _id: valueEdit,
            fullName: name,
            password: password,
            phone: phone.includes("_")
              ? phone.slice(0, phone.length - 1)
              : phone,
            email: email,
            status: userStatus,
          })
            .then((res) => {
              setErrorMessage(res.data.message);
              setOpenModal(true);
            })
            .catch((err) => {
              setErrorMessage(err.response.data.message);
              setOpenModal(true);
            });
        } else if (password === passwordConfirm && password === "") {
          Api.put("/api/user", {
            _id: valueEdit,
            fullName: name,
            phone: phone.includes("_")
              ? phone.slice(0, phone.length - 1)
              : phone,
            email: email,
            status: userStatus,
          })
            .then((res) => {
              setErrorMessage(res.data.message);
              setOpenModal(true);
            })
            .catch((err) => {
              setErrorMessage(err.response.data.message);
              setOpenModal(true);
            });
        } else {
          setErrorMessage("As senhas não coincidem");
          setOpenModal(true);
          setButtonDisable(true);
        }
        break;
      }

      case 1: {
        Api.put("/api/product", {
          _id: valueEdit,
          name: productName,
          brand: productBrand,
          quantity: productQuantity,
          status: productStatus,
        })
          .then((res) => {
            setErrorMessage(res.data.message);
            setOpenModal(true);
          })
          .catch((err) => {
            setErrorMessage(err.response.data.message);
            setOpenModal(true);
          });
        break;
      }

      case 2: {
        Api.put("/api/city", {
          _id: valueEdit,
          name: cityName,
          state: cityState,
          status: cityStatus,
        })
          .then((res) => {
            setErrorMessage(res.data.message);
            setOpenModal(true);
          })
          .catch((err) => {
            setErrorMessage(err.response.data.message);
            setOpenModal(true);
          });
        break;
      }

      case 3: {
        Api.put("/api/fleet", {
          _id: valueEdit,
          model: fleetModel,
          brand: fleetBrand,
          allocation: fleetAllocation,
          type: fleetType,
          licensePlate: fleetLicensePlate,
          status: fleetStatus,
        })
          .then((res) => {
            setErrorMessage(res.data.message);
            setOpenModal(true);
          })
          .catch((err) => {
            setErrorMessage(err.response.data.message);
            setOpenModal(true);
          });
        break;
      }

      case 4: {
        Api.put("/api/transaction", {
          _id: valueEdit,
          product: transactionProduct,
          origin: transactionOrigin,
          destination: transactionDestination,
          quantity: transactionQuantity,
          author: cookie.userInfo[0]._id,
          status: transactionStatus,
        })
          .then((res) => {
            setErrorMessage(res.data.message);
            setOpenModal(true);
          })
          .catch((err) => {
            setErrorMessage(err.response.data.message);
            setOpenModal(true);
          });
        break;
      }

      case 5: {
        const tireChanged =
          reviewTireChange === "nao"
            ? {
                frontLeft: false,
                frontRight: false,
                rearLeft: false,
                rearRight: false,
              }
            : {
                frontLeft: reviewTiresChangedFrontLeft,
                frontRight: reviewTiresChangedFrontRight,
                rearLeft: reviewTiresChangedRearLeft,
                rearRight: reviewTiresChangedRearRight,
              };

        const newDate = new Date(reviewDate).getTime();

        Api.put("/api/review", {
          _id: valueEdit,
          fleet: reviewFleet,
          lastKm: reviewLastKm,
          currentKm: reviewCurrentKm,
          oilChange: reviewOilChange === "sim" ? true : false,
          tireChange: reviewTireChange === "sim" ? true : false,
          tiresChanged: tireChanged,
          coast: reviewCoast,
          description: reviewDescription,
          date: newDate || dayjs(new Date()),
          status: reviewStatus,
        })
          .then((res) => {
            setErrorMessage(res.data.message);
            setOpenModal(true);
          })
          .catch((err) => {
            setErrorMessage(err.response.data.message);
            setOpenModal(true);
          });
        break;
      }

      default: {
        break;
      }
    }
  };

  return loading ? (
    <Grid
      container
      style={{
        width: "78vw",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <CircularProgress />
    </Grid>
  ) : (
    <Paper elevation={2} className={classes.root}>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className={classes.formGrid}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={tabIndex}
            onChange={handleChange}
            className={classes.tabs}
            indicatorColor="secondary"
            textColor="secondary"
          >
            <Tab
              id="person"
              key="person"
              value={0}
              disabled={tabIndex === 0 ? false : true}
              label={
                <Grid
                  container
                  direction="row"
                  justifycontent="center"
                  alignItems="center"
                >
                  <span>Usuário</span>
                </Grid>
              }
            />
            <Tab
              id="product"
              key="product"
              value={1}
              disabled={tabIndex === 1 ? false : true}
              label={
                <Grid
                  container
                  direction="row"
                  justifycontent="center"
                  alignItems="center"
                >
                  <span>Produto</span>
                </Grid>
              }
            />
            <Tab
              id="city"
              key="city"
              value={2}
              disabled={tabIndex === 2 ? false : true}
              label={
                <Grid
                  container
                  direction="row"
                  justifycontent="center"
                  alignItems="center"
                >
                  <span>Cidade</span>
                </Grid>
              }
            />
            <Tab
              id="fleet"
              key="fleet"
              value={3}
              disabled={tabIndex === 3 ? false : true}
              label={
                <Grid
                  container
                  direction="row"
                  justifycontent="center"
                  alignItems="center"
                >
                  <span>Frota</span>
                </Grid>
              }
            />
            <Tab
              id="transaction"
              key="transaction"
              value={4}
              disabled={tabIndex === 4 ? false : true}
              label={
                <Grid
                  container
                  direction="row"
                  justifycontent="center"
                  alignItems="center"
                >
                  <span>Transação</span>
                </Grid>
              }
            />
            <Tab
              id="review"
              key="review"
              value={5}
              disabled={tabIndex === 5 ? false : true}
              label={
                <Grid
                  container
                  direction="row"
                  justifycontent="center"
                  alignItems="center"
                >
                  <span>Manutenção</span>
                </Grid>
              }
            />
          </Tabs>

          <TabPanel value={tabIndex} index={0}>
            <CreateUser />
          </TabPanel>

          <TabPanel value={tabIndex} index={1}>
            <CreateProduct />
          </TabPanel>

          <TabPanel value={tabIndex} index={2}>
            <CreateCity />
          </TabPanel>

          <TabPanel value={tabIndex} index={3}>
            <CreateFleet />
          </TabPanel>

          <TabPanel value={tabIndex} index={4}>
            <CreateTransaction />
          </TabPanel>

          <TabPanel value={tabIndex} index={5}>
            <CreateReview />
          </TabPanel>

          <div className={classes.backAndConfirmButton}>
            <Button
              variant="contained"
              style={{ backgroundColor: "#4B565C", color: "white" }}
              className={`${classes.buttonInput}`}
              onClick={(e) => handleBack(e)}
            >
              <ArrowBackIosNewOutlinedIcon style={{ marginRight: 10 }} />
              Voltar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              className={`${classes.buttonInput}`}
            >
              <CheckOutlinedIcon style={{ marginRight: 10 }} />
              atualizar
            </Button>
          </div>
        </div>
      </form>
      <Dialog
        open={openModal}
        onClose={() => handleCloseModal("value")}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          <strong>
            <em>Atenção:</em>
          </strong>
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <h3 style={{ color: "gray" }}>{errorMessage}</h3>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCloseModal("fechar")}
            style={{ color: "#808080" }}
            autoFocus
          >
            <strong>fechar</strong>
          </Button>
          {!isFromEdit && (
            <Button
              onClick={() => handleCloseModal("outro")}
              color="secondary"
              autoFocus
              disabled={buttonDisable}
            >
              <strong>
                {tabIndex === 0
                  ? "Criar outro usuário"
                  : tabIndex === 1
                  ? "Criar outro produto"
                  : tabIndex === 2
                  ? "Criar outra cidade"
                  : tabIndex === 3
                  ? "Criar outra frota"
                  : tabIndex === 3
                  ? "Criar outra transação"
                  : "Criar outra manutenção"}
              </strong>
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
