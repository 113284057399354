import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

import { Context } from "../../context";

import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  inputMaior: {
    marginBottom: 20,
    width: "100%",
  },
  inputStyle: {
    minWidth: "71vw",
    [theme.breakpoints.up("xxs")]: {
      minWidth: "64vw",
    },
    [theme.breakpoints.up("xs")]: {
      minWidth: "64vw",
    },
    [theme.breakpoints.up("sm")]: {
      minWidth: "50vw",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "58vw",
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: "65vw",
    },
    [theme.breakpoints.up("xl")]: {
      minWidth: "71vw",
    },
  },
  hover: {
    "&:hover": {
      color: "#44533f",
    },
  },
}));

export default function CreateCity() {
  const {
    cityName,
    setCityName,
    cityState,
    setCityState,
    cityStatus,
    setCityStatus,
    errorCityName,
    setErrorCityName,
    errorCityState,
    setErrorCityState,
    isFromEdit,
    setImpossibleCreateTransaction,
  } = useContext(Context);

  useEffect(() => {
    setImpossibleCreateTransaction(false);
    if (!isFromEdit) {
      setCityName("");
      setCityState("");
      setCityStatus("active");
    }
    setErrorCityName(false);
    setErrorCityState(false);
  }, []); // eslint-disable-line

  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.inputStyle}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            id="name"
            required
            value={cityName || ""}
            error={!!errorCityName}
            style={{ marginRight: 10 }}
            helperText={errorCityName && "O campo Cidade é obrigatório"}
            onInvalid={(e) => {
              e.target.setCustomValidity("O campo Cidade é obrigatório");
              setErrorCityName(true);
            }}
            onInput={(e) => {
              e.target.setCustomValidity("");
              setCityName(e.target.value);
              setErrorCityName(false);
            }}
            label="Cidade"
            variant="outlined"
            className={classes.inputMaior}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            id="cityState"
            value={cityState || ""}
            required
            error={!!errorCityState}
            helperText={errorCityState && "O campo Estado é obrigatório"}
            onInvalid={(e) => {
              e.target.setCustomValidity("O campo Estado é obrigatório");
              setErrorCityState(true);
            }}
            onInput={(e) => {
              e.target.setCustomValidity("");
              setCityState(e.target.value);
              setErrorCityState(false);
            }}
            label="Estado"
            variant="outlined"
            className={classes.inputMaior}
          />
        </Grid>
        {/* END brand AND quantity FIELD CONTAINER */}
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <FormControl variant="outlined" className={classes.inputMaior}>
            <InputLabel>Status</InputLabel>
            <Select
              id="cityStatus"
              value={cityStatus}
              onChange={(e) => setCityStatus(e.target.value)}
              label="Status"
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                PaperProps: {
                  style: {
                    maxHeight: 200,
                  },
                },
              }}
            >
              <MenuItem value={"active"}>Habilitado</MenuItem>
              <MenuItem value={"inactive"}>Desabilitado</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}
