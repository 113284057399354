import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import CircularProgress from "@material-ui/core/CircularProgress";

import { Context } from "../context";

import Login from "../pages/login";
import Main from "../pages/main";
import Invalid from "../pages/invalid";
import User from "../components/user";
import Create from "../pages/create";
import Edit from "../pages/edit";
import Products from "../components/products";
import City from "../components/city";
import Fleet from "../components/fleet";
import Transaction from "../components/transaction";
import Review from "../components/review";

function CustomRoute({ isPrivate, ...rest }) {
  const { authenticated, loading } = useContext(Context);
  if (loading) {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <div style={{ width: "100px" }}>
            <h4>Carregando...</h4>
            <CircularProgress size={80} />
          </div>
        </div>
      </>
    );
  }
  if (isPrivate && !authenticated) {
    return <Redirect to="/login" />;
  }

  return rest.path === "/main" ? (
    <>
      <Main />
    </>
  ) : (
    <>
      <Main />
      <Route {...rest} />
    </>
  );
}

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path="/login" component={Login} />
      <CustomRoute isPrivate exact path="/main" component={Main} />
      <CustomRoute isPrivate exact path="/user" component={User} />
      <CustomRoute isPrivate exact path="/product" component={Products} />
      <CustomRoute isPrivate exact path="/city" component={City} />
      <CustomRoute isPrivate exact path="/fleet" component={Fleet} />
      <CustomRoute
        isPrivate
        exact
        path="/transaction"
        component={Transaction}
      />
      <CustomRoute isPrivate exact path="/review" component={Review} />
      <Route exact path="/error" component={Invalid} />
      <CustomRoute isPrivate exact path="/create" component={Create} />
      <CustomRoute isPrivate exact path="/edit" component={Edit} />
      <Route render={() => <Redirect to={{ pathname: "/error" }} />} />
    </Switch>
  );
}
