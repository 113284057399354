import { useState } from "react";
import dayjs from "dayjs";

export default function TransactionHook() {
  const [transactionList, setTransactionList] = useState("");
  const [loadingTransaction, setLoadingTransaction] = useState(true);
  const [transactionProduct, setTransactionProduct] = useState("");
  const [transactionOrigin, setTransactionOrigin] = useState("");
  const [transactionDestination, setTransactionDestination] = useState("");
  const [transactionQuantity, setTransactionQuantity] = useState("");
  const [transactionAuthor, setTransactionAuthor] = useState("");
  const [transactionStatus, setTransactionStatus] = useState("active");
  const [transactionDate, setTransactionDate] = useState(dayjs(new Date()));
  const [errorTransactionQuantity, setErrorTransactionQuantity] = useState("");
  const [cityRegisteredInTransaction, setCityRegisteredInTransaction] =
    useState("");
  const [productRegisteredInTransaction, setProductRegisteredInTransaction] =
    useState("");
  const [userRegisteredInTransaction, setUserRegisteredInTransaction] =
    useState("");
  const [
    loadingCityRegisteredInTransaction,
    setLoadingCityRegisteredInTransaction,
  ] = useState(true);
  const [
    loadingProductRegisteredInTransaction,
    setLoadingProductRegisteredInTransaction,
  ] = useState(true);
  const [impossibleCreateTransaction, setImpossibleCreateTransaction] =
    useState(true);

  return {
    transactionList,
    setTransactionList,
    loadingTransaction,
    setLoadingTransaction,
    transactionProduct,
    setTransactionProduct,
    transactionOrigin,
    setTransactionOrigin,
    transactionDestination,
    setTransactionDestination,
    transactionQuantity,
    setTransactionQuantity,
    transactionAuthor,
    setTransactionAuthor,
    transactionStatus,
    setTransactionStatus,
    transactionDate,
    setTransactionDate,
    errorTransactionQuantity,
    setErrorTransactionQuantity,
    cityRegisteredInTransaction,
    setCityRegisteredInTransaction,
    productRegisteredInTransaction,
    setProductRegisteredInTransaction,
    userRegisteredInTransaction,
    setUserRegisteredInTransaction,
    loadingCityRegisteredInTransaction,
    setLoadingCityRegisteredInTransaction,
    loadingProductRegisteredInTransaction,
    setLoadingProductRegisteredInTransaction,
    impossibleCreateTransaction,
    setImpossibleCreateTransaction,
  };
}
