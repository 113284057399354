import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@material-ui/core";

import { Context } from "../../context";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputMask from "react-input-mask";

import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  inputMaior: {
    marginBottom: 20,
    width: "100%",
  },
  inputStyle: {
    minWidth: "71vw",
    [theme.breakpoints.up("xxs")]: {
      minWidth: "64vw",
    },
    [theme.breakpoints.up("xs")]: {
      minWidth: "64vw",
    },
    [theme.breakpoints.up("sm")]: {
      minWidth: "50vw",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "58vw",
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: "65vw",
    },
    [theme.breakpoints.up("xl")]: {
      minWidth: "71vw",
    },
  },
  hover: {
    "&:hover": {
      color: "#44533f",
    },
  },
}));

export default function CreateUser() {
  const {
    name,
    setName,
    setEmail,
    phone,
    setPhone,
    email,
    userStatus,
    setUserStatus,
    passwordConfirm,
    password,
    setPassword,
    setPasswordConfirm,
    errorName,
    setErrorName,
    errorEmail,
    setErrorEmail,
    errorPhone,
    setErrorPhone,
    errorPassword,
    setErrorPassword,
    errorConfirmPass,
    setErrorConfirmPass,
    showPass,
    setShowPass,
    showPassConfirm,
    setShowPassConfirm,
    isFromEdit,
    setImpossibleCreateTransaction,
  } = useContext(Context);

  useEffect(() => {
    setImpossibleCreateTransaction(false);
    if (!isFromEdit) {
      setName("");
      setEmail("");
      setPhone("");
    }
    setPassword("");
    setPasswordConfirm("");
    setErrorName(false);
    setErrorEmail(false);
    setErrorPhone(false);
    setErrorPassword(false);
    setErrorConfirmPass(false);
  }, []); // eslint-disable-line

  const classes = useStyles();

  const handleClickShowPass = () => {
    setShowPass(!showPass);
  };

  const handleClickShowPassConfirm = () => {
    setShowPassConfirm(!showPassConfirm);
  };

  return (
    <>
      <Grid container className={classes.inputStyle}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            id="fullName"
            required
            value={name || ""}
            error={!!errorName}
            style={{ marginRight: 10 }}
            helperText={errorName && "O campo Nome é obrigatório"}
            onInvalid={(e) => {
              e.target.setCustomValidity("O campo Nome é obrigatório");
              setErrorName(true);
            }}
            onInput={(e) => {
              e.target.setCustomValidity("");
              setName(e.target.value);
              setErrorName(false);
            }}
            label="Nome"
            variant="outlined"
            className={classes.inputMaior}
          />
        </Grid>
        {/* END NAME AND CPF FIELD CONTAINER */}
        {/* START EMAIL AND PHONE FIELD CONTAINER */}
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            id="email"
            required
            value={email || ""}
            disabled={isFromEdit}
            error={!!errorEmail}
            helperText={errorEmail && "O campo E-mail é obrigatório"}
            type="email"
            onInvalid={(e) => {
              e.target.setCustomValidity("O campo E-mail é obrigatório");
              setErrorEmail(true);
            }}
            onInput={(e) => {
              e.target.setCustomValidity("");
              setEmail(e.target.value);
              setErrorEmail(false);
            }}
            label="E-mail"
            variant="outlined"
            className={classes.inputMaior}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <InputMask value={phone} mask={"(99) 99999-9999"}>
            {() => (
              <TextField
                id="phone"
                value={phone || ""}
                required
                error={!!errorPhone}
                helperText={errorPhone && "Insira um telefone válido"}
                type="phone"
                onInvalid={(e) => {
                  e.target.setCustomValidity("Insira um telefone válido");
                  setErrorPhone(true);
                }}
                onInput={(e) => {
                  e.target.setCustomValidity("");
                  setPhone(e.target.value);
                  setErrorPhone(false);
                }}
                label="Telefone"
                variant="outlined"
                className={classes.inputMaior}
              />
            )}
          </InputMask>
        </Grid>
        {/* END EMAIL AND PHONE FIELD CONTAINER */}
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <FormControl variant="outlined" className={classes.inputMaior}>
            <InputLabel>Status</InputLabel>
            <Select
              id="userStatus"
              value={userStatus}
              onChange={(e) => setUserStatus(e.target.value)}
              label="Status"
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                PaperProps: {
                  style: {
                    maxHeight: 200,
                  },
                },
              }}
            >
              <MenuItem value={"active"}>Habilitado</MenuItem>
              <MenuItem value={"inactive"}>Desabilitado</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* START PASSWORD FIELD CONTAINER */}
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            id="password"
            value={password || ""}
            error={!!errorPassword}
            required={!isFromEdit ? true : false}
            helperText={errorPassword && "O campo Senha é obrigatório"}
            type={showPass ? "text" : "password"}
            label="Nova Senha"
            onInvalid={(e) => {
              if (!isFromEdit) {
                e.target.setCustomValidity("O campo Senha é obrigatório");
                setErrorPassword(true);
              }
            }}
            onInput={(e) => {
              e.target.setCustomValidity("");
              setErrorPassword(false);
              setPassword(e.target.value);
            }}
            variant="outlined"
            className={classes.inputMaior}
            autoComplete="new-password"
            InputProps={{
              endAdornment: (
                <Tooltip
                  title={!showPass ? "Visualizar senha" : "Esconder Senha"}
                >
                  <InputAdornment position="end">
                    <IconButton
                      className={classes.hover}
                      onClick={handleClickShowPass}
                    >
                      {showPass ? (
                        <Visibility style={{ color: errorPassword && "red" }} />
                      ) : (
                        <VisibilityOff
                          style={{ color: errorPassword && "red" }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                </Tooltip>
              ),
            }}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            id="passwordConfirm"
            value={passwordConfirm || ""}
            error={!!errorConfirmPass}
            type={showPassConfirm ? "text" : "password"}
            helperText={errorConfirmPass && "As senhas não coincidem"}
            label="Confirmar Nova Senha"
            onInvalid={(e) => {
              e.target.setCustomValidity("As senhas não coincidem");
              setErrorConfirmPass(true);
            }}
            onInput={(e) => {
              setPasswordConfirm(e.target.value);
              if (e.target.value !== password) {
                setErrorConfirmPass(true);
              } else {
                e.target.setCustomValidity("");
                setErrorConfirmPass(false);
              }
            }}
            variant="outlined"
            className={classes.inputMaior}
            autoComplete="new-password"
            InputProps={{
              endAdornment: (
                <Tooltip
                  title={
                    !showPassConfirm ? "Visualizar senha" : "Esconder Senha"
                  }
                >
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassConfirm}
                      className={classes.hover}
                    >
                      {showPassConfirm ? (
                        <Visibility
                          style={{ color: errorConfirmPass && "red" }}
                        />
                      ) : (
                        <VisibilityOff
                          style={{ color: errorConfirmPass && "red" }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                </Tooltip>
              ),
            }}
          />
        </Grid>
      </Grid>

      {/* END PASSWORD FIELD CONTAINER */}
    </>
  );
}
