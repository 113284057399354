import React, { useContext, useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  IconButton,
  TableCell,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Grid,
  Tooltip,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import MenuBookOutlinedIcon from "@material-ui/icons/MenuBookOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import BlockIcon from "@mui/icons-material/Block";
import CheckIcon from "@mui/icons-material/Check";

import { Context } from "../../context";
import Api from "../../service";
import history from "../../routes/history";

import Pdf from "./pdfGenerator";

const useStyles = makeStyles((theme) => ({
  general: {
    display: "flex",
    width: "90vw",
    [theme.breakpoints.up("xxs")]: {
      width: "80vw",
    },
    [theme.breakpoints.up("xs")]: {
      width: "80vw",
    },
    [theme.breakpoints.up("sm")]: {
      width: "90vw",
    },
    [theme.breakpoints.up("md")]: {
      width: "90vw",
    },
    [theme.breakpoints.up("lg")]: {
      width: "90vw",
    },
    [theme.breakpoints.up("xl")]: {
      width: "90vw",
    },
  },
  delete: {
    marginRight: 30,
  },
  modalDialog: {
    textAlign: "left",
  },
  modalText: {
    color: "gray",
    fontSize: "18px",
    fontWeight: "bold",
  },
  fontIcon: {
    fontSize: 28,
  },
  hover: {
    "&:hover": {
      color: "#211f58",
    },
  },
  modalDialogPdf: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  input: {
    width: "90%",
    marginBottom: "30px",
  },
}));

const ManageFleet = () => {
  const {
    cookie,
    fleetList,
    setFleetList,
    setPage,
    setOpenFleet,
    setSelectedIndex,
    setTabIndex,
    setValueEdit,
    loadingFleet,
    setLoadingFleet,
  } = useContext(Context);

  const classes = useStyles();

  const [deleteMessage, setDeleteMessage] = useState("");
  const [openResponse, setOpenResponse] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [valueDelete, setValueDelete] = useState("");
  const [fleetDelete, setFleetDelete] = useState([]);
  const [openPdf, setOpenPdf] = useState(false);
  const [status, setStatus] = useState("all");
  const [modelFilter, setModelFilter] = useState("all");
  const [brandFilter, setBrandFilter] = useState("all");
  const [allocationFilter, setAllocationFilter] = useState("all");
  const [typeFilter, setTypeFilter] = useState("all");

  var newFleetList;

  var uniqFleetBrand = [];
  var uniqFleetModel = [];
  var uniqFleetCity = [];

  const handleSeeFleet = (id) => {
    setTabIndex(3);
    setValueEdit(id);
    history.push("/edit");
  };

  if (fleetList) {
    uniqFleetBrand = [
      ...new Set(fleetList.map((fleet) => fleet.brand !== "" && fleet.brand)),
    ];

    uniqFleetBrand = uniqFleetBrand.filter((item) => item !== false);

    uniqFleetModel = [
      ...new Set(fleetList.map((fleet) => fleet.model !== "" && fleet.model)),
    ];

    uniqFleetCity = [
      ...new Set(
        fleetList.map((fleet) => {
          return (
            fleet.allocation &&
            `${fleet.allocation.name} / ${fleet.allocation.state}`
          );
        })
      ),
    ];

    uniqFleetCity = uniqFleetCity.filter((item) => item);
  }

  // Code snippet that avoid warning in MUIDataTable under 4.1.12v
  const oldRender = TableCell.render;

  TableCell.render = function (...args) {
    const [props, ...otherArgs] = args;
    if (typeof props === "object" && props && "isEmpty" in props) {
      const { isEmpty, ...propsWithoutEmpty } = props;
      return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs]);
    } else {
      return oldRender.apply(this, args);
    }
  };
  // Code snippet that avoid warning in MUIDataTable under 4.1.12v

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontSize: "1.2rem",
    },
  }))(TableCell);

  useEffect(() => {
    const token = cookie.token.accessToken;

    Api.defaults.headers.common["x-access-token"] = token;

    setPage("Frotas");
    setSelectedIndex([5, "Frotas"]);
    setOpenFleet(true);

    Api.get("/api/fleet/list")
      .then((res) => {
        setFleetList(res.data);
        setLoadingFleet(false);
      })
      .catch((err) => {
        console.error(err);
        setLoadingFleet(false);
      });

    setValueDelete("");
    setFleetDelete("");
  }, []); // eslint-disable-line

  const handleGeneratorPdf = () => {
    setOpenPdf(true);
  };

  function handleClosePdfModal(props) {
    if (props === "confirmar") {
      var filteredFleet;
      if (
        modelFilter === "all" &&
        status === "all" &&
        brandFilter === "all" &&
        allocationFilter === "all" &&
        typeFilter === "all"
      ) {
        filteredFleet = fleetList;
      } else {
        filteredFleet = fleetList.filter((item) => {
          var isFilterModel =
            modelFilter !== "all" ? item.model === modelFilter : true;
          if (isFilterModel) {
            return status !== "all" ? item.status === status : item.status;
          } else {
            return "";
          }
        });

        filteredFleet = filteredFleet.filter((item) => {
          var isFilterBrand =
            brandFilter !== "all" ? item.brand === brandFilter : true;
          if (isFilterBrand) {
            return typeFilter !== "all" ? item.type === typeFilter : item.type;
          } else {
            return "";
          }
        });

        filteredFleet = filteredFleet.filter((item) => {
          if (allocationFilter === "all") {
            return item;
          } else {
            const cityFilter = allocationFilter.split(" ")[0];
            return item.allocation
              ? item.allocation.name.toUpperCase() === cityFilter.toUpperCase()
              : "";
          }
        });
      }

      const fleets = filteredFleet.map((item) => {
        return [
          { text: item.model, fontSize: 9, margin: [0, 4, 0, 4] },
          {
            text: item.brand,
            fontSize: 9,
            margin: [0, 4, 0, 4],
          },
          {
            text: `${
              item.allocation
                ? `${item.allocation.name} / ${item.allocation.state}`
                : "Não alocado"
            }`,
            fontSize: 9,
            margin: [0, 4, 0, 4],
          },
          {
            text: `${
              item.type === "car"
                ? "Carro"
                : item.type === "motorcycle"
                ? "Moto"
                : item.type === "truck"
                ? "Caminhão"
                : item.type === "brush_cutter"
                ? "Roçadeira"
                : "Trator"
            }`,
            fontSize: 9,
            margin: [0, 4, 0, 4],
          },
          {
            text: `000${item.serialNumber}`,
            fontSize: 9,
            margin: [0, 4, 0, 4],
          },
          {
            text: item.licensePlate || "Sem placa",
            fontSize: 9,
            margin: [0, 4, 0, 4],
          },
          {
            text: item.status === "active" ? "Ativo" : "Inativo",
            fontSize: 9,
            margin: [0, 4, 0, 4],
          },
        ];
      });
      Pdf(fleets);
    } else {
      setOpenPdf(false);
      setStatus("all");
      setModelFilter("all");
      setBrandFilter("all");
      setAllocationFilter("all");
      setTypeFilter("all");
    }
  }

  newFleetList = fleetList && fleetList;

  var countStatus = 0;
  var totalRows = 0;

  newFleetList &&
    newFleetList.forEach((data) => {
      totalRows += 1;
      data.status === "inactive" && (countStatus += 1);
    });

  const columns = [
    {
      name: "_id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "status",
      label: "status",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "model",
      label: "Modelo",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value}
            </Grid>
          );
        },
      },
    },
    {
      name: "brand",
      label: "Fabricante",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value}
            </Grid>
          );
        },
      },
    },
    {
      name: "allocation",
      label: "Cidade de alocação",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value ? `${value.name} / ${value.state}` : "Não alocado"}
            </Grid>
          );
        },
      },
    },
    {
      name: "type",
      label: "Tipo de frota",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value === "car"
                ? "Carro"
                : value === "motorcycle"
                ? "Moto"
                : value === "truck"
                ? "Caminhão"
                : value === "brush_cutter"
                ? "Roçadeira"
                : "Trator"}
            </Grid>
          );
        },
      },
    },
    {
      name: "serialNumber",
      label: "Nº de série",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              000{value}
            </Grid>
          );
        },
      },
    },
    {
      name: "licensePlate",
      label: "Placa",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value ? value : "Sem placa"}
            </Grid>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value === "active" ? (
                <CheckIcon
                  style={{
                    color: "green",
                    display: "flex",
                    justifyContent: "center",
                  }}
                />
              ) : (
                <BlockIcon
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                />
              )}
            </Grid>
          );
        },
      },
    },
    {
      name: "open",
      label: "Editar",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return <StyledTableCell key={columnMeta.index}></StyledTableCell>;
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Grid container>
                <Grid item xs={10}>
                  <IconButton
                    onClick={() => handleSeeFleet(tableMeta.rowData[0])}
                  >
                    <Tooltip title="Ver Detalhes">
                      <MenuBookOutlinedIcon
                        className={classes.fontIcon}
                        style={{
                          color:
                            tableMeta.rowData[1] === "active"
                              ? "#243e92"
                              : "gray",
                        }}
                      />
                    </Tooltip>
                  </IconButton>
                </Grid>
              </Grid>
            </>
          );
        },
      },
    },
  ];

  const handleCloseModalResponse = () => {
    setOpenResponse(false);
    window.location.reload();
  };

  function handleCloseModalConfirm(props) {
    if (props === "confirmar") {
      setOpenConfirm(false);
      setValueDelete("");

      Api.put("/api/fleet/status", { _id: valueDelete })
        .then((res) => {
          setDeleteMessage(res.data.message);
          setOpenResponse(true);
        })
        .catch((err) => {
          setDeleteMessage(err.response.data.message);
          setOpenResponse(true);
        });
    } else {
      setOpenConfirm(false);
    }
  }

  const handleDelete = (selectedRows, displayData) => {
    setFleetDelete("");

    var fleetName = selectedRows.data.map((fleet) => {
      return displayData.find((item) => item.dataIndex === fleet.dataIndex)
        .data[0];
    });

    setValueDelete(fleetName);
    setOpenConfirm(true);
  };

  const handleCreateFleet = () => {
    setPage("Cadastrar");
    setTabIndex(3);
    history.push("/create");
  };

  useEffect(() => {
    Api.get("/api/fleet", { params: { _id: valueDelete } })
      .then((res) => {
        setFleetDelete(res.data);
      })
      .catch((err) => {
        setFleetDelete(err.response.data.message);
      });
  }, [openConfirm === true]); // eslint-disable-line

  return loadingFleet ? (
    <Grid
      container
      style={{
        width: "78vw",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <CircularProgress />
    </Grid>
  ) : (
    <div>
      <Paper elevation={0} className={classes.general}>
        <div style={{ width: "1000vw" }}>
          <MUIDataTable
            id={"table"}
            title={"Frotas"}
            data={newFleetList}
            pagination="true"
            columns={columns}
            options={{
              sort: true,
              rowsPerPage: 5,
              rowsPerPageOptions: [5, 10, 15, 20],
              filter: false,
              download: false,
              responsive: "simple",
              selectableRowsOnClick: true,
              print: true,
              viewColumns: true,
              elevation: 0,
              textLabels: {
                body: {
                  noMatch: "Nenhuma frota encontrada!",
                  toolTip: "Ordenar",
                  columnHeaderTooltip: (column) =>
                    `Ordenar por ${column.label}`,
                },
                pagination: {
                  next: "Próxima página",
                  previous: "Página anterior",
                  rowsPerPage: "Linhas por página:",
                  displayRows: "de",
                },
                toolbar: {
                  search: "Procurar",
                  viewColumns: "Filtrar colunas",
                  print: "Imprimir tabela",
                },
                viewColumns: {
                  title: "Filtrar colunas",
                },
                selectedRows: {
                  text: "linha(s) selecionada(s)",
                },
              },
              customToolbarSelect: (
                selectedRows,
                displayData,
                setSelectedRows
              ) => {
                return (
                  <Tooltip title="Desativar Frota">
                    <div className={classes.delete}>
                      <IconButton
                        onClick={() => handleDelete(selectedRows, displayData)}
                        className={classes.hover}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                );
              },
              customToolbar: (data) => {
                return (
                  <>
                    <Tooltip title="Gerar PDF">
                      <IconButton
                        onClick={handleGeneratorPdf}
                        className={classes.hover}
                      >
                        <PictureAsPdfOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Criar Frota">
                      <IconButton
                        onClick={handleCreateFleet}
                        className={classes.hover}
                      >
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                );
              },
              isRowSelectable: (dataIndex, selectedRows) => {
                return dataIndex < totalRows - countStatus ? true : false;
              },
            }}
          />
        </div>
      </Paper>
      <Dialog
        open={openResponse}
        onClose={handleCloseModalResponse}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          <strong>
            <i>{"Atenção:"}</i>
          </strong>
        </DialogTitle>
        <DialogContent className={classes.modalDialog}>
          <h3 className={classes.modalText} style={{ textAlign: "center" }}>
            {deleteMessage}
          </h3>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseModalResponse}
            style={{ color: "#808080" }}
            autoFocus
          >
            <strong>fechar</strong>
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openConfirm}
        onClose={() => handleCloseModalConfirm("value")}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          <strong>
            <i>{"Atenção:"}</i>
          </strong>
        </DialogTitle>
        <DialogContent className={classes.modalDialog}>
          <h3 className={classes.modalText}>
            Você está prestes a DESATIVAR a(s) frota(s):
            <ul style={{ color: "black" }}>
              {fleetDelete !== "" &&
                fleetDelete.map((fleet, index) => {
                  return (
                    <li key={index}>
                      {" "}
                      {fleet.model} - {fleet.brand} (000{fleet.serialNumber}){" "}
                    </li>
                  );
                })}
            </ul>
            Deseja continuar?
          </h3>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCloseModalConfirm("negar")}
            style={{ color: "#808080" }}
            autoFocus
          >
            <strong>fechar</strong>
          </Button>
          <Button
            onClick={() => handleCloseModalConfirm("confirmar")}
            color="secondary"
            autoFocus
          >
            <strong>confirmar</strong>
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openPdf}
        onClose={handleClosePdfModal}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          <strong>
            <i>{"Atenção:"}</i>
          </strong>
        </DialogTitle>
        <DialogContent>
          <h3 style={{ marginBottom: "30px" }}>Selecione os filtros:</h3>
          <div className={classes.modalDialogPdf}>
            <FormControl variant="outlined" className={classes.input}>
              <InputLabel>Modelo</InputLabel>
              <Select
                id="product"
                label="Modelo"
                value={modelFilter}
                onChange={(e) => setModelFilter(e.target.value)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
              >
                <MenuItem value={"all"}>Todos os modelos</MenuItem>

                {uniqFleetModel &&
                  uniqFleetModel.map((fleet, index) => {
                    return (
                      <MenuItem key={index} value={fleet}>
                        {fleet}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>

            <FormControl variant="outlined" className={classes.input}>
              <InputLabel>Fabricantes</InputLabel>
              <Select
                id="brand"
                label="Fabricantes"
                value={brandFilter}
                onChange={(e) => setBrandFilter(e.target.value)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
              >
                <MenuItem value={"all"}>Todos os fabricantes</MenuItem>

                {uniqFleetBrand &&
                  uniqFleetBrand.map((product, index) => {
                    return (
                      <MenuItem key={index} value={product}>
                        {product}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>

            <FormControl variant="outlined" className={classes.input}>
              <InputLabel>Cidade de alocação</InputLabel>
              <Select
                id="brand"
                label="Cidade de alocação"
                value={allocationFilter}
                onChange={(e) => setAllocationFilter(e.target.value)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
              >
                <MenuItem value={"all"}>Todas as cidades</MenuItem>

                {uniqFleetCity &&
                  uniqFleetCity.map((city, index) => {
                    return (
                      <MenuItem key={index} value={city}>
                        {city}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>

            <FormControl variant="outlined" className={classes.input}>
              <InputLabel>Tipo de frota</InputLabel>
              <Select
                id="brand"
                label="Tipo de frota"
                value={typeFilter}
                onChange={(e) => setTypeFilter(e.target.value)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
              >
                <MenuItem value={"all"}>Todos os tipos</MenuItem>
                <MenuItem value={"car"}>Carro</MenuItem>
                <MenuItem value={"motorcycle"}>Moto</MenuItem>
                <MenuItem value={"truck"}>Caminhão</MenuItem>
                <MenuItem value={"brush_cutter"}>Roçadeira</MenuItem>
                <MenuItem value={"tractor"}>Trator</MenuItem>
              </Select>
            </FormControl>

            <FormControl variant="outlined" className={classes.input}>
              <InputLabel>Status</InputLabel>
              <Select
                id="status"
                label="Status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
              >
                <MenuItem value={"all"}>Todas as frotas</MenuItem>
                <MenuItem value={"active"}>Frota ativa</MenuItem>
                <MenuItem value={"inactive"}>Frota inativa</MenuItem>
              </Select>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleClosePdfModal("negar")}
            style={{ color: "#808080" }}
            autoFocus
          >
            <strong>fechar</strong>
          </Button>
          <Button
            onClick={() => handleClosePdfModal("confirmar")}
            color="secondary"
            autoFocus
          >
            <strong>GERAR PDF</strong>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ManageFleet;
