import { useState } from "react";

export default function SelectedIndex() {
  const [selectedIndex, setSelectedIndex] = useState([1, "Página Inicial"]);
  const [page, setPage] = useState("Página Inicial");
  const [barLeftOpen, setBarLeftOpen] = useState(false);
  const [openUsers, setOpenUsers] = useState(false);
  const [openProducts, setOpenProducts] = useState(false);
  const [openCities, setOpenCities] = useState(false);
  const [openFleet, setOpenFleet] = useState(false);
  const [openTransaction, setOpenTransaction] = useState(false);
  const [openReview, setOpenReview] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  return {
    setSelectedIndex,
    selectedIndex,
    page,
    setPage,
    barLeftOpen,
    setBarLeftOpen,
    openUsers,
    setOpenUsers,
    openProducts,
    setOpenProducts,
    openCities,
    setOpenCities,
    openFleet,
    setOpenFleet,
    openTransaction,
    setOpenTransaction,
    openReview,
    setOpenReview,
    tabIndex,
    setTabIndex,
  };
}
