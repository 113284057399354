import React, { useContext, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  CssBaseline,
  Toolbar,
  IconButton,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Collapse,
  Typography,
  Grid,
} from "@material-ui/core";

import { Context } from "../../context";
import { Link } from "react-router-dom";
import Api from "../../service";
import Charts from "./charts";

import MenuIcon from "@material-ui/icons/Menu";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListItemButton from "@mui/material/ListItemButton";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import WysiwygOutlinedIcon from "@mui/icons-material/WysiwygOutlined";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import MoveDownOutlinedIcon from "@mui/icons-material/MoveDownOutlined";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import "./style.css";

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    color: "#ffffff",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.primary.main,
  },
  appbarContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
  menuButton: {
    marginRight: 10,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    [theme.breakpoints.up("xxs")]: {
      width: "250px",
    },
    [theme.breakpoints.up("xs")]: {
      width: "250px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "250px",
    },
    [theme.breakpoints.up("md")]: {
      width: "250px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "300px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "300px",
    },
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    background: theme.palette.primary.light,
    width: drawerWidth,
    [theme.breakpoints.up("xxs")]: {
      width: "250px",
    },
    [theme.breakpoints.up("xs")]: {
      width: "250px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "250px",
    },
    [theme.breakpoints.up("md")]: {
      width: "250px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "300px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "300px",
    },
    overflowX: "hidden",
    scrollbarWidth: "thin",
  },
  listIcon: {
    color: "#ffffff",
  },
  avatarColor: {
    backgroundColor: theme.palette.secondary.light,
  },
  avatarBorder: {
    height: 84,
    width: 84,
    borderWidth: 2,
    borderColor: theme.palette.secondary.light,
    borderStyle: "solid",
    display: "inline-block",
    borderRadius: "50%",
    boxSizing: "inherit",
  },
  avatarSize: {
    height: 60,
    width: 60,
    fontSize: 25,
    margin: "10px 0px 0px 10px",
    textAlign: "center",
    boxShadow: "0px 8px 10px -7px #000000",
  },
  avatarContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginBottom: 20,
    marginTop: 20,
  },
  hover: {
    "&:hover": {
      opacity: 0.5,
    },
  },
  userName: {
    fontWeight: "bold",
    fontSize: 20,
    textAlign: "center",
    color: "#ffffff",
  },
}));

function DefineAvatar(props) {
  const { name } = props;

  const lastName = name.substring(name.lastIndexOf(" "));
  const lastLetter = lastName.substring(1, 2).toUpperCase();
  const firstLetter = name.substring(0, 1).toUpperCase();

  return (
    <>
      {firstLetter}
      {lastLetter}
    </>
  );
}

const MainPage = () => {
  const classes = useStyles();

  const {
    barLeftOpen,
    setBarLeftOpen,
    selectedIndex,
    setSelectedIndex,
    setPage,
    cookie,
    handleLogout,
    page,
    setTabIndex,
    setOpenUsers,
    openUsers,
    openProducts,
    setOpenProducts,
    openCities,
    setOpenCities,
    openFleet,
    setOpenFleet,
    openTransaction,
    setOpenTransaction,
    openReview,
    setOpenReview,
  } = useContext(Context);

  useEffect(() => {
    const token = cookie.token.accessToken;
    Api.defaults.headers.common["x-access-token"] = token;
  }, []); // eslint-disable-line

  const handleDrawerOpen = () => {
    setBarLeftOpen(true);
  };

  const handleDrawerClose = () => {
    setBarLeftOpen(false);
  };

  const handleHelpLogout = () => {
    handleDrawerClose();
    handleLogout();
  };

  const handleSelected = (e, index, origin, tabIndexLocal) => {
    switch (index) {
      case 2:
        setPage(origin);
        setSelectedIndex([index, origin]);
        setBarLeftOpen(false);
        setTabIndex(tabIndexLocal);
        break;

      case 3:
        setPage(origin);
        setSelectedIndex([index, origin]);
        setBarLeftOpen(false);
        setTabIndex(tabIndexLocal);
        break;

      case 4:
        setPage(origin);
        setSelectedIndex([index, origin]);
        setBarLeftOpen(false);
        setTabIndex(tabIndexLocal);
        break;

      case 5:
        setPage(origin);
        setSelectedIndex([index, origin]);
        setBarLeftOpen(false);
        setTabIndex(tabIndexLocal);
        break;

      case 6:
        setPage(origin);
        setSelectedIndex([index, origin]);
        setBarLeftOpen(false);
        setTabIndex(tabIndexLocal);
        break;

      case 7:
        setPage(origin);
        setSelectedIndex([index, origin]);
        setBarLeftOpen(false);
        setTabIndex(tabIndexLocal);
        break;

      default:
        setPage(origin);
        setSelectedIndex([index, "main"]);
        setBarLeftOpen(false);
        setTabIndex(tabIndexLocal);
        break;
    }
  };

  const handleExpandUsers = () => {
    setOpenUsers(!openUsers);
  };

  const handleExpandProducts = () => {
    setOpenProducts(!openProducts);
  };

  const handleExpandCities = () => {
    setOpenCities(!openCities);
  };

  const handleExpandFleet = () => {
    setOpenFleet(!openFleet);
  };

  const handleExpandTransaction = () => {
    setOpenTransaction(!openTransaction);
  };

  const handleExpandReview = () => {
    setOpenReview(!openReview);
  };

  return (
    <>
      <CssBaseline />
      <div className={classes.root}>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: barLeftOpen,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: barLeftOpen,
              })}
            >
              <MenuIcon />
            </IconButton>
            <div className={classes.appbarContent}>
              <Typography variant="h6" noWrap>
                {page}
              </Typography>
            </div>
          </Toolbar>
        </AppBar>
        <SwipeableDrawer
          open={barLeftOpen}
          onClose={handleDrawerClose}
          onOpen={handleDrawerOpen}
          classes={{
            paper: classes.drawerOpen,
          }}
        >
          <div className={classes.avatarContainer}>
            <IconButton disableRipple disableFocusRipple>
              <div className={`${classes.avatarBorder}`}>
                <Avatar
                  className={`${classes.avatarColor} ${classes.avatarSize}`}
                >
                  <DefineAvatar
                    name={cookie.userInfo[0].fullName}
                  ></DefineAvatar>
                </Avatar>
              </div>
            </IconButton>
            <div style={{ whiteSpace: "pre-wrap" }}>
              <div className={classes.userName}>
                {cookie.userInfo[0].fullName}
              </div>
            </div>
          </div>

          <List>
            <ListItem
              button
              component={Link}
              to="/main"
              className={classes.listIcon}
              selected={selectedIndex[0] === 1}
              onClick={(e) => handleSelected(e, 1, "Página Inicial")}
            >
              <ListItemIcon>
                <HomeOutlinedIcon className={classes.listIcon} />
              </ListItemIcon>
              <ListItemText primary="Página Inicial" />
            </ListItem>

            <>
              {/* START USER BUTTON */}
              <ListItem
                button
                key="user"
                className={classes.listIcon}
                selected={selectedIndex[0] === 2}
                onClick={handleExpandUsers}
              >
                <ListItemIcon className={classes.listIcon}>
                  <GroupOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Usuários" />
                {openUsers ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openUsers} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    component={Link}
                    to="/user"
                    selected={
                      selectedIndex[0] === 2 && selectedIndex[1] === "Usuários"
                    }
                    onClick={(e) => handleSelected(e, 2, "Usuários")}
                  >
                    <ListItemIcon className={classes.listIcon}>
                      <SearchOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="Consultar"
                      className={classes.listIcon}
                    />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    component={Link}
                    to="/create"
                    selected={
                      selectedIndex[0] === 2 && selectedIndex[1] === "Cadastrar"
                    }
                    onClick={(e) => handleSelected(e, 2, "Cadastrar", 0)}
                  >
                    <ListItemIcon className={classes.listIcon}>
                      <AddOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="Cadastrar"
                      className={classes.listIcon}
                    />
                  </ListItemButton>
                </List>
              </Collapse>
              {/* END USER BUTTON */}
              {/* START PRODUCT BUTTON */}
              <ListItem
                button
                key="product"
                className={classes.listIcon}
                selected={selectedIndex[0] === 3}
                onClick={handleExpandProducts}
              >
                <ListItemIcon className={classes.listIcon}>
                  <WysiwygOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Produtos" />
                {openProducts ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openProducts} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    component={Link}
                    to="/product"
                    selected={
                      selectedIndex[0] === 3 && selectedIndex[1] === "Produtos"
                    }
                    onClick={(e) => handleSelected(e, 3, "Produtos")}
                  >
                    <ListItemIcon className={classes.listIcon}>
                      <SearchOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="Consultar"
                      className={classes.listIcon}
                    />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    component={Link}
                    to="/create"
                    selected={
                      selectedIndex[0] === 3 && selectedIndex[1] === "Cadastrar"
                    }
                    onClick={(e) => handleSelected(e, 3, "Cadastrar", 1)}
                  >
                    <ListItemIcon className={classes.listIcon}>
                      <AddOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="Cadastrar"
                      className={classes.listIcon}
                    />
                  </ListItemButton>
                </List>
              </Collapse>
              {/* END PRODUCT BUTTON */}
              {/* START CITY BUTTON */}
              <ListItem
                button
                key="city"
                className={classes.listIcon}
                selected={selectedIndex[0] === 4}
                onClick={handleExpandCities}
              >
                <ListItemIcon className={classes.listIcon}>
                  <PublicOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Cidades" />
                {openCities ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openCities} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    component={Link}
                    to="/city"
                    selected={
                      selectedIndex[0] === 4 && selectedIndex[1] === "Cidades"
                    }
                    onClick={(e) => handleSelected(e, 4, "Cidades")}
                  >
                    <ListItemIcon className={classes.listIcon}>
                      <SearchOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="Consultar"
                      className={classes.listIcon}
                    />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    component={Link}
                    to="/create"
                    selected={
                      selectedIndex[0] === 4 && selectedIndex[1] === "Cadastrar"
                    }
                    onClick={(e) => handleSelected(e, 4, "Cadastrar", 2)}
                  >
                    <ListItemIcon className={classes.listIcon}>
                      <AddOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="Cadastrar"
                      className={classes.listIcon}
                    />
                  </ListItemButton>
                </List>
              </Collapse>
              {/* END CITY BUTTON */}
              {/* START FLEET BUTTON */}
              <ListItem
                button
                key="fleet"
                className={classes.listIcon}
                selected={selectedIndex[0] === 5}
                onClick={handleExpandFleet}
              >
                <ListItemIcon className={classes.listIcon}>
                  <LocalShippingOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Frotas" />
                {openFleet ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openFleet} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    component={Link}
                    to="/fleet"
                    selected={
                      selectedIndex[0] === 5 && selectedIndex[1] === "Frotas"
                    }
                    onClick={(e) => handleSelected(e, 5, "Frotas")}
                  >
                    <ListItemIcon className={classes.listIcon}>
                      <SearchOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="Consultar"
                      className={classes.listIcon}
                    />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    component={Link}
                    to="/create"
                    selected={
                      selectedIndex[0] === 5 && selectedIndex[1] === "Cadastrar"
                    }
                    onClick={(e) => handleSelected(e, 5, "Cadastrar", 3)}
                  >
                    <ListItemIcon className={classes.listIcon}>
                      <AddOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="Cadastrar"
                      className={classes.listIcon}
                    />
                  </ListItemButton>
                </List>
              </Collapse>
              {/* END FLEET BUTTON */}
              {/* START TRANSACTION BUTTON */}
              <ListItem
                button
                key="transaction"
                className={classes.listIcon}
                selected={selectedIndex[0] === 6}
                onClick={handleExpandTransaction}
              >
                <ListItemIcon className={classes.listIcon}>
                  <MoveDownOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Transações" />
                {openTransaction ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openTransaction} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    component={Link}
                    to="/transaction"
                    selected={
                      selectedIndex[0] === 6 &&
                      selectedIndex[1] === "Transações"
                    }
                    onClick={(e) => handleSelected(e, 6, "Transações")}
                  >
                    <ListItemIcon className={classes.listIcon}>
                      <SearchOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="Consultar"
                      className={classes.listIcon}
                    />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    component={Link}
                    to="/create"
                    selected={
                      selectedIndex[0] === 6 && selectedIndex[1] === "Cadastrar"
                    }
                    onClick={(e) => handleSelected(e, 6, "Cadastrar", 4)}
                  >
                    <ListItemIcon className={classes.listIcon}>
                      <AddOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="Cadastrar"
                      className={classes.listIcon}
                    />
                  </ListItemButton>
                </List>
              </Collapse>
              {/* END TRANSACTION BUTTON */}
              {/* START REVIEW BUTTON */}
              <ListItem
                button
                key="review"
                className={classes.listIcon}
                selected={selectedIndex[0] === 7}
                onClick={handleExpandReview}
              >
                <ListItemIcon className={classes.listIcon}>
                  <BuildOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Manutenções" />
                {openReview ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openReview} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    component={Link}
                    to="/review"
                    selected={
                      selectedIndex[0] === 7 &&
                      selectedIndex[1] === "Manutenções"
                    }
                    onClick={(e) => handleSelected(e, 7, "Manutenções")}
                  >
                    <ListItemIcon className={classes.listIcon}>
                      <SearchOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="Consultar"
                      className={classes.listIcon}
                    />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    component={Link}
                    to="/create"
                    selected={
                      selectedIndex[0] === 7 && selectedIndex[1] === "Cadastrar"
                    }
                    onClick={(e) => handleSelected(e, 7, "Cadastrar", 5)}
                  >
                    <ListItemIcon className={classes.listIcon}>
                      <AddOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="Cadastrar"
                      className={classes.listIcon}
                    />
                  </ListItemButton>
                </List>
              </Collapse>
              {/* END REVIEW BUTTON */}
              {/* START LOGOUT BUTTON */}
              <ListItem
                button
                key="logout"
                component={Link}
                to="/login"
                onClick={() => handleHelpLogout()}
              >
                <ListItemIcon className={classes.listIcon}>
                  <LogoutOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Sair" className={classes.listIcon} />
              </ListItem>
              {/* END LOGOUT BUTTON */}
            </>
          </List>
        </SwipeableDrawer>
        {page === "Página Inicial" && (
          <Grid
            container
            style={{ marginTop: 90, justifyContent: "center", display: "flex" }}
          >
            <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
              <Charts />
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
};

export default MainPage;
