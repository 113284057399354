import { useState } from "react";

export default function UserHook() {
  const [userList, setUserList] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [errorName, setErrorName] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPhone, setErrorPhone] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorConfirmPass, setErrorConfirmPass] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showPassConfirm, setShowPassConfirm] = useState(false);
  const [userStatus, setUserStatus] = useState("active");
  const [isFromHome, setIsFromHome] = useState(false);
  const [loadingUser, setLoadingUser] = useState(true);

  return {
    userList,
    setUserList,
    showPassConfirm,
    setShowPassConfirm,
    loadingUser,
    setLoadingUser,
    name,
    userStatus,
    setUserStatus,
    setName,
    email,
    setEmail,
    phone,
    setPhone,
    password,
    setPassword,
    passwordConfirm,
    setPasswordConfirm,
    errorName,
    setErrorName,
    errorEmail,
    setErrorEmail,
    errorPhone,
    setErrorPhone,
    errorPassword,
    setErrorPassword,
    errorConfirmPass,
    setErrorConfirmPass,
    showPass,
    setShowPass,
    isFromHome,
    setIsFromHome,
  };
}
