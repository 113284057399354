import React, { useContext, useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  IconButton,
  TableCell,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Grid,
  Tooltip,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import MenuBookOutlinedIcon from "@material-ui/icons/MenuBookOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import BlockIcon from "@mui/icons-material/Block";
import CheckIcon from "@mui/icons-material/Check";

import { Context } from "../../context";
import Api from "../../service";
import history from "../../routes/history";
import { Typography } from "@mui/material";

import Pdf from "./pdfGenerator";

const useStyles = makeStyles((theme) => ({
  general: {
    display: "flex",
    width: "90vw",
    [theme.breakpoints.up("xxs")]: {
      width: "80vw",
    },
    [theme.breakpoints.up("xs")]: {
      width: "80vw",
    },
    [theme.breakpoints.up("sm")]: {
      width: "90vw",
    },
    [theme.breakpoints.up("md")]: {
      width: "90vw",
    },
    [theme.breakpoints.up("lg")]: {
      width: "90vw",
    },
    [theme.breakpoints.up("xl")]: {
      width: "90vw",
    },
  },
  delete: {
    marginRight: 30,
  },
  modalDialog: {
    textAlign: "left",
  },
  modalText: {
    color: "gray",
    fontSize: "18px",
    fontWeight: "bold",
  },
  fontIcon: {
    fontSize: 28,
  },
  hover: {
    "&:hover": {
      color: "#211f58",
    },
  },
  modalDialogPdf: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  input: {
    width: "90%",
    marginBottom: "30px",
  },
}));

const ManageProducts = () => {
  const {
    cookie,
    productsList,
    setProductsList,
    setPage,
    setOpenProducts,
    setSelectedIndex,
    setTabIndex,
    setValueEdit,
    loadingProducts,
    setLoadingProducts,
  } = useContext(Context);

  const classes = useStyles();

  const [deleteMessage, setDeleteMessage] = useState("");
  const [openResponse, setOpenResponse] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [valueDelete, setValueDelete] = useState("");
  const [productDelete, setProductDelete] = useState([]);
  const [openPdf, setOpenPdf] = useState(false);
  const [status, setStatus] = useState("all");
  const [productFilter, setProductFilter] = useState("all");
  const [brandFilter, setBrandFilter] = useState("all");

  var newProductList;

  var uniqProductBrand = [];

  const handleSeeProduct = (id) => {
    setTabIndex(1);
    setValueEdit(id);
    history.push("/edit");
  };

  if (productsList) {
    uniqProductBrand = [
      ...new Set(
        productsList.map((product) => product.brand !== "" && product.brand)
      ),
    ];

    uniqProductBrand = uniqProductBrand.filter((item) => item !== false);
  }

  // Code snippet that avoid warning in MUIDataTable under 4.1.12v
  const oldRender = TableCell.render;

  TableCell.render = function (...args) {
    const [props, ...otherArgs] = args;
    if (typeof props === "object" && props && "isEmpty" in props) {
      const { isEmpty, ...propsWithoutEmpty } = props;
      return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs]);
    } else {
      return oldRender.apply(this, args);
    }
  };
  // Code snippet that avoid warning in MUIDataTable under 4.1.12v

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontSize: "1.2rem",
    },
  }))(TableCell);

  useEffect(() => {
    const token = cookie.token.accessToken;

    Api.defaults.headers.common["x-access-token"] = token;

    setPage("Produtos");
    setSelectedIndex([3, "Produtos"]);
    setOpenProducts(true);

    Api.get("/api/product/list")
      .then((res) => {
        setProductsList(res.data);
        setLoadingProducts(false);
      })
      .catch((err) => {
        console.error(err);
        setLoadingProducts(false);
      });

    setValueDelete("");
    setProductDelete("");
  }, []); // eslint-disable-line

  const handleGeneratorPdf = () => {
    setOpenPdf(true);
  };

  function handleClosePdfModal(props) {
    if (props === "confirmar") {
      var filteredProducts;
      if (
        productFilter === "all" &&
        status === "all" &&
        brandFilter === "all"
      ) {
        filteredProducts = productsList;
      } else {
        filteredProducts = productsList.filter((item) => {
          var isFilterProduct =
            productFilter !== "all" ? item._id === productFilter : true;
          if (isFilterProduct) {
            return status !== "all" ? item.status === status : item.status;
          } else {
            return "";
          }
        });

        filteredProducts = filteredProducts.filter((item) => {
          if (brandFilter === "all") {
            return item;
          } else if (brandFilter === "without_brand") {
            return item.brand === "";
          } else {
            return item.brand === brandFilter;
          }
        });
      }

      const products = filteredProducts.map((item) => {
        return [
          { text: item.name, fontSize: 9, margin: [0, 4, 0, 4] },
          {
            text: item.brand === "" ? "Marca não cadastrada" : item.brand,
            fontSize: 9,
            margin: [0, 4, 0, 4],
          },
          {
            text: `${
              item.quantity === 0
                ? "Nenhum"
                : item.quantity === 1
                ? `${item.quantity} unidade`
                : `${item.quantity} unidades`
            }`,
            fontSize: 9,
            margin: [0, 4, 0, 4],
          },
          {
            text: `${
              item.totalQuantity - item.quantity === 0
                ? "Nenhum"
                : item.totalQuantity - item.quantity === 1
                ? `${item.totalQuantity - item.quantity} unidade`
                : `${item.totalQuantity - item.quantity} unidades`
            }`,
            fontSize: 9,
            margin: [0, 4, 0, 4],
          },
          {
            text: `${
              item.totalQuantity === 0
                ? "Nenhum"
                : item.totalQuantity === 1
                ? `${item.totalQuantity} unidade`
                : `${item.totalQuantity} unidades`
            }`,
            fontSize: 9,
            margin: [0, 4, 0, 4],
          },
          {
            text: `${formatProductDate(
              item.updateDate ? item.updateDate : item.timestamp
            )}`,
            fontSize: 9,
            margin: [0, 4, 0, 4],
          },
          {
            text: item.status === "active" ? "Ativo" : "Inativo",
            fontSize: 9,
            margin: [0, 4, 0, 4],
          },
        ];
      });
      Pdf(products);
    } else {
      setOpenPdf(false);
      setStatus("all");
      setProductFilter("all");
      setBrandFilter("all");
    }
  }

  function formatProductDate(productDate) {
    let formatDate = new Date(Date.parse(productDate));
    let date =
      (formatDate.getDate() > 9
        ? formatDate.getDate()
        : "0" + formatDate.getDate()) +
      "/" +
      (formatDate.getMonth() + 1 > 9
        ? formatDate.getMonth() + 1
        : "0" + (formatDate.getMonth() + 1)) +
      "/" +
      formatDate.getFullYear();
    return date;
  }

  newProductList = productsList && productsList;

  var countStatus = 0;
  var totalRows = 0;

  newProductList &&
    newProductList.forEach((data) => {
      totalRows += 1;
      data.status === "inactive" && (countStatus += 1);
    });

  const columns = [
    {
      name: "_id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "status",
      label: "status",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "updateDate",
      label: "updateDate",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "name",
      label: "Produto",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value}
            </Grid>
          );
        },
      },
    },
    {
      name: "brand",
      label: "Marca",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value === "" ? "Marca não cadastrada" : value}
            </Grid>
          );
        },
      },
    },
    {
      name: "quantity",
      label: "Estoque (qtde)",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value === 0
                ? "Nenhum"
                : value === 1
                ? `${value} unidade`
                : `${value} unidades`}
            </Grid>
          );
        },
      },
    },
    {
      name: "totalQuantity",
      label: "Em uso (qtde)",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value - tableMeta.rowData[5] === 0
                ? "Nenhum"
                : value - tableMeta.rowData[5] === 1
                ? `${value - tableMeta.rowData[5]} unidade`
                : `${value - tableMeta.rowData[5]} unidades`}
            </Grid>
          );
        },
      },
    },
    {
      name: "totalQuantity",
      label: "Total (qtde)",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value === 0
                ? "Nenhum"
                : value === 1
                ? `${value} unidade`
                : `${value} unidades`}
            </Grid>
          );
        },
      },
    },
    {
      name: "timestamp",
      label: "Data",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {tableMeta.rowData[2]
                ? formatProductDate(tableMeta.rowData[2])
                : formatProductDate(value)}
            </Grid>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value === "active" ? (
                <CheckIcon
                  style={{
                    color: "green",
                    display: "flex",
                    justifyContent: "center",
                  }}
                />
              ) : (
                <BlockIcon
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                />
              )}
            </Grid>
          );
        },
      },
    },
    {
      name: "open",
      label: "Editar",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return <StyledTableCell key={columnMeta.index}></StyledTableCell>;
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Grid container>
                <Grid item xs={10}>
                  <IconButton
                    onClick={() => handleSeeProduct(tableMeta.rowData[0])}
                  >
                    <Tooltip title="Ver Detalhes">
                      <MenuBookOutlinedIcon
                        className={classes.fontIcon}
                        style={{
                          color:
                            tableMeta.rowData[1] === "active"
                              ? "#243e92"
                              : "gray",
                        }}
                      />
                    </Tooltip>
                  </IconButton>
                </Grid>
              </Grid>
            </>
          );
        },
      },
    },
  ];

  const handleCloseModalResponse = () => {
    setOpenResponse(false);
    window.location.reload();
  };

  function handleCloseModalConfirm(props) {
    if (props === "confirmar") {
      setOpenConfirm(false);
      setValueDelete("");

      Api.put("/api/product/status", { _id: valueDelete })
        .then((res) => {
          setDeleteMessage(res.data.message);
          setOpenResponse(true);
        })
        .catch((err) => {
          setDeleteMessage(err.response.data.message);
          setOpenResponse(true);
        });
    } else {
      setOpenConfirm(false);
    }
  }

  const handleDelete = (selectedRows, displayData) => {
    setProductDelete("");

    var productName = selectedRows.data.map((product) => {
      return displayData.find((item) => item.dataIndex === product.dataIndex)
        .data[0];
    });

    setValueDelete(productName);
    setOpenConfirm(true);
  };

  const handleCreateProduct = () => {
    setPage("Cadastrar");
    setTabIndex(1);
    history.push("/create");
  };

  useEffect(() => {
    Api.get("/api/product", { params: { _id: valueDelete } })
      .then((res) => {
        setProductDelete(res.data);
      })
      .catch((err) => {
        setProductDelete(err.response.data.message);
      });
  }, [openConfirm === true]); // eslint-disable-line

  return loadingProducts ? (
    <Grid
      container
      style={{
        width: "78vw",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <CircularProgress />
    </Grid>
  ) : (
    <div>
      <Paper elevation={0} className={classes.general}>
        <div style={{ width: "1000vw" }}>
          <MUIDataTable
            id={"table"}
            title={"Produtos"}
            data={newProductList}
            pagination="true"
            columns={columns}
            options={{
              sort: true,
              rowsPerPage: 5,
              rowsPerPageOptions: [5, 10, 15, 20],
              filter: false,
              download: false,
              responsive: "simple",
              selectableRowsOnClick: true,
              print: true,
              viewColumns: true,
              elevation: 0,
              textLabels: {
                body: {
                  noMatch: "Nenhum produto encontrado!",
                  toolTip: "Ordenar",
                  columnHeaderTooltip: (column) =>
                    `Ordenar por ${column.label}`,
                },
                pagination: {
                  next: "Próxima página",
                  previous: "Página anterior",
                  rowsPerPage: "Linhas por página:",
                  displayRows: "de",
                },
                toolbar: {
                  search: "Procurar",
                  viewColumns: "Filtrar colunas",
                  print: "Imprimir tabela",
                },
                viewColumns: {
                  title: "Filtrar colunas",
                },
                selectedRows: {
                  text: "linha(s) selecionada(s)",
                },
              },
              customToolbarSelect: (
                selectedRows,
                displayData,
                setSelectedRows
              ) => {
                return (
                  <Tooltip title="Desativar Produto">
                    <div className={classes.delete}>
                      <IconButton
                        onClick={() => handleDelete(selectedRows, displayData)}
                        className={classes.hover}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                );
              },
              customToolbar: (data) => {
                return (
                  <>
                    <Tooltip title="Gerar PDF">
                      <IconButton
                        onClick={handleGeneratorPdf}
                        className={classes.hover}
                      >
                        <PictureAsPdfOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Criar Produto">
                      <IconButton
                        onClick={handleCreateProduct}
                        className={classes.hover}
                      >
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                );
              },
              isRowSelectable: (dataIndex, selectedRows) => {
                return dataIndex < totalRows - countStatus ? true : false;
              },
            }}
          />
        </div>
      </Paper>
      <Typography
        align="right"
        style={{
          marginTop: 20,
          color: "#8c8c8c",
          marginBottom: 20,
          marginRight: 10,
        }}
      >
        Atenção: O Total é a soma de todos<br></br> os produtos (estoque e em
        uso).
      </Typography>
      <Dialog
        open={openResponse}
        onClose={handleCloseModalResponse}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          <strong>
            <i>{"Atenção:"}</i>
          </strong>
        </DialogTitle>
        <DialogContent className={classes.modalDialog}>
          <h3 className={classes.modalText} style={{ textAlign: "center" }}>
            {deleteMessage}
          </h3>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseModalResponse}
            style={{ color: "#808080" }}
            autoFocus
          >
            <strong>fechar</strong>
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openConfirm}
        onClose={() => handleCloseModalConfirm("value")}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          <strong>
            <i>{"Atenção:"}</i>
          </strong>
        </DialogTitle>
        <DialogContent className={classes.modalDialog}>
          <h3 className={classes.modalText}>
            Você está prestes a DESATIVAR o(s) produto(s):
            <ul style={{ color: "black" }}>
              {productDelete !== "" &&
                productDelete.map((product, index) => {
                  return (
                    <li key={index}>
                      {product.name} - {product.brand}
                    </li>
                  );
                })}
            </ul>
            Deseja continuar?
          </h3>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCloseModalConfirm("negar")}
            style={{ color: "#808080" }}
            autoFocus
          >
            <strong>fechar</strong>
          </Button>
          <Button
            onClick={() => handleCloseModalConfirm("confirmar")}
            color="secondary"
            autoFocus
          >
            <strong>confirmar</strong>
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openPdf}
        onClose={handleClosePdfModal}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          <strong>
            <i>{"Atenção:"}</i>
          </strong>
        </DialogTitle>
        <DialogContent>
          <h3 style={{ marginBottom: "30px" }}>Selecione os filtros:</h3>
          <div className={classes.modalDialogPdf}>
            <FormControl variant="outlined" className={classes.input}>
              <InputLabel>Produto</InputLabel>
              <Select
                id="product"
                label="Produto"
                value={productFilter}
                onChange={(e) => setProductFilter(e.target.value)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
              >
                <MenuItem value={"all"}>Todos os produtos</MenuItem>

                {productsList &&
                  productsList.map((product, index) => {
                    return (
                      <MenuItem key={index} value={product._id}>
                        {product.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>

            <FormControl variant="outlined" className={classes.input}>
              <InputLabel>Marcas</InputLabel>
              <Select
                id="brand"
                label="Marcas"
                value={brandFilter}
                onChange={(e) => setBrandFilter(e.target.value)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
              >
                <MenuItem value={"all"}>Todos as marcas</MenuItem>
                <MenuItem value={"without_brand"}>Produtos sem marcas</MenuItem>

                {uniqProductBrand &&
                  uniqProductBrand.map((product, index) => {
                    return (
                      <MenuItem key={index} value={product}>
                        {product}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>

            <FormControl variant="outlined" className={classes.input}>
              <InputLabel>Status</InputLabel>
              <Select
                id="status"
                label="Status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
              >
                <MenuItem value={"all"}>Todos os produtos</MenuItem>
                <MenuItem value={"active"}>Produto ativo</MenuItem>
                <MenuItem value={"inactive"}>Produto inativo</MenuItem>
              </Select>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleClosePdfModal("negar")}
            style={{ color: "#808080" }}
            autoFocus
          >
            <strong>fechar</strong>
          </Button>
          <Button
            onClick={() => handleClosePdfModal("confirmar")}
            color="secondary"
            autoFocus
          >
            <strong>GERAR PDF</strong>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ManageProducts;
