import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
  MenuItem,
} from "@material-ui/core";

import { Context } from "../../context";
import Api from "../../service";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  inputMaior: {
    marginBottom: 20,
    width: "100%",
  },
  inputStyle: {
    minWidth: "71vw",
    [theme.breakpoints.up("xxs")]: {
      minWidth: "64vw",
    },
    [theme.breakpoints.up("xs")]: {
      minWidth: "64vw",
    },
    [theme.breakpoints.up("sm")]: {
      minWidth: "50vw",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "58vw",
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: "65vw",
    },
    [theme.breakpoints.up("xl")]: {
      minWidth: "71vw",
    },
  },
  hover: {
    "&:hover": {
      color: "#44533f",
    },
  },
}));

export default function CreateFleet() {
  const {
    fleetModel,
    setFleetModel,
    fleetBrand,
    setFleetBrand,
    fleetAllocation,
    setFleetAllocation,
    fleetType,
    setFleetType,
    fleetSerialNumber,
    fleetLicensePlate,
    setFleetLicensePlate,
    fleetStatus,
    setFleetStatus,
    errorFleetModel,
    setErrorFleetModel,
    errorFleetBrand,
    setErrorFleetBrand,
    isFromEdit,
    cityRegistered,
    setCityRegistered,
    loadingCityRegistered,
    setloadingCityRegistered,
    setImpossibleCreateTransaction,
  } = useContext(Context);

  useEffect(() => {
    setloadingCityRegistered(true);
    setImpossibleCreateTransaction(false);

    Api.get("/api/city/list").then((res) => {
      setCityRegistered(res.data);
      setloadingCityRegistered(false);
    });

    if (!isFromEdit) {
      setFleetModel("");
      setFleetBrand("");
      setFleetAllocation("");
      setFleetLicensePlate("");
    }
    setErrorFleetModel(false);
    setErrorFleetBrand(false);
  }, []); // eslint-disable-line

  const classes = useStyles();

  return loadingCityRegistered ? (
    <Grid
      container
      className={classes.inputStyle}
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Grid>
  ) : (
    <>
      <Grid container className={classes.inputStyle}>
        {isFromEdit && (
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextField
              id="serialNumber"
              value={fleetSerialNumber || ""}
              disabled
              label="Número de série"
              variant="outlined"
              className={classes.inputMaior}
            />
          </Grid>
        )}
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            id="model"
            required
            value={fleetModel || ""}
            error={!!errorFleetModel}
            style={{ marginRight: 10 }}
            helperText={
              errorFleetModel && "O campo Modelo da frota é obrigatório"
            }
            onInvalid={(e) => {
              e.target.setCustomValidity(
                "O campo Modelo da frota é obrigatório"
              );
              setErrorFleetModel(true);
            }}
            onInput={(e) => {
              e.target.setCustomValidity("");
              setFleetModel(e.target.value);
              setErrorFleetModel(false);
            }}
            label="Modelo da frota"
            variant="outlined"
            className={classes.inputMaior}
          />
        </Grid>
        {/* END NAME AND CPF FIELD CONTAINER */}
        {/* START brand AND quantity FIELD CONTAINER */}
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            id="fleetBrand"
            required
            value={fleetBrand || ""}
            error={!!errorFleetBrand}
            style={{ marginRight: 10 }}
            helperText={errorFleetBrand && "O campo Fabricante é obrigatório"}
            onInvalid={(e) => {
              e.target.setCustomValidity("O campo Fabricante é obrigatório");
              setErrorFleetBrand(true);
            }}
            onInput={(e) => {
              e.target.setCustomValidity("");
              setFleetBrand(e.target.value);
              setErrorFleetBrand(false);
            }}
            label="Fabricante"
            variant="outlined"
            className={classes.inputMaior}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <FormControl variant="outlined" className={classes.inputMaior}>
            <InputLabel>Cidade de alocação</InputLabel>
            <Select
              id="fleetAllocation"
              value={fleetAllocation}
              onChange={(e) => setFleetAllocation(e.target.value)}
              label="Cidade de alocação"
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                PaperProps: {
                  style: {
                    maxHeight: 200,
                  },
                },
              }}
            >
              {cityRegistered &&
                cityRegistered.map((city, index) => {
                  return (
                    <MenuItem key={city._id} value={city._id}>
                      {city.status === "inactive"
                        ? `${city.name} (desativada)`
                        : city.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <FormControl variant="outlined" className={classes.inputMaior}>
            <InputLabel>Tipo de frota</InputLabel>
            <Select
              id="fleetType"
              value={fleetType}
              onChange={(e) => setFleetType(e.target.value)}
              label="Tipo de frota"
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                PaperProps: {
                  style: {
                    maxHeight: 200,
                  },
                },
              }}
            >
              <MenuItem value={"car"}>Carro</MenuItem>
              <MenuItem value={"motorcycle"}>Moto</MenuItem>
              <MenuItem value={"truck"}>Caminhão</MenuItem>
              <MenuItem value={"brush_cutter"}>Roçadeira</MenuItem>
              <MenuItem value={"tractor"}>Trator</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            id="fleetLicensePlate"
            value={fleetLicensePlate || ""}
            label="Placa"
            variant="outlined"
            onInput={(e) => {
              setFleetLicensePlate(e.target.value);
            }}
            className={classes.inputMaior}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <FormControl variant="outlined" className={classes.inputMaior}>
            <InputLabel>Status</InputLabel>
            <Select
              id="fleetStatus"
              value={fleetStatus}
              onChange={(e) => setFleetStatus(e.target.value)}
              label="Status"
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                PaperProps: {
                  style: {
                    maxHeight: 200,
                  },
                },
              }}
            >
              <MenuItem value={"active"}>Habilitado</MenuItem>
              <MenuItem value={"inactive"}>Desabilitado</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}
