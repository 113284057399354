import React from "react";
import { Button } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontSize: "48px",
    paddingTop: "35vh",
  },
  button: {
    width: "160px",
    height: "60px",
    color: "white",
  },
}));

export default function InvalidRout() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <p>Desculpe! Esta é uma rota inválida.</p>
        <Button
          variant="contained"
          color="primary"
          href="/main"
          className={classes.button}
        >
          voltar
        </Button>
      </div>
    </>
  );
}
