import React, { createContext } from "react";

import useAuthHook from "./hooks/authHook";
import selectedPageHook from "./hooks/selectedPageHook";
import userHook from "./hooks/userHook";
import editHook from "./hooks/editHook";
import productHook from "./hooks/productHook";
import cityHook from "./hooks/cityHook";
import fleetHook from "./hooks/fleetHook";
import transactionHook from "./hooks/transactionHook";
import reviewHook from "./hooks/reviewHook";

const Context = createContext();

function ContextHooks({ children }) {
  const {
    authenticated,
    userInfos,
    loading,
    handleLogin,
    handleLogout,
    setErrorMsg,
    errorMsg,
    cookie,
    setCookie,
    setUserInfos,
  } = useAuthHook();

  const {
    setSelectedIndex,
    selectedIndex,
    page,
    setPage,
    barLeftOpen,
    setBarLeftOpen,
    openUsers,
    setOpenUsers,
    openProducts,
    setOpenProducts,
    openCities,
    setOpenCities,
    openFleet,
    setOpenFleet,
    openTransaction,
    setOpenTransaction,
    openReview,
    setOpenReview,
    tabIndex,
    setTabIndex,
  } = selectedPageHook();

  const {
    userList,
    setUserList,
    name,
    setName,
    email,
    setEmail,
    cpf,
    setCPF,
    phone,
    setPhone,
    password,
    setPassword,
    passwordConfirm,
    setPasswordConfirm,
    errorName,
    setErrorName,
    errorEmail,
    setErrorEmail,
    errorPhone,
    setErrorPhone,
    userStatus,
    setUserStatus,
    errorPassword,
    setErrorPassword,
    errorConfirmPass,
    setErrorConfirmPass,
    showPass,
    setShowPass,
    showPassConfirm,
    setShowPassConfirm,
    isFromHome,
    setIsFromHome,
    loadingUser,
    setLoadingUser,
  } = userHook();

  const { valueEdit, setValueEdit, isFromEdit, setIsFromEdit } = editHook();

  const {
    productsList,
    setProductsList,
    loadingProducts,
    setLoadingProducts,
    productName,
    setProductName,
    productBrand,
    setProductBrand,
    productQuantity,
    setProductQuantity,
    productStatus,
    setProductStatus,
    errorProductName,
    setErrorProductName,
    errorProductQuantity,
    setErrorProductQuantity,
  } = productHook();

  const {
    citiesList,
    setCitiesList,
    loadingCities,
    setLoadingCities,
    cityName,
    setCityName,
    cityState,
    setCityState,
    cityStatus,
    setCityStatus,
    errorCityName,
    setErrorCityName,
    errorCityState,
    setErrorCityState,
  } = cityHook();

  const {
    fleetList,
    setFleetList,
    loadingFleet,
    setLoadingFleet,
    fleetModel,
    setFleetModel,
    fleetBrand,
    setFleetBrand,
    fleetAllocation,
    setFleetAllocation,
    fleetType,
    setFleetType,
    fleetSerialNumber,
    setFleetSerialNumber,
    fleetLicensePlate,
    setFleetLicensePlate,
    fleetStatus,
    setFleetStatus,
    errorFleetModel,
    setErrorFleetModel,
    errorFleetBrand,
    setErrorFleetBrand,
    cityRegistered,
    setCityRegistered,
    loadingCityRegistered,
    setloadingCityRegistered,
  } = fleetHook();

  const {
    transactionList,
    setTransactionList,
    loadingTransaction,
    setLoadingTransaction,
    transactionProduct,
    setTransactionProduct,
    transactionOrigin,
    setTransactionOrigin,
    transactionDestination,
    setTransactionDestination,
    transactionQuantity,
    setTransactionQuantity,
    transactionAuthor,
    setTransactionAuthor,
    transactionStatus,
    setTransactionStatus,
    transactionDate,
    setTransactionDate,
    errorTransactionQuantity,
    setErrorTransactionQuantity,
    cityRegisteredInTransaction,
    setCityRegisteredInTransaction,
    productRegisteredInTransaction,
    setProductRegisteredInTransaction,
    userRegisteredInTransaction,
    setUserRegisteredInTransaction,
    loadingCityRegisteredInTransaction,
    setLoadingCityRegisteredInTransaction,
    loadingProductRegisteredInTransaction,
    setLoadingProductRegisteredInTransaction,
    impossibleCreateTransaction,
    setImpossibleCreateTransaction,
  } = transactionHook();

  const {
    reviewList,
    setReviewList,
    loadingReview,
    setLoadingReview,
    reviewFleet,
    setReviewFleet,
    reviewLastKm,
    setReviewLastKm,
    reviewCurrentKm,
    setReviewCurrentKm,
    reviewOilChange,
    setReviewOilChange,
    reviewTireChange,
    setReviewTireChange,
    reviewTiresChangedFrontLeft,
    setReviewTiresChangedFrontLeft,
    reviewTiresChangedFrontRight,
    setReviewTiresChangedFrontRight,
    reviewTiresChangedRearLeft,
    setReviewTiresChangedRearLeft,
    reviewTiresChangedRearRight,
    setReviewTiresChangedRearRight,
    reviewCoast,
    setReviewCoast,
    reviewDescription,
    setReviewDescription,
    reviewDate,
    setReviewDate,
    reviewStatus,
    setReviewStatus,
    errorReviewLastKm,
    setErrorReviewLastKm,
    errorReviewCurrentKm,
    setErrorReviewCurrentKm,
    reviewFleetRegistered,
    setReviewFleetRegistered,
    loadingReviewFleetRegistered,
    setloadingReviewFleetRegistered,
  } = reviewHook();

  return (
    <Context.Provider
      value={{
        authenticated,
        userInfos,
        loading,
        handleLogin,
        handleLogout,
        setErrorMsg,
        errorMsg,
        cookie,
        setCookie,
        setUserInfos,
        setSelectedIndex,
        selectedIndex,
        page,
        setPage,
        barLeftOpen,
        setBarLeftOpen,
        openUsers,
        setOpenUsers,
        openProducts,
        setOpenProducts,
        openCities,
        setOpenCities,
        openFleet,
        setOpenFleet,
        openTransaction,
        setOpenTransaction,
        openReview,
        setOpenReview,
        tabIndex,
        setTabIndex,
        userList,
        setUserList,
        name,
        setName,
        email,
        setEmail,
        cpf,
        setCPF,
        phone,
        setPhone,
        password,
        setPassword,
        passwordConfirm,
        setPasswordConfirm,
        errorName,
        setErrorName,
        errorEmail,
        setErrorEmail,
        errorPhone,
        setErrorPhone,
        userStatus,
        setUserStatus,
        errorPassword,
        setErrorPassword,
        errorConfirmPass,
        setErrorConfirmPass,
        showPass,
        setShowPass,
        showPassConfirm,
        setShowPassConfirm,
        isFromHome,
        setIsFromHome,
        loadingUser,
        setLoadingUser,
        valueEdit,
        setValueEdit,
        isFromEdit,
        setIsFromEdit,
        productsList,
        setProductsList,
        loadingProducts,
        setLoadingProducts,
        productName,
        setProductName,
        productBrand,
        setProductBrand,
        productQuantity,
        setProductQuantity,
        productStatus,
        setProductStatus,
        errorProductName,
        setErrorProductName,
        errorProductQuantity,
        setErrorProductQuantity,
        citiesList,
        setCitiesList,
        loadingCities,
        setLoadingCities,
        cityName,
        setCityName,
        cityState,
        setCityState,
        cityStatus,
        setCityStatus,
        errorCityName,
        setErrorCityName,
        errorCityState,
        setErrorCityState,
        fleetList,
        setFleetList,
        loadingFleet,
        setLoadingFleet,
        fleetModel,
        setFleetModel,
        fleetBrand,
        setFleetBrand,
        fleetAllocation,
        setFleetAllocation,
        fleetType,
        setFleetType,
        fleetSerialNumber,
        setFleetSerialNumber,
        fleetLicensePlate,
        setFleetLicensePlate,
        fleetStatus,
        setFleetStatus,
        errorFleetModel,
        setErrorFleetModel,
        errorFleetBrand,
        setErrorFleetBrand,
        cityRegistered,
        setCityRegistered,
        loadingCityRegistered,
        setloadingCityRegistered,
        transactionList,
        setTransactionList,
        loadingTransaction,
        setLoadingTransaction,
        transactionProduct,
        setTransactionProduct,
        transactionOrigin,
        setTransactionOrigin,
        transactionDestination,
        setTransactionDestination,
        transactionQuantity,
        setTransactionQuantity,
        transactionAuthor,
        setTransactionAuthor,
        transactionStatus,
        setTransactionStatus,
        transactionDate,
        setTransactionDate,
        errorTransactionQuantity,
        setErrorTransactionQuantity,
        cityRegisteredInTransaction,
        setCityRegisteredInTransaction,
        productRegisteredInTransaction,
        setProductRegisteredInTransaction,
        userRegisteredInTransaction,
        setUserRegisteredInTransaction,
        loadingCityRegisteredInTransaction,
        setLoadingCityRegisteredInTransaction,
        loadingProductRegisteredInTransaction,
        setLoadingProductRegisteredInTransaction,
        impossibleCreateTransaction,
        setImpossibleCreateTransaction,
        reviewList,
        setReviewList,
        loadingReview,
        setLoadingReview,
        reviewFleet,
        setReviewFleet,
        reviewLastKm,
        setReviewLastKm,
        reviewCurrentKm,
        setReviewCurrentKm,
        reviewOilChange,
        setReviewOilChange,
        reviewTireChange,
        setReviewTireChange,
        reviewTiresChangedFrontLeft,
        setReviewTiresChangedFrontLeft,
        reviewTiresChangedFrontRight,
        setReviewTiresChangedFrontRight,
        reviewTiresChangedRearLeft,
        setReviewTiresChangedRearLeft,
        reviewTiresChangedRearRight,
        setReviewTiresChangedRearRight,
        reviewCoast,
        setReviewCoast,
        reviewDescription,
        setReviewDescription,
        reviewDate,
        setReviewDate,
        reviewStatus,
        setReviewStatus,
        errorReviewLastKm,
        setErrorReviewLastKm,
        errorReviewCurrentKm,
        setErrorReviewCurrentKm,
        reviewFleetRegistered,
        setReviewFleetRegistered,
        loadingReviewFleetRegistered,
        setloadingReviewFleetRegistered,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export { Context, ContextHooks };
