import React, { useContext, useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  IconButton,
  TableCell,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Grid,
  Tooltip,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  Typography,
  MenuItem,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt-br";

import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import MenuBookOutlinedIcon from "@material-ui/icons/MenuBookOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import BlockIcon from "@mui/icons-material/Block";
import CheckIcon from "@mui/icons-material/Check";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";

import { Context } from "../../context";
import Api from "../../service";
import history from "../../routes/history";

import Pdf from "./pdfGenerator";

const useStyles = makeStyles((theme) => ({
  general: {
    display: "flex",
    width: "90vw",
    [theme.breakpoints.up("xxs")]: {
      width: "80vw",
    },
    [theme.breakpoints.up("xs")]: {
      width: "80vw",
    },
    [theme.breakpoints.up("sm")]: {
      width: "90vw",
    },
    [theme.breakpoints.up("md")]: {
      width: "90vw",
    },
    [theme.breakpoints.up("lg")]: {
      width: "90vw",
    },
    [theme.breakpoints.up("xl")]: {
      width: "90vw",
    },
  },
  delete: {
    marginRight: 30,
  },
  modalDialog: {
    textAlign: "left",
  },
  modalText: {
    color: "gray",
    fontSize: "18px",
    fontWeight: "bold",
  },
  fontIcon: {
    fontSize: 28,
  },
  hover: {
    "&:hover": {
      color: "#211f58",
    },
  },
  modalDialogPdf: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  input: {
    width: "90%",
    marginBottom: "30px",
  },
  paperDiv: {
    width: "1000vw",
    marginLeft: 15,
    [theme.breakpoints.up("xxs")]: {
      marginLeft: 15,
    },
    [theme.breakpoints.up("xs")]: {
      marginLeft: 15,
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: 0,
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: 0,
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: 0,
    },
    [theme.breakpoints.up("xl")]: {
      marginLeft: 0,
    },
  },
}));

const ManageTransaction = () => {
  const {
    cookie,
    transactionList,
    setTransactionList,
    setPage,
    setOpenTransaction,
    setSelectedIndex,
    setTabIndex,
    setValueEdit,
    loadingTransaction,
    setLoadingTransaction,
  } = useContext(Context);

  const classes = useStyles();

  const [deleteMessage, setDeleteMessage] = useState("");
  const [openResponse, setOpenResponse] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [valueDelete, setValueDelete] = useState("");
  const [transactionDelete, setTransactionDelete] = useState([]);
  const [openPdf, setOpenPdf] = useState(false);
  const [status, setStatus] = useState("all");
  const [productFilter, setProductFilter] = useState("all");
  const [authorFilter, setAuthorFilter] = useState("all");
  const [originFilter, setOriginFilter] = useState("all");
  const [destinationFilter, setDestinationFilter] = useState("all");
  const [dayFilter, setDayFilter] = useState(0);
  const [monthFilter, setMonthFilter] = useState(0);
  const [yearFilter, setYearFilter] = useState(0);
  const [isReturnProduct, setIsReturnProduct] = useState(false);
  const [dateFormatFilter, setDateFormatFilter] = useState("day");

  var newTransactionList;

  var uniqTransactionProduct = [];
  var uniqTransactionUser = [];
  var uniqTransactionCityOrigin = [];
  var uniqTransactionCityDesination = [];

  const handleSeeTransaction = (id) => {
    setTabIndex(4);
    setValueEdit(id);
    history.push("/edit");
  };

  function formatTransactionDate(transactionDate) {
    let formatDate = new Date(Date.parse(transactionDate));
    let date =
      (formatDate.getDate() > 9
        ? formatDate.getDate()
        : "0" + formatDate.getDate()) +
      "/" +
      (formatDate.getMonth() + 1 > 9
        ? formatDate.getMonth() + 1
        : "0" + (formatDate.getMonth() + 1)) +
      "/" +
      formatDate.getFullYear();
    return date;
  }

  if (transactionList) {
    uniqTransactionProduct = [
      ...new Set(
        transactionList.map(
          (transaction) =>
            transaction.product !== "" &&
            `${transaction.product.name} - ${transaction.product.brand}`
        )
      ),
    ];

    uniqTransactionUser = [
      ...new Set(
        transactionList.map(
          (transaction) =>
            transaction.author !== "" &&
            `${transaction.author.fullName} - ${transaction.author._id}`
        )
      ),
    ];

    uniqTransactionCityOrigin = [
      ...new Set(
        transactionList.map((transaction) => {
          return (
            transaction.origin &&
            `${transaction.origin.name} / ${transaction.origin.state}`
          );
        })
      ),
    ];

    uniqTransactionCityDesination = [
      ...new Set(
        transactionList.map((transaction) => {
          return (
            transaction.destination &&
            `${transaction.destination.name} / ${transaction.destination.state}`
          );
        })
      ),
    ];
  }

  const handleMobileDatePickerDate = (date) => {
    const [day, month, year] = date.split("/").map(Number);

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    setDayFilter(formattedDay.toString());
    setMonthFilter(formattedMonth.toString());
    setYearFilter(year.toString());
  };

  // Code snippet that avoid warning in MUIDataTable under 4.1.12v
  const oldRender = TableCell.render;

  TableCell.render = function (...args) {
    const [props, ...otherArgs] = args;
    if (typeof props === "object" && props && "isEmpty" in props) {
      const { isEmpty, ...propsWithoutEmpty } = props;
      return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs]);
    } else {
      return oldRender.apply(this, args);
    }
  };
  // Code snippet that avoid warning in MUIDataTable under 4.1.12v

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontSize: "1.2rem",
    },
  }))(TableCell);

  useEffect(() => {
    const token = cookie.token.accessToken;

    Api.defaults.headers.common["x-access-token"] = token;

    setPage("Transações");
    setSelectedIndex([6, "Transações"]);
    setOpenTransaction(true);

    Api.get("/api/transaction/list")
      .then((res) => {
        setTransactionList(res.data);
        setLoadingTransaction(false);
      })
      .catch((err) => {
        console.error(err);
        setLoadingTransaction(false);
      });

    setValueDelete("");
    setTransactionDelete("");
  }, []); // eslint-disable-line

  const handleGeneratorPdf = () => {
    setOpenPdf(true);
  };

  function handleClosePdfModal(props) {
    if (props === "confirmar") {
      var filteredTransaction;
      if (
        productFilter === "all" &&
        status === "all" &&
        authorFilter === "all" &&
        originFilter === "all" &&
        destinationFilter === "all" &&
        dayFilter === 0 &&
        monthFilter === 0 &&
        yearFilter === 0
      ) {
        filteredTransaction = transactionList;
      } else {
        filteredTransaction = transactionList.filter((item) => {
          var isFilterModel =
            productFilter !== "all"
              ? item.product.name === productFilter.split(" - ")[0] &&
                item.product.brand === productFilter.split(" - ")[1]
              : true;
          if (isFilterModel) {
            return status !== "all" ? item.status === status : item.status;
          } else {
            return "";
          }
        });

        filteredTransaction = filteredTransaction.filter((item) => {
          var isFilterAuthor =
            authorFilter !== "all"
              ? item.author._id === authorFilter.split(" ")[1]
              : true;
          if (isFilterAuthor) {
            return item;
          } else {
            return "";
          }
        });

        filteredTransaction = filteredTransaction.filter((item) => {
          if (originFilter === "all") {
            return item;
          } else {
            const cityFilter = originFilter.split("/")[0].trim();
            return item.origin
              ? item.origin.name.toUpperCase() === cityFilter.toUpperCase()
              : "";
          }
        });

        filteredTransaction = filteredTransaction.filter((item) => {
          if (destinationFilter === "all") {
            return item;
          } else {
            const cityFilter = destinationFilter.split("/")[0].trim();
  
            return item.destination
              ? item.destination.name.toUpperCase() === cityFilter.toUpperCase()
              : "";
          }
        });

        filteredTransaction = filteredTransaction.filter((item) => {
          if (dateFormatFilter === "month") {
            if (monthFilter === 0 && yearFilter === 0) {
              return item;
            } else {
              const currentDate = formatTransactionDate(item.timestamp);
              const splitedDate = currentDate.split("/");
              const currentMonth = splitedDate[1];
              const currentYear = splitedDate[2];

              return currentMonth === monthFilter && currentYear === yearFilter;
            }
          } else if (dateFormatFilter === "day") {
            if (dayFilter === 0) {
              return item;
            } else {
              const currentDate = formatTransactionDate(item.timestamp);
              const splitedDate = currentDate.split("/");
              const currentDay = splitedDate[0];
              const currentMonth = splitedDate[1];
              const currentYear = splitedDate[2];

              return (
                currentDay === dayFilter &&
                currentMonth === monthFilter &&
                currentYear === yearFilter
              );
            }
          } else {
            if (yearFilter === 0) {
              return item;
            } else {
              const currentDate = formatTransactionDate(item.timestamp);
              const splitedDate = currentDate.split("/");
              const currentYear = splitedDate[2];

              return currentYear === yearFilter;
            }
          }
        });
      }

      const transactions = filteredTransaction.map((item) => {
        return [
          {
            text: `${item.product.name} - ${item.product.brand}`,
            fontSize: 9,
            margin: [0, 4, 0, 4],
          },
          {
            text: `${
              item.origin
                ? `${item.origin.name} / ${item.origin.state}`
                : "Não alocado"
            }`,
            fontSize: 9,
            margin: [0, 4, 0, 4],
          },
          {
            text: `${
              item.destination
                ? `${item.destination.name} / ${item.destination.state}`
                : "Não alocado"
            }`,
            fontSize: 9,
            margin: [0, 4, 0, 4],
          },
          {
            text:
              item.quantity > 1
                ? `${item.quantity} unidades`
                : `${item.quantity} unidade`,
            fontSize: 9,
            margin: [0, 4, 0, 4],
          },
          {
            text: formatTransactionDate(item.timestamp),
            fontSize: 9,
            margin: [0, 4, 0, 4],
          },
          {
            text: item.author.fullName,
            fontSize: 9,
            margin: [0, 4, 0, 4],
          },
          {
            text:
              item.status === "active"
                ? "Ativo"
                : item.status === "inactive"
                ? "Inativo"
                : "Devolvido",
            fontSize: 9,
            margin: [0, 4, 0, 4],
          },
        ];
      });

      Pdf(transactions);
    } else {
      setOpenPdf(false);
      setStatus("all");
      setProductFilter("all");
      setAuthorFilter("all");
      setOriginFilter("all");
      setDestinationFilter("all");
      setDayFilter(0);
      setMonthFilter(0);
      setYearFilter(0);
      setDateFormatFilter("day");
    }
  }

  newTransactionList = transactionList && transactionList;

  var countStatus = 0;
  var totalRows = 0;

  newTransactionList &&
    newTransactionList.forEach((data) => {
      totalRows += 1;
      (data.status === "inactive" || data.status === "returned") &&
        (countStatus += 1);
    });

  const columns = [
    {
      name: "_id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "status",
      label: "status",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "product",
      label: "Produto",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value && value.brand === ""
                ? `${value.name} - Sem marca`
                : `${value.name} - ${value.brand}`}
            </Grid>
          );
        },
      },
    },
    {
      name: "origin",
      label: "Cidade de Origem",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value && `${value.name} / ${value.state}`}
            </Grid>
          );
        },
      },
    },
    {
      name: "destination",
      label: "Cidade de Destino",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value && `${value.name} / ${value.state}`}
            </Grid>
          );
        },
      },
    },
    {
      name: "quantity",
      label: "Quantidade transferida",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value === 1 ? `${value} unidade` : `${value} unidades`}
            </Grid>
          );
        },
      },
    },
    {
      name: "timestamp",
      label: "Data",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {formatTransactionDate(value)}
            </Grid>
          );
        },
      },
    },
    {
      name: "author",
      label: "Cadastrador",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value && value.fullName}
            </Grid>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <StyledTableCell key={columnMeta.index}>
              {columnMeta.label}
            </StyledTableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid
              item
              xs={10}
              style={{
                marginTop: 5,
                color: tableMeta.rowData[1] === "active" ? "black" : "gray",
              }}
            >
              {value === "active" ? (
                <CheckIcon
                  style={{
                    color: "green",
                    display: "flex",
                    justifyContent: "center",
                  }}
                />
              ) : value === "inactive" ? (
                <BlockIcon
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                />
              ) : (
                <SettingsBackupRestoreIcon
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                />
              )}
            </Grid>
          );
        },
      },
    },
    {
      name: "open",
      label: "Editar",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return <StyledTableCell key={columnMeta.index}></StyledTableCell>;
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Grid container>
                <Grid item xs={10}>
                  <IconButton
                    disabled={tableMeta.rowData[1] === "returned"}
                    onClick={() => handleSeeTransaction(tableMeta.rowData[0])}
                  >
                    <Tooltip title="Ver Detalhes">
                      <MenuBookOutlinedIcon
                        className={classes.fontIcon}
                        style={{
                          color:
                            tableMeta.rowData[1] === "active"
                              ? "#243e92"
                              : "gray",
                        }}
                      />
                    </Tooltip>
                  </IconButton>
                </Grid>
              </Grid>
            </>
          );
        },
      },
    },
  ];

  const handleCloseModalResponse = () => {
    setOpenResponse(false);
    window.location.reload();
  };

  function handleCloseModalConfirm(props) {
    if (props === "confirmar") {
      setOpenConfirm(false);
      setValueDelete("");

      if (isReturnProduct) {
        Api.put("/api/transaction/return", { _id: valueDelete })
          .then((res) => {
            setDeleteMessage(res.data.message);
            setOpenResponse(true);
          })
          .catch((err) => {
            setDeleteMessage(err.response.data.message);
            setOpenResponse(true);
          });
      } else {
        Api.put("/api/transaction/status", { _id: valueDelete })
          .then((res) => {
            setDeleteMessage(res.data.message);
            setOpenResponse(true);
          })
          .catch((err) => {
            setDeleteMessage(err.response.data.message);
            setOpenResponse(true);
          });
      }
    } else {
      setOpenConfirm(false);
    }
  }

  const handleDelete = (selectedRows, displayData, operation) => {
    setTransactionDelete("");

    if (operation === "return") {
      setIsReturnProduct(true);
    } else {
      setIsReturnProduct(false);
    }

    var transactionName = selectedRows.data.map((transaction) => {
      return displayData.find(
        (item) => item.dataIndex === transaction.dataIndex
      ).data[0];
    });

    setValueDelete(transactionName);
    setOpenConfirm(true);
  };

  const handleCreateTransaction = () => {
    setPage("Cadastrar");
    setTabIndex(4);
    history.push("/create");
  };

  useEffect(() => {
    Api.get("/api/transaction", { params: { _id: valueDelete } })
      .then((res) => {
        setTransactionDelete(res.data);
      })
      .catch((err) => {
        setTransactionDelete(err.response.data.message);
      });
  }, [openConfirm === true]); // eslint-disable-line

  return loadingTransaction ? (
    <Grid
      container
      style={{
        width: "78vw",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <CircularProgress />
    </Grid>
  ) : (
    <div>
      <Typography
        align="left"
        style={{
          marginTop: 20,
          color: "#8c8c8c",
          marginBottom: 10,
          marginRight: 10,
          marginLeft: 10,
        }}
      >
        Atenção: as devoluções são irreversíveis. Você pode realizar apenas uma
        devolução por vez.
      </Typography>
      <Paper elevation={0} className={classes.general}>
        <div className={classes.paperDiv}>
          <MUIDataTable
            id={"table"}
            title={"Transações"}
            data={newTransactionList}
            pagination="true"
            columns={columns}
            options={{
              sort: true,
              rowsPerPage: 5,
              rowsPerPageOptions: [5, 10, 15, 20],
              filter: false,
              download: false,
              responsive: "simple",
              selectableRowsOnClick: true,
              print: true,
              viewColumns: true,
              elevation: 0,
              textLabels: {
                body: {
                  noMatch: "Nenhuma transação encontrada!",
                  toolTip: "Ordenar",
                  columnHeaderTooltip: (column) =>
                    `Ordenar por ${column.label}`,
                },
                pagination: {
                  next: "Próxima página",
                  previous: "Página anterior",
                  rowsPerPage: "Linhas por página:",
                  displayRows: "de",
                },
                toolbar: {
                  search: "Procurar",
                  viewColumns: "Filtrar colunas",
                  print: "Imprimir tabela",
                },
                viewColumns: {
                  title: "Filtrar colunas",
                },
                selectedRows: {
                  text: "linha(s) selecionada(s)",
                },
              },
              customToolbarSelect: (
                selectedRows,
                displayData,
                setSelectedRows
              ) => {
                return (
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Tooltip title="Devolver produtos">
                      <IconButton
                        disabled={selectedRows.data.length > 1}
                        onClick={() =>
                          handleDelete(selectedRows, displayData, "return")
                        }
                        className={classes.hover}
                      >
                        <SettingsBackupRestoreIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Desativar Transação">
                      <div className={classes.delete}>
                        <IconButton
                          onClick={() =>
                            handleDelete(selectedRows, displayData, "delete")
                          }
                          className={classes.hover}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  </div>
                );
              },
              customToolbar: (data) => {
                return (
                  <>
                    <Tooltip title="Gerar PDF">
                      <IconButton
                        onClick={handleGeneratorPdf}
                        className={classes.hover}
                      >
                        <PictureAsPdfOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Criar Transação">
                      <IconButton
                        onClick={handleCreateTransaction}
                        className={classes.hover}
                      >
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                );
              },
              isRowSelectable: (dataIndex, selectedRows) => {
                return dataIndex < totalRows - countStatus ? true : false;
              },
            }}
          />
        </div>
      </Paper>
      <Dialog
        disableEnforceFocus
        open={openResponse}
        onClose={handleCloseModalResponse}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          <strong>
            <i>{"Atenção:"}</i>
          </strong>
        </DialogTitle>
        <DialogContent className={classes.modalDialog}>
          <h3 className={classes.modalText} style={{ textAlign: "center" }}>
            {deleteMessage}
          </h3>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseModalResponse}
            style={{ color: "#808080" }}
            autoFocus
          >
            <strong>fechar</strong>
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        disableEnforceFocus
        open={openConfirm}
        onClose={() => handleCloseModalConfirm("value")}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          <strong>
            <i>{"Atenção:"}</i>
          </strong>
        </DialogTitle>
        <DialogContent className={classes.modalDialog}>
          <h3 className={classes.modalText}>
            {isReturnProduct
              ? "Você está prestes a DEVOLVER a(s) transação(ões):"
              : "Você está prestes a DESATIVAR a(s) transação(ões):"}
            <ul style={{ color: "black" }}>
              {transactionDelete !== "" &&
                transactionDelete.map((transaction, index) => {
                  return (
                    <li key={index}>
                      {transaction.product &&
                        `${transaction.product.name} - ${
                          transaction.product.brand
                        }, ${
                          transaction.quantity > 1
                            ? `${transaction.quantity} unidades`
                            : `${transaction.quantity} unidade`
                        }`}
                    </li>
                  );
                })}
            </ul>
            Deseja continuar?
            {isReturnProduct && `\nObs: esta operação é IRREVERSÍVEL.`}
          </h3>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCloseModalConfirm("negar")}
            style={{ color: "#808080" }}
            autoFocus
          >
            <strong>fechar</strong>
          </Button>
          <Button
            onClick={() => handleCloseModalConfirm("confirmar")}
            color="secondary"
            autoFocus
          >
            <strong>confirmar</strong>
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        disableEnforceFocus
        open={openPdf}
        onClose={handleClosePdfModal}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          <strong>
            <i>{"Atenção:"}</i>
          </strong>
        </DialogTitle>
        <DialogContent>
          <h3 style={{ marginBottom: "30px" }}>Selecione os filtros:</h3>
          <div className={classes.modalDialogPdf}>
            <FormControl variant="outlined" className={classes.input}>
              <InputLabel>Produto</InputLabel>
              <Select
                id="product"
                label="Produto"
                value={productFilter}
                onChange={(e) => setProductFilter(e.target.value)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
              >
                <MenuItem value={"all"}>Todos os produtos</MenuItem>

                {uniqTransactionProduct &&
                  uniqTransactionProduct.map((product, index) => {
                    return (
                      <MenuItem key={index} value={product}>
                        {product}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>

            <FormControl variant="outlined" className={classes.input}>
              <InputLabel>Cadastrador</InputLabel>
              <Select
                id="brand"
                label="Cadastrador"
                value={authorFilter}
                onChange={(e) => setAuthorFilter(e.target.value)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
              >
                <MenuItem value={"all"}>Todos os cadastradores</MenuItem>

                {uniqTransactionUser &&
                  uniqTransactionUser.map((user, index) => {
                    const userName = user.split(" -")[0];
                    const userId = user.split(" -")[1];

                    return (
                      <MenuItem key={index} value={userId}>
                        {userName}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>

            <FormControl variant="outlined" className={classes.input}>
              <InputLabel>Cidade de origem</InputLabel>
              <Select
                id="brand"
                label="Cidade de origem"
                value={originFilter}
                onChange={(e) => setOriginFilter(e.target.value)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
              >
                <MenuItem value={"all"}>Todas as cidades</MenuItem>

                {uniqTransactionCityOrigin &&
                  uniqTransactionCityOrigin.map((city, index) => {
                    return (
                      <MenuItem key={index} value={city}>
                        {city}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>

            <FormControl variant="outlined" className={classes.input}>
              <InputLabel>Cidade de destino</InputLabel>
              <Select
                id="brand"
                label="Cidade de destino"
                value={destinationFilter}
                onChange={(e) => setDestinationFilter(e.target.value)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
              >
                <MenuItem value={"all"}>Todas as cidades</MenuItem>
                {uniqTransactionCityDesination &&
                  uniqTransactionCityDesination.map((city, index) => {
                    return (
                      <MenuItem key={index} value={city}>
                        {city}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>

            <FormControl
              variant="outlined"
              className={classes.input}
              style={{ marginLeft: 10, marginBottom: 0, marginTop: -10 }}
            >
              <FormLabel>Filtrar:</FormLabel>
              <RadioGroup
                row
                value={dateFormatFilter}
                onChange={(e) => setDateFormatFilter(e.target.value)}
              >
                <FormControlLabel
                  value="day"
                  control={<Radio />}
                  label="Data"
                />
                <FormControlLabel
                  value="month"
                  control={<Radio />}
                  label="Mês"
                />
                <FormControlLabel
                  value="year"
                  control={<Radio />}
                  label="Ano"
                />
              </RadioGroup>
            </FormControl>

            {dateFormatFilter === "month" ? (
              <FormControl variant="outlined" className={classes.input}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="pt-br"
                >
                  <MobileDatePicker
                    label={"Mês da transação"}
                    views={["year", "month"]}
                    slotProps={{
                      textField: {
                        helperText:
                          "Não selecione nenhum mês para consultar todos.",
                      },
                    }}
                    onChange={(e) => {
                      handleMobileDatePickerDate(formatTransactionDate(e.$d));
                    }}
                    clearable
                  />
                </LocalizationProvider>
              </FormControl>
            ) : dateFormatFilter === "day" ? (
              <FormControl variant="outlined" className={classes.input}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="pt-br"
                >
                  <MobileDatePicker
                    label={"Data da transação"}
                    slotProps={{
                      textField: {
                        helperText:
                          "Não selecione nenhuma data para consultar todas.",
                      },
                    }}
                    onChange={(e) => {
                      handleMobileDatePickerDate(formatTransactionDate(e.$d));
                    }}
                    clearable
                  />
                </LocalizationProvider>
              </FormControl>
            ) : (
              <FormControl variant="outlined" className={classes.input}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="pt-br"
                >
                  <MobileDatePicker
                    label={"Ano da transação"}
                    views={["year"]}
                    slotProps={{
                      textField: {
                        helperText:
                          "Não selecione nenhum ano para consultar todos.",
                      },
                    }}
                    onChange={(e) => {
                      handleMobileDatePickerDate(formatTransactionDate(e.$d));
                    }}
                    clearable
                  />
                </LocalizationProvider>
              </FormControl>
            )}

            <FormControl variant="outlined" className={classes.input}>
              <InputLabel>Status</InputLabel>
              <Select
                id="status"
                label="Status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
              >
                <MenuItem value={"all"}>Todas as transações</MenuItem>
                <MenuItem value={"active"}>Transação ativa</MenuItem>
                <MenuItem value={"inactive"}>Transação inativa</MenuItem>
                <MenuItem value={"returned"}>Transação devolvida</MenuItem>
              </Select>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleClosePdfModal("negar")}
            style={{ color: "#808080" }}
            autoFocus
          >
            <strong>fechar</strong>
          </Button>
          <Button
            onClick={() => handleClosePdfModal("confirmar")}
            color="secondary"
            autoFocus
          >
            <strong>GERAR PDF</strong>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ManageTransaction;
